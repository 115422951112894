import React, { useMemo } from "react";
import NotFound from "components/NotFound";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import { useRouter } from "hooks/useRouter";
import { useAxiosRequest } from "use-axios-request";
import { CommunityType, CommunityTypeRecord } from "./model";
import LoadingError from "components/LoadingError";
import { AxiosError } from "axios";
import { Descriptions, Tabs, Button } from "antd";
import VaultAttachmentsList from "pages/shared/attachments/vaults_list";
import FlatsSection from "pages/communities/sections/flats";
import AdministracioSection from "pages/communities/sections/administracio_list";
import ObservacioSection from "pages/communities/sections/observacio_list";
import ExpedientsList from "pages/communities/sections/expedients";
import GestioSection from "pages/communities/sections/gestio";
import AvaluacioSection from "pages/communities/sections/avaluacio";
import { Tab } from "rc-tabs/lib/interface";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerForm";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerForm";
import CadastreForm from "./form_cadastre";
import { EditOutlined } from '@ant-design/icons';

const CommunityEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { query: { communitiyId } } = useRouter();

  const baseUrlEdit = useMemo(() => 'communities', []);
  const baseUrl = React.useMemo(() => `/api/communities/${communitiyId}`, [communitiyId]);
  const attachmentBaseUrl = React.useMemo(() => `communities/${communitiyId}`, [communitiyId]);

  const { data: response, error, isFetching, refresh } = useAxiosRequest<{ data: CommunityType }>(baseUrl);

  const formOptions = useMemo(() : useDrawerFormOptionsType<CommunityType> => {
    return {
      title: "Pis / Local",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: {
        ...CommunityTypeRecord,
        meta: {
          permissions: {
            can_edit: true,
            can_show: true
          }
        }
      }
    }
  }, [refresh]);

  const { edit, drawerProps } = useDrawerForm<CommunityType>(baseUrlEdit, formOptions);

  if (!communitiyId) { return <NotFound /> }
  if (isFetching) { return <PageLoading /> }

  if (error) {
    const { isAxiosError, response } = error as AxiosError;

    if (isAxiosError && response && response.status === 404) {
      return <NotFound />
    }

    return <LoadingError />
  }

  if (!response) {
    return <NotFound />
  }

  const { data: item } = response;

  const headerContent = (
    <Descriptions bordered title="" size="small" column={3}>
      <Descriptions.Item key="address" label="Adreça">{ item.attributes.address_fmt }</Descriptions.Item>
      <Descriptions.Item key="reference" label="Referència cadastral">{ item.attributes.referencia_cadastral }</Descriptions.Item>
      <Descriptions.Item key="year" label="Any de construcció">{ item.attributes.any_construccio }</Descriptions.Item>
      <Descriptions.Item key="postal_code" label="Codi Postal">{ item.attributes.codi_postal }</Descriptions.Item>
      <Descriptions.Item key="barri" label="Barri">{ item.attributes.nom_barri }</Descriptions.Item>
      <Descriptions.Item key="districte" label="Districte">{item.attributes.nom_districte}</Descriptions.Item>
      <Descriptions.Item key="ite" label="ITE obligatoria">{item.attributes.ite_obligatoria ? "Sí" : "No"}</Descriptions.Item>
      <Descriptions.Item key="ite" label="ITE vigent fins">{item.attributes.ite_valida_fins}</Descriptions.Item>
    </Descriptions>
  );

  // const tags = (
  //   <ApplicationMessagesList type="Community" id={communitiyId} />
  // )

  const extra = (
    [
      <Button key="1" onClick={() => edit(communitiyId)} icon={<EditOutlined />}>Editar</Button>,
    ]
  );

  const tabs: Tab[] = [
    { key: "1", label: "Observacions", children: <ObservacioSection communitiyId={communitiyId} /> },
    { key: "2", label: "Expedients", children: <ExpedientsList communityId={communitiyId} /> },
    { key: "3", label: "Locals i pisos", children: <FlatsSection /> },
    { key: "4", label: "Òrgans de gestió", children: <GestioSection communitiyId={communitiyId} /> },
    { key: "5", label: "Avaluació", children: <AvaluacioSection communityId={communitiyId} /> },
    { key: "6", label: "Administració", children: <AdministracioSection communitiyId={communitiyId} /> },
    { key: "8", label: "Documents", children: <VaultAttachmentsList endpoint={attachmentBaseUrl} initialVault={item.attributes.vault_id} /> },
  ];

  return (
    <PageHeaderWrapper title="Editar comunitat" content={headerContent} extra={extra}>
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} className="mb-20" />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <CadastreForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default CommunityEditPage;
