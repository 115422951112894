import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import ExpedientForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import {useQuery} from "hooks/useRouter";
import {Descriptions, Tabs, Tag} from "antd";
import api from "../../api";
import {IExpedient} from "api/interfaces/Expedient";
import LoadingError from "../../components/LoadingError";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import VaultAttachmentsList from "../shared/attachments/vaults_list";
import ConsultesList from "./sections/consultes";
import CoordinacionsList from "./sections/coordinacions";
import GestionsList from "./sections/gestions";
import LocalsList from "./sections/locals";
import IncidenciesList from "./sections/incidencies";
import IntervencionsList from "./sections/intervencions";
import CommunitiesList from "./sections/communities";
import ParticipacioSection from "./sections/participacio";
import ContactsSection from "./sections/contacts";
import { Tab } from "rc-tabs/lib/interface";
import AdministracioList from "./sections/administracio_list";

export interface IState {
  item?: IExpedient
  included: JsonApiDocument["included"]
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  included: [],
  isFetching: false,
  error: null
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const ExpedientEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { expedientId } = useQuery();

  const attachmentBaseUrl = React.useMemo(() => `expedients/${expedientId}`, [expedientId]);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.expedients.show(expedientId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            included: result.success().included,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            included: [],
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    if (expedientId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [expedientId, dispatch]);

  if (!expedientId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }
  if (!state.item) { return <LoadingError /> }

  // const communitiesIds = state.item.relationships.communities.data.map((c) => c.id).join(",");

  const headerContent = (
    <Descriptions bordered title="" size="small" column={1}>
      <Descriptions.Item key="expedient_number" label="Número d'exp.">{ state.item.attributes.num_expedient }</Descriptions.Item>
      <Descriptions.Item key="address" label="Adreça/es">{ state.item.attributes.addresses }</Descriptions.Item>
    </Descriptions>
  );

  const tags = (
    state.item.attributes.data_fi ? <Tag color="red">Tancat</Tag> : <Tag color="geekblue">Obert</Tag>
  );

  const tabs: Tab[] = [
    { key: "1", label: "General", children: <ExpedientForm api={api.expedients} record={state.item} dispatch={dispatch} /> },
    { key: "2", label: "Locals i pisos", children: <LocalsList expedientId={expedientId} /> },
    { key: "3", label: "Comunitats", children: <CommunitiesList expedientId={expedientId} /> },
    { key: "4", label: "Administració", children: <AdministracioList expedientId={expedientId} /> },
    { key: "5", label: "Contactes", children: <ContactsSection expedientId={expedientId} /> },
    { key: "6", label: "Tipus expedients", children: <GestionsList expedientId={expedientId} /> },
    { key: "7", label: "Incidències", children: <IncidenciesList expedientId={expedientId} /> },
    { key: "8", label: "Intervencions", children: <IntervencionsList expedientId={expedientId} /> },
    { key: "9", label: "Consultes", children: <ConsultesList expedientId={expedientId} /> },
    { key: "10", label: "Coordinacions", children: <CoordinacionsList expedientId={expedientId} /> },
    { key: "11", label: "Participació", children: <ParticipacioSection expedientId={expedientId} /> },
    { key: "12", label: "Documents", children: <VaultAttachmentsList endpoint={attachmentBaseUrl} initialVault={state.item.attributes.vault_id} /> },
  ]

  return (
    <PageHeaderWrapper title="Editar expedient" content={headerContent} tags={tags}>
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} className="mb-20" />
    </PageHeaderWrapper>
  );
};

export default ExpedientEditPage;
