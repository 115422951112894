import { GET, DELETE, PATCH, POST } from 'api/constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from 'api/interfaces/Query'
import { IExpedientParticipacioResult, IExpedientParticipacionsResult, IExpedientParticipacio } from 'api/interfaces/expedient/Participacio'
import { Routes } from 'api/routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "api/interfaces/ResultResponse";

export default class ExpedientParticipacions extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly expedient_id: string;

  protected dateFields: string[] = ["data"];

  constructor(expedient_id: string, host?: string) {
    super(host);
    this.expedient_id = expedient_id;
  }

  public async list(params: IQuery = {}): Promise<IExpedientParticipacionsResult> {
    return await this.request(GET, Routes.expedientParticipacionsPath(this.expedient_id), {}, params) as IExpedientParticipacionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientParticipacioResult> {
    return await this.request(GET, Routes.expedientParticipacioPath(this.expedient_id, id), {}, params) as IExpedientParticipacioResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientParticipacioResult> {
    return await this.request(POST, Routes.expedientParticipacionsPath(this.expedient_id), {}, params) as IExpedientParticipacioResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientParticipacioResult> {
    return await this.request(PATCH, Routes.expedientParticipacioPath(this.expedient_id, id), {}, params) as IExpedientParticipacioResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientParticipacioPath(this.expedient_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IExpedientParticipacio {
    return {
      id: null,
      type: "participacio_expedient",
      attributes: {
        data: null,
        expedient_id: null,
        grup_edat_id: null,
        grup_edat_nom: "",
        dones_esp_paies: 0,
        dones_est_paies: 0,
        homes_esp_paios: 0,
        homes_est_paios: 0,
        binari_esp_paies: 0,
        binari_est_paies: 0,
        dones_esp_gitanes: 0,
        dones_est_gitanes: 0,
        homes_esp_gitanos: 0,
        homes_est_gitanos: 0,
        binari_esp_gitanes: 0,
        binari_est_gitanes: 0,
        observacions: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
