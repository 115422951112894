import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import NotFound from "components/NotFound";
import List from "./list";
import Edit from "./edit";

const AdministracioFinquesSection: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <List />
      </Route>

      <Route exact path={`${path}/:serveiId`}>
        <Edit />
      </Route>

      <Route component={NotFound} />
    </Switch>
  );
};

export default AdministracioFinquesSection;
