import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import './App.less';

import AuthorizedRoute from 'components/Authorized/AuthorizedRoute';
import NotFound from 'components/NotFound';
import Home from 'pages/Home';
import UsersSection from 'pages/users';
import CommunitiesSection from 'pages/communities';
import FlatsSection from 'pages/flats';
import ExpedientsSection from 'pages/expedients';
import IndicatorsSection from 'packages/progess-consultes/pages/queries';
import ContactsSection from 'pages/contacts';
import PropietarisSection from 'pages/propietaris';
import AdministracioFinquesSection from 'pages/administracio_finques';
import ServeisSection from 'pages/serveis';
import PreferencesDashboard from 'pages/preferences';
import Login from 'pages/auth/login';

const App: React.FC = () => {
  return (
    <Switch>
      <Route path="/auth/login" component={Login} exact />

      <Redirect from="/" to="/dashboard" exact />
      <AuthorizedRoute path="/dashboard" permissions={["dashboard"]}>
        <Home />
      </AuthorizedRoute>

      <AuthorizedRoute path="/communities" permissions={["communities:index"]}>
        <CommunitiesSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/flats" permissions={["flats:index"]}>
        <FlatsSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/expedients" permissions={["expedients:index"]}>
        <ExpedientsSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/indicators" permissions={["indicators:index"]}>
        <IndicatorsSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/contacts" permissions={["contacts:index"]}>
        <ContactsSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/propietaris" permissions={["propietaris:index"]}>
        <PropietarisSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/administracio-finques" permissions={["serveis:index"]}>
        <AdministracioFinquesSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/serveis" permissions={["serveis:index"]}>
        <ServeisSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/users" permissions={["users:manage"]}>
        <UsersSection />
      </AuthorizedRoute>

      <AuthorizedRoute path="/preferences" permissions={["preferences"]}>
        <PreferencesDashboard />
      </AuthorizedRoute>

      <Route component={NotFound} />
    </Switch>
  );
}

export default App;
