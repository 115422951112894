import { useAxiosRequest } from "use-axios-request";
import { useMemo, useRef, RefObject } from "react";
import { AxiosError } from "axios";
import DrawerForm, { DrawerFormProps } from "components/DrawerForm";
import { Form, notification } from "antd";
import { axiosConfig } from "utils/request";
import { codeMessage } from "utils/constants";
import { BasicRecord } from "utils/models";


export interface useDrawerFormReturnType<RecordType extends BasicRecord> {
  create: () => void
  edit: (id: string|number) => void
  drawerProps: {
    forwardedRef: RefObject<DrawerForm<RecordType>>
  } & DrawerFormProps<RecordType>
};

export interface useDrawerFormOptionsType<RecordType extends BasicRecord> {
  title: string,
  newRecord: RecordType,
  handleCreated?: DrawerFormProps<RecordType>["handleCreated"]
  handleUpdated?: DrawerFormProps<RecordType>["handleUpdated"]
}

const noop = () => {};

export const useDrawerForm = <RecordType extends BasicRecord>(baseUrl: string, options: useDrawerFormOptionsType<RecordType>) : useDrawerFormReturnType<RecordType> => {
  const [form] = Form.useForm();
  const drawerRef = useRef<DrawerForm<RecordType>>(null);

  const { title, handleCreated = noop, handleUpdated = noop, newRecord } = options;

  const initialValues = useMemo(() => newRecord, [newRecord])

  const requestCallbacks = useMemo(() => ({
    onSuccess: (data: ItemResponse<RecordType>) => {
      drawerRef.current?.show(data.data);
    },
    onError: (error: AxiosError) => {
      drawerRef.current?.handleCancel();

      const { response } = error;

      if (response && response.status) {
        const { status } = response;
        const errorText = codeMessage[response.status] || response.statusText;

        notification.error({
          message: `Error ${status}`,
          description: errorText,
        });
      } else if (!response) {
        notification.error({
          message: `Error`,
          description: "Hi hagut un error desconegut"
        });
      }
    }
  }), []);

  const { update } = useAxiosRequest<ItemResponse<RecordType>>(null, requestCallbacks)

  const create = () => {
    drawerRef.current?.show()
  }

  const edit = (id: number) => {
    drawerRef.current?.show();
    drawerRef.current?.setState({ loading: true });
    update({
      ...axiosConfig,
      url: `${baseUrl}/${id}`
    })
  }


  const result: useDrawerFormReturnType<RecordType> = {
    create,
    edit,
    drawerProps: {
      form,
      title,
      createUrl: baseUrl,
      handleCreated,
      handleUpdated,
      forwardedRef: drawerRef,
      initialValues
    }
  }

  return result;
}
