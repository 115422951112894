import React, {useCallback} from "react";
import {Alert, Button, DatePicker, Form, Table, message} from "antd";
import {ColumnGroupType, ColumnType} from "antd/lib/table/interface";
import {useInnerTable} from "hooks/useInnerTableApi";
import api from "api";

import {FormInstance} from "antd/lib/form";
import {renderRecordActions, renderRecordYear} from "utils/helpers";
import {IExpedientParticipacio} from "api/interfaces/expedient/Participacio";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {dateFormats} from "utils/formats";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {PlusOutlined} from "@ant-design/icons";
import {useStoreState} from "utils/store";
import {ModalFormChildProps, ModalFormWithForwardRef} from "components/ModalFormApi";
import ParticipacioHistory from "pages/expedients/sections/participacio_history";
import moment from "moment";

interface ParticipacioListProps {
  expedientId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    grup_edat: undefined,
    from: undefined,
    to: undefined
  })
};

const baseQuery = { summaries: true }

const ParticipacioList: React.FC<ParticipacioListProps> = ({ expedientId }) => {
  const [form] = Form.useForm();
  const apiEndpoint = React.useMemo(() => api.expedientParticipacions(expedientId), [expedientId]);

  const { tableProps, error, refresh, reload } = useInnerTable<IExpedientParticipacio>(apiEndpoint, {baseQuery, form, formResetCallback });

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const expandedRowRender = useCallback((record: IExpedientParticipacio): React.ReactNode => {
    return <ParticipacioHistory expedientId={expedientId} data={record.attributes.data.format(dateFormats.server)} onUpdate={refresh} />;
  },[expedientId, refresh]) ;

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientParticipacio> => {
    const record = apiEndpoint.newInstance();
    record.attributes.data = moment().startOf("year");

    return {
      title: "Registre participació",
      handleCreated: reload,
      handleUpdated: reload,
      newRecord: record,
    }
  }, [apiEndpoint, reload]);

  const { create, edit, drawerProps } = useDrawerForm<IExpedientParticipacio>(apiEndpoint, formOptions);

  const columns: (ColumnType<IExpedientParticipacio> | ColumnGroupType<IExpedientParticipacio>)[] = [
    {
      title: "Any",
      key: "data",
      render: renderRecordYear("data")
    },
    {
      title: 'Dones nacionals',
      key: "dones_esp",
      children: [
        {
          title: "Paies",
          key: "dones_esp_paies",
          align: "center",
          dataIndex: ["attributes", "dones_esp_paies"]
        },
        {
          title: "Gitanes",
          key: "dones_esp_gitanes",
          align: "center",
          dataIndex: ["attributes", "dones_esp_gitanes"]
        }
      ]
    },
    {
      title: 'Dones estrangeres',
      key: "dones_est",
      children: [
        {
          title: "Paies",
          key: "dones_est_paies",
          align: "center",
          dataIndex: ["attributes", "dones_est_paies"]
        },
        {
          title: "Gitanes",
          key: "dones_est_gitanes",
          align: "center",
          dataIndex: ["attributes", "dones_est_gitanes"]
        }
      ]
    },
    {
      title: 'Homes nacionals',
      key: "dones_esp",
      children: [
        {
          title: "Paios",
          key: "homes_esp_paios",
          align: "center",
          dataIndex: ["attributes", "homes_esp_paios"]
        },
        {
          title: "Gitanos",
          key: "homes_esp_gitanos",
          align: "center",
          dataIndex: ["attributes", "homes_esp_gitanos"]
        }
      ]
    },
    {
      title: 'Homes estrangers',
      key: "dones_est",
      children: [
        {
          title: "Paios",
          key: "homes_est_paios",
          align: "center",
          dataIndex: ["attributes", "homes_est_paios"]
        },
        {
          title: "Gitanos",
          key: "homes_est_gitanos",
          align: "center",
          dataIndex: ["attributes", "homes_est_gitanos"]
        }
      ]
    },
    {
      title: 'Nº binari nacionals',
      key: "binari_esp",
      children: [
        {
          title: "Paies",
          key: "binari_esp_paies",
          align: "center",
          dataIndex: ["attributes", "binari_esp_paies"]
        },
        {
          title: "Gitanes",
          key: "binari_esp_gitanes",
          align: "center",
          dataIndex: ["attributes", "binari_esp_gitanes"]
        }
      ]
    },
    {
      title: 'Nº binari estrangeres',
      key: "binari_est",
      children: [
        {
          title: "Paies",
          key: "binari_est_paies",
          align: "center",
          dataIndex: ["attributes", "binari_est_paies"]
        },
        {
          title: "Gitanes",
          key: "binari_est_gitanes",
          align: "center",
          dataIndex: ["attributes", "binari_est_gitanes"]
        }
      ]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span />
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} expandable={{ expandedRowRender }} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ readOnly }: ModalFormChildProps) => (
          <Form.Item name={["attributes", "data"]} label="Any de registre" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
            <DatePicker picker="year" className="w100" disabled={readOnly} format="YYYY" placeholder="Selecciona una data" />
          </Form.Item>
        )}
      </ModalFormWithForwardRef>
    </>
  );
};

export default ParticipacioList;
