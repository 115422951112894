import React, { ReactElement, useCallback, useMemo, useRef, useState } from "react";

import { Form, Table, Alert, Button, Input, Select, Typography, DatePicker, message } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useQuery } from "hooks/useRouter";
import { filterOptionByLabel, hasPermission } from "utils/helpers";
import { useTable } from "hooks/useTableApi";
import { FormInstance } from "antd/lib/form";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined, EditOutlined, PlaySquareOutlined, ExclamationOutlined, MenuOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import api from "../../api";
import { Routes } from "../../api/routes"
import {IQuery} from "../../api/interfaces/Query";

import { useAxiosRequest } from "use-axios-request";
import { axiosConfig } from "utils/request";
import { ICategory } from "packages/progess-consultes/api/interfaces/Category";
import { dateFormats } from "utils/formats";
import qs from "qs";
import moment from "moment";

const { Text } = Typography;

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    name: undefined,
    category: undefined,
  })
}

const categoriesRequest = { ...axiosConfig, baseURL: "/", url: Routes.categoriesPath(), params: { items: "all" } };

const renderName = (item: IQuery) => {
  return (
    <>
      {item.attributes.name} <br/>
      <Text type="secondary">{item.attributes.description}</Text>
    </>
  )
};

const requiredFiltersText = () : React.ReactNode => (
  <div style={{padding: '1rem'}}>
    <ExclamationOutlined style={{fontSize: '2rem'}} />
    <div>Si us plau, selecciona una categoria</div>
  </div>
)


const defaultExecuteParams =  { from: moment(), to: moment() }

export const renderQueryActions = (params: {from: moment.Moment, to: moment.Moment} = defaultExecuteParams) => (text: string, record: any, index: number) : ReactElement => {
  const executeParams = { filters: { from: params.from.format(dateFormats.server), to: params.to.format(dateFormats.server) }}
  const executeURL = `/indicators/${record.id}/execute?${qs.stringify(executeParams, { encodeValuesOnly: true, arrayFormat: 'brackets' })}`

  return (
    <span>
      { record.meta.permissions.can_show && <Button type="link" href={executeURL} target="_blank" icon={<PlaySquareOutlined />} />}
      { record.meta.permissions.can_edit && <Button type="link" href={`/indicators/${record.id}`} target="_blank" icon={<EditOutlined />} />}
    </span>
  );
};

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem<{index: number}>() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: {index: number}) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={style}
      {...restProps}
    />
  );
};

const QueriesList: React.FC = () => {
  // useWhyDidYouUpdate('QueriesList', props);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();

  const [executeParams, setExecuteParams] = useState(defaultExecuteParams);
  const { filters: query } = useQuery();

  const categoriesLoaded = (data) => {
    const { category } = query || {};
    const { data: categories = [] } = data || {};

    if (!category && categories.length) {
      form.setFieldsValue({ category: categories[0].id });
      form.submit();
    }
  }

  const { tableProps, error, reload, refresh, search } = useTable<IQuery>(api.queries, { form, formResetCallback: searchFormResetCallback, requiredFilters: ["category"], requiredFiltersText });
  const { submit: searchSubmit } = search!;

  const { data: categoriesData, isFetching: isFetchingCategories } = useAxiosRequest<{ data: ICategory[] }>(categoriesRequest, { onSuccess: categoriesLoaded });
  const { data: categories = [] } = categoriesData || {};

  const sortEnabled = useMemo(() => {
    return hasPermission(userPermissions, "indicators:create");
  }, [userPermissions]);

  const columns: ColumnType<IQuery>[] = [
    {
      title: 'Nom',
      render: renderName,
      key: "name"
    },
    {
      title: 'Categories',
      key: "categories",
      dataIndex: ["attributes", "category_names"]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderQueryActions(executeParams)
    }
  ];

  if (sortEnabled) {
    columns.unshift({
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />,
    });
  }

  const handleExecutionFromChanged = (value) => {
    setExecuteParams({ ...executeParams, from: value })
  };

  const handleExecutionToChanged = (value) => {
    setExecuteParams({ ...executeParams, to: value })
  };

  const moveRow = useCallback(async (dragIndex, hoverIndex) => {
    const query = tableProps.dataSource[dragIndex];

    const response = await api.queries.changePosition(query.id, {
      category_id: form.getFieldValue("category"),
      position: hoverIndex + 1
    });

    if (response.isFail()) {
      message.error("No s'ha pogut actualitzar la posició de la consulta", 10);
    } else {
      refresh();
    }
  }, [form, refresh, tableProps.dataSource]);

  const toolbar = (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
        <div />
        <div>
          <PermissibleRender userPermissions={userPermissions} requiredPermissions={["indicators:create"]}>
            <Button icon={<PlusOutlined />} href="/indicators/new" target="_blank">Afegir</Button>
          </PermissibleRender>
        </div>
      </div>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
        <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
          <Form.Item name="category">
            <Select placeholder="Categoria" loading={isFetchingCategories} filterOption={filterOptionByLabel} dropdownMatchSelectWidth={false} allowClear showSearch onChange={form.submit}>
              {categories.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.name}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item name="name" style={{marginRight: 0}}>
            <Input.Search placeholder="Nom" onSearch={form.submit} />
          </Form.Item>
          {/* <Form.Item>
            <Button type="link" onClick={resetSearh}>Reiniciar</Button>
            <Button type="link" onClick={form.submit}>Filtrar</Button>
          </Form.Item> */}
        </Form>
        <Form layout="inline" initialValues={defaultExecuteParams}>
          <Form.Item name="from">
            <DatePicker className="w100" allowClear={false} format={dateFormats.display} placeholder="Des de" onChange={handleExecutionFromChanged} />
          </Form.Item>
          <Form.Item name="to" style={{marginRight: 0}}>
          <DatePicker className="w100" allowClear={false} format={dateFormats.display} placeholder="Fins" onChange={handleExecutionToChanged} />
          </Form.Item>
        </Form>
      </div>
    </>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      { sortEnabled &&
        <DndProvider backend={HTML5Backend}>
          <Table columns={columns} rowKey="id" {...tableProps} onRow={(record, index) => ({ index, moveRow } as React.HTMLAttributes<HTMLElement>)} components={{ body: { row: DraggableBodyRow }}} />
        </DndProvider>
      }

      { !sortEnabled &&
        <Table columns={columns} rowKey="id" {...tableProps} />
      }
    </PageHeaderWrapper>
  );
};

export default QueriesList;
