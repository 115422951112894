import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IAvEstructuraResult, IAvEstructuresResult, IAvEstructura } from '../../interfaces/community/AvEstructura'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class AvEstructura extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly community_id: string

  protected dateFields: string[] = ["data"];

  constructor(community_id: string, host?: string) {
    super(host);
    this.community_id = community_id;
  }

  public async list(params: IQuery = {}): Promise<IAvEstructuresResult> {
    return await this.request(GET, Routes.avEstructuresPath(this.community_id), {}, params) as IAvEstructuresResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IAvEstructuraResult> {
    return await this.request(GET, Routes.avEstructuraPath(this.community_id, id), {}, params) as IAvEstructuraResult
  }

  public async create(params: JsonApiDocument): Promise<IAvEstructuraResult> {
    return await this.request(POST, Routes.avEstructuresPath(this.community_id), {}, params) as IAvEstructuraResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IAvEstructuraResult> {
    return await this.request(PATCH, Routes.avEstructuraPath(this.community_id, id), {}, params) as IAvEstructuraResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.avEstructuraPath(this.community_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IAvEstructura {
    return {
      id: null,
      type: "users",
      attributes: {
        necessitat_accessibilitat: "",
        percepcio_comunitat: "",
        expedient_urbanistic: "",
        bustia: "",
        porta_entrada: "",
        interfons: "",
        pintura_escala: "",
        claraboia: "",
        vidres_finestres: "",
        llums: "",
        bombetes: "",
        filtres_aigua: "",
        esquerdes: "",
        teulada_facana: "",
        baixants: "",
        subministrament: "",
        neteja: "",
        necessitat_desinfeccio_comunitari: "",
        necessitat_desinfeccio_privatiu: "",
        gestio_espais_comuns: "",
        data: moment(),
        observacions: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
