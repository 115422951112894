import React, { useMemo } from "react"
import {Col, DatePicker, Form, Input, Row, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "utils/formats";

import ComboServeiContactes from "components/Forms/ComboServeiContactes";
import { useAxiosRequest } from "use-axios-request";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { filterOptionByLabel } from "utils/helpers";
import { CommunityType } from "pages/communities/model";
import { rowConfig, twoColumns } from "utils/constants";

export interface AdministracioFormProps {
  expedientId: string;
  form: FormInstance;
  readOnly?: boolean;
  eventBusPrefix: string;
}

const rules = {
  data_inici: [ { required: true, message: "Aquest camp és requerit" } ],
  tipus_consulta_id: [ { required: true, message: "Aquest camp és requerit" } ],
  ccvv_id: [ { required: true, message: "Aquest camp és requerit" } ],
  servei_id: [ { required: true, message: "Aquest camp és requerit" } ],
};

const AdministracioForm: React.FC<AdministracioFormProps> = ({ expedientId, readOnly = false, form, eventBusPrefix }) => {
  const communitiesRequest = useMemo(() => ({ ...axiosConfig, baseURL: "/", url: Routes.communitiesPath(), params: { items: "all", filters: { "expedients_id_eq": expedientId }}}), [expedientId]);
  const { data: communitiesData, isFetching: isFetchingCommunities } = useAxiosRequest<{ data: CommunityType[] }>(communitiesRequest);
  const { data: communities = [] } = communitiesData || {};

  return (
    <>
      <Form.Item name={["attributes", "expedient_id"]} hidden>
        <Input type="hidden" name='expedient_id' value={expedientId} />
      </Form.Item>

      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "data_inici"]} label="Data d'inici" rules={rules.data_inici}>
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "data_fi"]} label="Data fi">
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={["attributes", "ccvv_id"]} label="Comunitat" rules={rules.ccvv_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingCommunities} filterOption={filterOptionByLabel} showSearch>
          {communities.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.address_fmt}</Select.Option>)}
        </Select>
      </Form.Item>

      <ComboServeiContactes
        form={form}
        eventBusPrefix={eventBusPrefix}
        rules={rules}
        serviceFilters={{is_administracio_finques_eq: true}}
        labels={{ servei: "Administració de finques", contactes: "Contactes (administració de finques)" }}
      />

      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default AdministracioForm;
