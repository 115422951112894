import React, {useCallback, useMemo} from "react";
import NotFound from "components/NotFound";
import AvaluacioItem, {AvaluacioItemProps} from "./avaluacio_item";
import { emit as busDispatchEvent } from 'react-gbus';
import api from "api";
import { List } from "antd";
import AvConvivenciaForm from "../forms/AvConvivenciaForm";
import AvEstructuraForm from "../forms/AvEstructuraForm";
import AvParticipacioForm from "../forms/AvParticipacioForm";
import AvOrganitzacioForm from "../forms/AvOrganitzacioForm";
import {renderRecordDate, renderObservations} from "../../../utils/helpers";
import AvaluacioScore from "./avaluacio_score";

interface AvaluacioDahsboardProps {
  communityId: string
}

const AvaluacioDahsboard: React.FC<AvaluacioDahsboardProps> = ({ communityId }) => {
  const apiConvivencies = useMemo(() => api.avConvivencies(communityId), [communityId]);
  const apiEstructures = useMemo(() => api.avEstructures(communityId), [communityId]);
  const apiOrganitzacions = useMemo(() => api.avOrganitzacions(communityId), [communityId]);
  const apiParticipacions = useMemo(() => api.avParticipacions(communityId), [communityId]);

  const reloadScores = useCallback(() => {
    busDispatchEvent(`@@community/${communityId}/reloadScores`);
  }, [communityId]);

  if (!communityId) { return <NotFound /> }

  const listItems : AvaluacioItemProps[] = [
    {
      title: "Organització",
      description: "Comunitat constituïda, existència d'òrgans de representació; realització de reunions (nombre); requeriments legals (assegurança, ITE, pagament quotes...)",
      apiEndpoint: apiOrganitzacions,
      reloadScores: reloadScores,
      formComponent: AvOrganitzacioForm,
      historyColumns: [
        {
          title: "Data",
          key: "date",
          render: renderRecordDate("data")
        },
        { key: "comunitat_constituida", title: "Comunitat constituïda", dataIndex: ["attributes", "comunitat_constituida"] },
        { key: "organs_representacio_interns", title: "Exist. òrgans repr. interns", dataIndex: ["attributes", "organs_representacio_interns"] },
        { key: "efectivitat_organs_interns", title: "Efect. òrgans repr. interns", dataIndex: ["attributes", "efectivitat_organs_interns"] },
        { key: "organs_representacio_externs", title: "Exist. òrgans repr. externs", dataIndex: ["attributes", "organs_representacio_externs"] },
        { key: "efectivitat_organs_externs", title: "Efect. òrgans repr. externs", dataIndex: ["attributes", "efectivitat_organs_externs"] },
        { key: "rotacio_carrecs", title: "Rotació càrrecs", dataIndex: ["attributes", "rotacio_carrecs"] },
        { key: "reunions_ordinaries_anuals", title: "Realit. reunions ord. anuals", dataIndex: ["attributes", "reunions_ordinaries_anuals"] },
        { key: "asseguranca", title: "Assegurança", dataIndex: ["attributes", "asseguranca"] },
        { key: "ite", title: "ITE", dataIndex: ["attributes", "ite"] },
        { key: "pagament_quotes", title: "Pag. quotes", dataIndex: ["attributes", "pagament_quotes"] },
        { key: "pagament_derrames", title: "Pag. derrames", dataIndex: ["attributes", "pagament_derrames"] },
        { key: "demanda_subvencio", title: "Demanda de subvenció", dataIndex: ["attributes", "demanda_subvencio"] },
        { key: "fons_reserva", title: "Fons de reserva", dataIndex: ["attributes", "fons_reserva"] },
        { key: "existencia_estatus", title: "Exist. estatuts", dataIndex: ["attributes", "existencia_estatus"] },
        { key: "normes_regim_intern", title: "Exist. normes", dataIndex: ["attributes", "normes_regim_intern"] },
      ]
    },
    {
      title: "Participació",
      description: "Qui participa, forma de desenvolupar les reunions i presa de decisions, tipologia de grup i lideratge.",
      apiEndpoint: apiParticipacions,
      reloadScores: reloadScores,
      formComponent: AvParticipacioForm,
      historyColumns: [
        {
          title: "Data",
          key: "date",
          render: renderRecordDate("data")
        },
        {
          title: 'Participació reunions junta',
          key: "participacio_reunions_junta",
          dataIndex: ["attributes", "participacio_reunions_junta"]
        },
        {
          title: 'Gestió no propietaris',
          key: "gestio_no_propietaris",
          dataIndex: ["attributes", "gestio_no_propietaris"]
        },
        {
          title: 'Desenvolupament reunions',
          key: "desenvolupament_reunions",
          dataIndex: ["attributes", "desenvolupament_reunions"]
        },
        {
          title: 'Presa decisions',
          key: "presa_decisions",
          dataIndex: ["attributes", "presa_decisions"]
        },
        {
          title: 'Tipologia grups',
          key: "tipologia_grups",
          dataIndex: ["attributes", "tipologia_grups"]
        },
        {
          title: 'Observacions',
          key: "observations",
          ellipsis: { showTitle: false },
          render: renderObservations
        },
      ]
    },
    {
      title: "Estructura, instal·lacions i manteniment",
      description: "Estat de l'edifici, manteniment, gestió d'espais i elements comuns, neteja.",
      apiEndpoint: apiEstructures,
      reloadScores: reloadScores,
      formComponent: AvEstructuraForm,
      historyColumns: [
        {
          title: "Data",
          key: "date",
          render: renderRecordDate("data")
        },
        { key: "necessitat_accessibilitat", title: "Necessitat d’Access.", dataIndex: ["attributes", "necessitat_accessibilitat"] },
        { key: "percepcio_comunitat", title: "Perc. estat edifici", dataIndex: ["attributes", "percepcio_comunitat"] },
        { key: "expedient_urbanistic", title: "Exp. urbanístic", dataIndex: ["attributes", "expedient_urbanistic"] },
        { key: "bustia", title: "Bústies", dataIndex: ["attributes", "bustia"] },
        { key: "porta_entrada", title: "Porta entrada", dataIndex: ["attributes", "porta_entrada"] },
        { key: "interfons", title: "Intèrfons", dataIndex: ["attributes", "interfons"] },
        { key: "pintura_escala", title: "Pintura escala", dataIndex: ["attributes", "pintura_escala"] },
        { key: "claraboia", title: "Claraboia", dataIndex: ["attributes", "claraboia"] },
        { key: "vidres_finestres", title: "Vidres finestres", dataIndex: ["attributes", "vidres_finestres"] },
        { key: "llums", title: "Llums", dataIndex: ["attributes", "llums"] },
        { key: "bombetes", title: "Bombetes", dataIndex: ["attributes", "bombetes"] },
        { key: "filtres_aigua", title: "Filt. d’aigua", dataIndex: ["attributes", "filtres_aigua"] },
        { key: "esquerdes", title: "Esquerdes", dataIndex: ["attributes", "esquerdes"] },
        { key: "teulada_facana", title: "Teulada - Façana", dataIndex: ["attributes", "teulada_facana"] },
        { key: "baixants", title: "Baixants", dataIndex: ["attributes", "baixants"] },
        { key: "subministrament", title: "Subminis.", dataIndex: ["attributes", "subministrament"] },
        { key: "neteja", title: "Neteja", dataIndex: ["attributes", "neteja"] },
        { key: "necessitat_desinfeccio_comunitari", title: "Des/Neteja comunitari", dataIndex: ["attributes", "necessitat_desinfeccio_comunitari"] },
        { key: "necessitat_desinfeccio_privatiu", title: "Des/Neteja privatiu", dataIndex: ["attributes", "necessitat_desinfeccio_privatiu"] },
        { key: "gestio_espais_comuns", title: "Ocupació esp. comuns", dataIndex: ["attributes", "gestio_espais_comuns"] },
      ]
    },
    {
      title: "Convivència",
      description: "Clima a la comunitat, relació entre les persones que hi viuen, forma de gestionar els conflictes, situacions de violència explícita.",
      apiEndpoint: apiConvivencies,
      reloadScores: reloadScores,
      formComponent: AvConvivenciaForm,
      historyColumns: [
        {
          title: "Data",
          key: "date",
          render: renderRecordDate("data")
        },
        {
          title: "Relacions",
          key: "relacions",
          dataIndex: ["attributes", "relacions"]
        },
        {
          title: "Gestió conflictes",
          key: "gestio_conflictes",
          dataIndex: ["attributes", "gestio_conflictes"]
        },
        {
          title: "Violència",
          key: "violencia",
          dataIndex: ["attributes", "violencia"]
        },
        {
          title: "Delinqüència",
          key: "delinquencia",
          dataIndex: ["attributes", "delinquencia"]
        },
        {
          title: "Vandalisme",
          key: "vandalisme",
          dataIndex: ["attributes", "vandalisme"]
        },
        {
          title: 'Observacions',
          key: "observations",
          ellipsis: { showTitle: false },
          render: renderObservations
        },
      ]
    }
  ];

  return (
    <>
      <AvaluacioScore communityId={communityId}/>
      <List
        // grid={{
        //   gutter: 16,
        //   xs: 1,
        //   sm: 1,
        //   md: 2,
        //   lg: 2,
        //   xl: 2,
        //   xxl: 2,
        // }}
        itemLayout="vertical"
        dataSource={listItems}
        renderItem={(itemConfig) => <AvaluacioItem {...itemConfig} />}
      />
    </>
  );
};

export default AvaluacioDahsboard;
