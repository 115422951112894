import React, {useCallback} from "react";
import { Button, Table, Alert, message } from "antd";
import { trim } from "lodash";
import { ColumnType } from "antd/lib/table/interface";
import { useRouter } from "hooks/useRouter";
import { useInnerTable } from "hooks/useInnerTable";

import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerForm";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerForm";
import { FlatType, FlatTypeRecord } from "pages/flats/model";
import FlatForm from "pages/flats/form";
import { renderRecordActions, renderObservations } from "utils/helpers";
import ContactesSection from "pages/shared/contacts/list";
import request from "utils/request";
import { PlusOutlined } from "@ant-design/icons";


const renderPlantaPisPorta = (text: string, record: FlatType, index: number) : string => {
  let value = "";

  if (record.attributes.escala) value = `E: ${record.attributes.escala}`;
  if (record.attributes.pis) value = value + ` Pl: ${record.attributes.pis}`;
  if (record.attributes.porta) value = value + ` Pt: ${record.attributes.porta}`;

  return trim(value);
}

const FlatsSection: React.FC = () => {
  const { query: { communitiyId } } = useRouter();

  const expandedRowRender = useCallback((record: FlatType): React.ReactNode => {
    return <ContactesSection type="Flat" id={record.id} context="all" hideToolbar style={{marginTop: "5px", marginBottom: 0}} />;
  },[]) ;


  const baseUrl = React.useMemo(() => "flats", []);
  const baseQuery  = React.useMemo(() => ({ community_id_eq: communitiyId }) , [communitiyId])

  const { tableProps, refresh, error, reload } = useInnerTable<FlatType>(baseUrl, { baseQuery });

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<FlatType> => {
    const newRecord = {
      ...FlatTypeRecord,
      meta: {
        permissions: {
          can_edit: true,
          can_show: true
        }
      }
    };

    newRecord.attributes.ccvv_id = communitiyId;

    return {
      title: "Pis / Local",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord
    }
  }, [communitiyId, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<FlatType>(baseUrl, formOptions);

  const destroy = async (id) => {
    const response = await request(`${baseUrl}/${id}`, { method: "delete" })

    if (response.success) {
      message.success("Registre elimintat correctament");
      refresh();
    } else {
      message.error("Ha hagut un error a l'esborrar el registre", 10);
    }
  }

  const columns: ColumnType<FlatType>[] = [
    {
      title: 'Escala/Planta/Porta',
      key: "street",
      render: renderPlantaPisPorta
    },
    {
      title: 'Tipus',
      dataIndex: ["attributes", "descripcio_us_local"],
      key: "type"
    },
    {
      title: 'Barri',
      dataIndex: ["attributes", 'nom_barri'],
      key: "nom_barri"
    },
    {
      title: 'Codi postal',
      dataIndex: ["attributes", 'codi_postal'],
      key: "codi_postal"
    },
    {
      title: 'Referència cadastral',
      dataIndex: ["attributes", "referencia_cadastral"],
      key: "referencia_cadastral"
    },
    {
      title: 'Quota',
      dataIndex: ["attributes", "quota_participacio"],
      key: "quota_participacio"
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, destroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
       <div>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} expandable={{ expandedRowRender }}/>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <FlatForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default FlatsSection;
