import React, { useCallback, useState, useMemo } from "react";
import { FormInstance, Rule } from "antd/lib/form";
import { useAxiosRequest } from "use-axios-request";
import { IServei } from "api/interfaces/Servei";
import { IContact } from "api/interfaces/Contact";
import { AxiosError } from "axios";
import { notification, Form, Select, Button } from "antd";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { useListener } from "react-gbus";
import { FORM_SHOWN, DrawerFormWithForwardRef, DrawerFormChildProps } from "components/DrawerFormApi";
import {codeMessage} from "utils/constants";
import api from "api";
import { filterOptionByLabel } from "utils/helpers";
import { PlusOutlined } from "@ant-design/icons";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import CreateServeiForm from "pages/serveis/create";
import {IContacting} from "../../api/interfaces/Contacting";
import {ModalFormChildProps, ModalFormWithForwardRef} from "../ModalFormApi";
import CreateContactingForm from "../../pages/shared/contacts/create_contacting_form";

export interface ComboServeiContactesProps {
  form: FormInstance;
  readOnly?: boolean;
  eventBusPrefix: string;
  rules?: { [key: string]: Rule[] }
  serviceFilters?: { [key: string]: any }
  labels?: { servei: string, contactes: string }
}

const contactesRequest = (serviceID) => {
  return {
    ...axiosConfig,
    baseURL: "/",
    url: Routes.contactsPath(),
    params: { items: "all", filters: {for: `Servei:${serviceID}`}}
  };
}

const defaultLabels = {
  servei: "Servei",
  contactes: "Contactes del servei"
}

const ComboServeiContactes: React.FC<ComboServeiContactesProps> = ({ readOnly, form, eventBusPrefix, rules, serviceFilters, labels = defaultLabels }) => {
  const [serveiID, setServeiID] = useState<number | string>();
  const [contactes, setContactes] = useState<IContact[]>([]);

  const contactesRequestCallbacks = useMemo(() => ({
    onSuccess: (data: CollectionResponse<IContact>) => {
      setContactes(data.data);
    },
    onError: (error: AxiosError) => {
      setContactes([]);

      const { response } = error;

      if (response && response.status) {
        const { status } = response;
        const errorText = codeMessage[response.status] || response.statusText;

        notification.error({
          message: `Error ${status}`,
          description: errorText,
        });
      } else if (!response) {
        notification.error({
          message: `Error`,
          description: "Hi hagut un error desconegut"
        });
      }
    }
  }), []);

  const serveisRequest = useMemo(() => ({ ...axiosConfig, url: "serveis", params: { items: "all", filters: {...serviceFilters} }}), [serviceFilters]);
  const { data: serveisData, isFetching: isFetchingServeis, refresh: reloadServices } = useAxiosRequest<{ data: IServei[] }>(serveisRequest);
  const { data: serveis = [] } = serveisData || {};

  const { isFetching: isFetchingContactes, update: loadContacts } = useAxiosRequest<{ data: IContact[] }>(null, contactesRequestCallbacks);

  const handleServiceChange = useCallback((value: string) => {
    setServeiID(value);

    form.setFieldsValue({attributes: {servei_contactes_ids: []}});
    loadContacts(contactesRequest(value));
  }, [setServeiID, loadContacts, form]);

  const handleFormLoaded = useCallback((payload) => {
    const { values }: { values: { attributes: any } } = payload;
    const { attributes: { servei_id }} = values;

    setServeiID(servei_id);

    if (servei_id) {
      loadContacts(contactesRequest(servei_id));
    }
  }, [loadContacts]);

  useListener(handleFormLoaded, [`@@form/${eventBusPrefix}/${FORM_SHOWN}`]);

  const contactingFormOptions = React.useMemo(() : useDrawerFormOptionsType<IContacting> => {
    const addContact = (item: IContacting) => {
      setContactes(contactes.concat(item.attributes.contact.data));

      // Use concat to create a new array so form get updated
      const contactIds = form
        .getFieldValue(["attributes", "servei_contactes_ids"])
        .concat(item.attributes.contact.data.id);
      form.setFieldsValue({attributes: {servei_contactes_ids: contactIds}});
    };

    return {
      title: "Associació contacte",
      handleCreated: addContact,
      handleUpdated: addContact,
      newRecord: api.contactings.newInstance({
        contactable_id: serveiID,
        contactable_type: "Servei"
      })
    }
  }, [serveiID, contactes, form])

  const { create: createContacting, drawerProps: contactingDrawerProps } = useDrawerForm<IContacting>(api.contactings, contactingFormOptions);


  const serveiFormOptions = React.useMemo(() : useDrawerFormOptionsType<IServei> => {
    const addServei = (item: IServei) => {
      reloadServices();
      form.setFieldsValue({attributes: {servei_id: parseInt(item.id)}});
      handleServiceChange(item.id);
    };

    return {
      title: "Nou servei",
      handleCreated: addServei,
      handleUpdated: addServei,
      newRecord: api.serveis.newInstance({ is_administracio_finques: true })
    }
  }, [form, handleServiceChange, reloadServices]);

  const { create: createServei, drawerProps: serveiDrawerProps } = useDrawerForm<IServei>(api.serveis, serveiFormOptions);

  return (
    <>
      <Form.Item name={["attributes", "servei_id"]} label={labels.servei} rules={rules.servei_id} >
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingServeis} onChange={handleServiceChange}
                filterOption={filterOptionByLabel} showSearch
                notFoundContent={<Button icon={<PlusOutlined />} onClick={createServei} type="link">Afegir servei</Button>}>
          {serveis.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "servei_contactes_ids"]} label={labels.contactes}>
        <Select disabled={readOnly || !serveiID} placeholder="Selecciona els elements" mode="multiple" loading={isFetchingServeis || isFetchingContactes}
                filterOption={filterOptionByLabel} showSearch
                notFoundContent={<Button icon={<PlusOutlined />} onClick={createContacting} type="link">Associar contacte</Button>}>
          {contactes.map((item) => <Select.Option key={item.id} value={item.id}>{item.attributes.full_name}</Select.Option>)}
        </Select>
      </Form.Item>

      <ModalFormWithForwardRef {...contactingDrawerProps}>
        {({ form }: ModalFormChildProps) => (
            <CreateContactingForm form={form} rolesGroup="Servei" />
        )}
      </ModalFormWithForwardRef>

      <DrawerFormWithForwardRef {...serveiDrawerProps}>
        {({ form }: DrawerFormChildProps) => (
          <CreateServeiForm form={form} />
        )}
      </DrawerFormWithForwardRef>
    </>
  )
}

export default ComboServeiContactes;
