import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Menu, Layout, message } from 'antd';
import { PageHeaderWrapper } from "@ant-design/pro-layout";
import DictionaryTable from "./dictionary"
import { find, orderBy } from "lodash";
import Scrollbar from "react-scrollbars-custom";

const { Sider, Content } = Layout;

type DictionaryDefinition = {
  name: string
  path: string
}

const DICTIONARIES : DictionaryDefinition[] = orderBy([
  { path: "canals_entrada", name: "Canals d’entrada" },
  { path: "demandants_expedients", name: "Qui fa la demanda" },
  { path: "motius_tancament", name: "Motius de tancament" },
  { path: "tipus_gestions", name: "Tipus gestions expedients" },
  { path: "tipus_intervencions", name: "Tipus intervencions" },
  { path: "grups_edat", name: "Grups d'edat" },
  { path: "calendar/categories", name: "Calendari: Categories" },
  { path: "attachment_types", name: "Tipus d'adjunts" },
  // { path: "tipus_coordinacions", name: "Tipus coordinacions" },
  // { path: "tipus_consultes", name: "Tipus consultes tipus" },
  { path: "rols_incidencies", name: "Rols incidències" },
  { path: "estats_ite", name: "Estats ITE" },
  { path: "tipus_incidencies", name: "Incidències (tipus i àmbits)" },
  { path: "ambits_tipus_incidencies", name: "Àmbits incidències" },
  // { path: "abast_incidencies", name: "Abast incidències" },
  { path: "participacio_organs", name: "Participació òrgans junta" },
  { path: "regims", name: "Tipus de règim" },
  { path: "tipus_propietat", name: "Tipus de propietat" },
  { path: "tipus_rol_contacte", name: "Contactes: rols" },
  // This dicts can't be modified
  { path: "av_colors", name: "Avaluació: Colors"},
  { path: "av_d_si_no_nsnc_tramit", name: "Avaluació: Tràmit"},
  { path: "av_d_presa_decisions", name: "Avaluació: Presa decisions"},
  { path: "av_d_gestio_conflictes", name: "Avaluació: Conflictes"},
  { path: "av_d_avaluacio_trams", name: "Avaluació: Trams"},
  { path: "av_d_desenvolupament_reunions", name: "Avaluació: Desenv. reunions"},
  { path: "av_d_gestio_no_propietaris", name: "Avaluació: Gestió no propietaris"},
  { path: "av_d_tipologia_grups", name: "Avaluació: Tipología grups"},
  { path: "av_d_relacions", name: "Avaluació: Relacions"},
  { path: "av_d_estat_edifici", name: "Avaluació: Estat edifici"},
  { path: "av_d_neteja", name: "Avaluació: Neteja"},
], ["name", "asc"]);

const DictionariesSection: React.FC = () => {
  const history = useHistory();

  const { dictionary: path } = useParams<{ dictionary: string}>();

  const dictionary = find(DICTIONARIES, { path });

  const handleClick = (menuItem) => {
    const dict = find(DICTIONARIES, { path: menuItem.key} );

    if (dict) {
      history.push(`/preferences/dictionaries/${dict.path}`);
    } else {
      history.push('/preferences/dictionaries');
      message.error("El diccionari que intentes modificar no existeix");
    }
  };

  const menuItems = DICTIONARIES.map((dict) => ({ key: dict.path, label: dict.name }));

  return (
    <PageHeaderWrapper>
      <Layout>
        <Sider theme="light" width={300}>
          <Scrollbar style={{ width: 290, height: "calc(100vh - 225px)" }}>
            <Menu onClick={handleClick} mode="inline" items={menuItems} defaultSelectedKeys={dictionary ? [dictionary.path] : []} />
          </Scrollbar>
        </Sider>
        <Content style={{ padding: '0 50px' }}>
          {
            dictionary
            ? <DictionaryTable path={dictionary.path} title={dictionary.name} />
            : <span>Selecciona un diccionari de la llista</span>
          }
        </Content>
      </Layout>
    </PageHeaderWrapper>
  );
};

export default DictionariesSection;
