import React from "react";
import { DrawerFormChildProps } from "components/DrawerFormApi";
import { dateFormats } from "utils/formats";
import {Form, DatePicker, Input, Select, Row, Col, Typography} from "antd";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import {rowConfig, twoColumns} from "../../../utils/constants";
import {axiosConfig} from "utils/request";
import {filterOptionByLabel} from "utils/helpers"

const { Text } = Typography;

const primerGrup = [
  [
    { name: "bustia", title: "Bústies" },
    { name: "porta_entrada", title: "Porta entrada" }
  ],
  [
    { name: "interfons", title: "Intèrfons" },
    { name: "pintura_escala", title: "Pintura escala" }
  ],
  [
    { name: "claraboia", title: "Claraboia" },
    { name: "vidres_finestres", title: "Vidres finestres" }
  ],
  [
    { name: "llums", title: "Llums" },
    { name: "bombetes", title: "Bombetes" }
  ],
  [
    { name: "filtres_aigua", title: "Filtracions d’aigua" },
    { name: "esquerdes", title: "Esquerdes" }
  ],
  [
    { name: "teulada_facana", title: "Teulada - Façana" },
    { name: "baixants", title: "Baixants" },
  ],
  [
    { name: "subministrament", title: "Subministrament" }
  ],
];

const segonGrup = [
  { name: "necessitat_desinfeccio_comunitari", title: "Necessitat desinfecció i/o neteja en profunditat comunitari" },
  { name: "necessitat_desinfeccio_privatiu", title: "Necessitat desinfecció i/o neteja en profunditat element privatiu" },
  { name: "gestio_espais_comuns", title: "Ocupació dels espais comuns amb elements privatius sense autorització/consens de la comunitat" }
];

const estatEdificiRequest = { ...axiosConfig, url: "av_d_estat_edifici", params: { items: "all" } };
const netejaRequest = { ...axiosConfig, url: "av_d_neteja", params: { items: "all" } };
const optionsRequest = { ...axiosConfig, url: "av_d_si_no_nsnc_tramit", params: { items: "all" } };

const AvEstructuraForm: React.FC<DrawerFormChildProps> = ({ form, readOnly }) => {
  const { data: estatEdifici = { data: [] }, isFetching: isFetchingEstatEdifici } = useAxiosRequest<{ data: DicitonaryType[] }>(estatEdificiRequest);
  const { data: neteja = { data: [] }, isFetching: isFetchingNeteja } = useAxiosRequest<{ data: DicitonaryType[] }>(netejaRequest);
  const { data: options = { data: [] }, isFetching: isFetchingOptions } = useAxiosRequest<{ data: DicitonaryType[] }>(optionsRequest);

  return (
    <>
      <Form.Item name={["attributes", "data"]} label="Data">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>

      <Form.Item name={["attributes", "necessitat_accessibilitat"]} label="Necessitat d’Accessibilitat">
        <Select placeholder="Selecciona un valor" loading={isFetchingEstatEdifici} filterOption={filterOptionByLabel} showSearch>
          {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item name={["attributes", "percepcio_comunitat"]} label="Percepció de la comunitat sobre estat de l’edifici">
        <Select placeholder="Selecciona un valor" loading={isFetchingEstatEdifici} filterOption={filterOptionByLabel} showSearch>
          {estatEdifici && estatEdifici.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item name={["attributes", "expedient_urbanistic"]} label="Expedient urbanístic">
        <Select placeholder="Selecciona un valor" loading={isFetchingEstatEdifici} filterOption={filterOptionByLabel} showSearch>
          {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>

      <Text strong>Elements en mal estat:</Text>

      {primerGrup.map((fields, index) => (
        <Row {...rowConfig}>
          {fields.map(field => (
            <Col key={index} {...twoColumns}>
              <Form.Item key={field.name}  name={["attributes", field.name]} label={field.title}>
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>
      ))}

      <Form.Item name={["attributes", "neteja"]} label="Neteja">
        <Select placeholder="Selecciona un valor" loading={isFetchingNeteja} filterOption={filterOptionByLabel} showSearch>
          {neteja && neteja.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>

      {segonGrup.map((field) => (
        <Form.Item key={field.name} name={["attributes", field.name]} label={field.title}>
          <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
            {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
          </Select>
        </Form.Item>
      ))}

      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default AvEstructuraForm;
