import { useReducer, useMemo, Reducer, useEffect, useCallback } from "react";
import { useUpdateEffect } from "@umijs/hooks";

import { FormInstance } from "antd/lib/form";
import { IQueryList } from "api/interfaces/Query";
import { TableProps } from "antd/lib/table";
import { Store } from "antd/lib/form/interface";

export interface useInnerTableReturnType<RecordType> {
  search?: {
    reset: () => void,
    submit: (formValues: Store) => void,
    searchQuery: { [name: string]: any }
  },
  tableProps: Partial<TableProps<RecordType>>,
  refresh: () => void;
  reload: () => void;
  loading: boolean;
  meta: { [name: string]: any };
  error: Error | null;
};

export interface UseInnerTableOptionsType {
  form?: FormInstance;
  formResetCallback?: (form: FormInstance) => void;
  current?: number;
  defaultPageSize?: number;
  baseQuery?: { [name: string]: any };
}

class UseInnerTableInitialState<RecordType> {
  // Current page
  current = 1;

  // Page size
  pageSize = 15;

  // Total items
  total = 0;

  // Array of records
  data: RecordType[] = [];

  // Meta data from api response
  meta: { [name: string]: any } = {};

  // Form data
  baseQuery: { [name: string]: any } = {};

  // Form data
  searchQuery: { [name: string]: any } = {};

  // Counter
  requestCounter = 0;

  // Loading
  loading = true;

  // LoadingError
  error = null;
}

const reducer = <RecordType>(state: UseInnerTableInitialState<RecordType>, action: { type: string; payload?: {} }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

export const useInnerTable = <RecordType>(api: IQueryList, options: UseInnerTableOptionsType = {}) : useInnerTableReturnType<RecordType> => {
  const initialState = useMemo(() => new UseInnerTableInitialState<RecordType>(), []);

  // No hay problema que se cree cada vez, porque el useState y useReducer
  // solo se inicializan la primera vez, por tanto no provoca redender
  const { current = 1, defaultPageSize = 15, baseQuery = {}, form, formResetCallback } = options;

  const [state, dispatch] = useReducer<Reducer<UseInnerTableInitialState<RecordType>, any>>(reducer, {
    ...initialState,
    current: current,
    pageSize: defaultPageSize,
    baseQuery: baseQuery
  });

  // Tienen que usar useCallback porque al devolverlas al principal
  // si no son la misma provocaria un re-render?
  const reload = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: {
        current: 1,
        searchQuery: {},
        requestCounter: state.requestCounter + 1,
      },
    });
  }, [state.requestCounter]);

  const refresh = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: { requestCounter: state.requestCounter + 1 },
    });
  }, [state.requestCounter]);

  const searchReset = useCallback(() => {
    if (!form) {
      return;
    }

    if (formResetCallback) {
      formResetCallback(form)
    } else {
      form.resetFields();
    }

    dispatch({
      type: 'updateState',
      payload: {
        current: 1,
        searchQuery: form.getFieldsValue()
      }
    });

    refresh();
  }, [dispatch, form, refresh, formResetCallback]);

  const searchSubmit = useCallback(
    (formValues: Store) : void => {
      if (!form) {
        return;
      }

      setTimeout(() => {
        dispatch({
          type: 'updateState',
          payload: {
            searchQuery: { ...state.searchQuery, ...formValues },
          },
        });

        refresh();
      });
    },
    [form, refresh, state.searchQuery],
  );

  useEffect(() => {
    refresh();
    // This is OK, we only call this on first mount to fire the axios request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    reload()
    // This is OK, we only call this if base URL changes
    // if we add reload, we have an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  useUpdateEffect(() => {
    const fetchData = async () => {
      const result = await api.list({
        page: state.current,
        items: state.pageSize,
        filters: { ...state.searchQuery, ...state.baseQuery }
      });

      if (result.isSuccess()) {
        const { page, count, vars: { items }} = result.success().meta.pagy;

        dispatch({
          type: 'updateState',
          payload: {
            data: result.success().data,
            meta: result.success().meta,
            current: page,
            total: count,
            pageSize: items,
            loading: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            data: [],
            current: 1,
            total: 0,
            pageSize: defaultPageSize,
            loading: false,
            error: result.fail().message
          }
        })
      }
    };

    dispatch({
      type: 'updateState',
      payload: {
        loading: true
      }
    })

    fetchData();
  }, [state.current, state.pageSize, state.requestCounter, dispatch, api])

  const handlePagination = useCallback<TableProps<RecordType>["onChange"]>(
    (pagination) => {
      dispatch({
        type: 'updateState',
        payload: {
          current: pagination.current,
          pageSize: pagination.pageSize,
          count: state.requestCounter + 1,
        }
      })
  }, [state.requestCounter]);

  const result : useInnerTableReturnType<RecordType> = {
    tableProps: {
      bordered: false,
      size: "small",
      dataSource: state.data,
      loading: state.loading,
      onChange: handlePagination,
      pagination: {
        size: "small",
        current: state.current,
        pageSize: state.pageSize,
        total: state.total,
        hideOnSinglePage: true
      }
    },
    refresh,
    reload,
    loading: state.loading,
    meta: state.meta,
    error: state.error
  }

  if (form) {
    result.search = {
      submit: searchSubmit,
      reset: searchReset,
      searchQuery: state.searchQuery,
    }
  }

  return result;
}
