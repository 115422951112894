import React from "react";

import { Form, Table, Alert, Button, Input, message } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useQuery } from "hooks/useRouter";
import { renderRecordActions, renderRecordDate, renderObservations } from "utils/helpers";
import { useTable } from "hooks/useTableApi";
import { FormInstance } from "antd/lib/form";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import { DrawerFormChildProps, DrawerFormWithForwardRef } from "components/DrawerFormApi";
import api from "api";
import {IContact} from "api/interfaces/Contact";
import ContactForm from "./form";

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    nom: undefined,
    cognoms: undefined,
    phone: undefined,
  })
}

const ContactsList: React.FC = () => {
  // useWhyDidYouUpdate('ContactsList', props);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();

  const { filters: query } = useQuery();

  const { tableProps, error, refresh, reload, search } = useTable<IContact>(api.contacts, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IContact> => {
    return {
      title: "Nou contacte",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.contacts.newInstance()
    }
  }, [refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IContact>(api.contacts, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await api.contacts.destroy(id);

    if (response.isSuccess()) {
      refresh();
    } else {
      const error = response.fail().summary || "No s'ha pogut eliminar el registre"
      message.error(error, 10);
    }
  }

  const columns: ColumnType<IContact>[] = [
    {
      title: 'Nom',
      dataIndex: ["attributes", 'nom'],
      key: "name"
    },
    {
      title: 'Cognoms',
      key: "cognoms",
      dataIndex: ["attributes", "cognoms"]
    },
    {
      title: 'E-mail',
      key: "email",
      dataIndex: ["attributes", "email"]
    },
    {
      title: 'Telèfon',
      key: "telefon",
      dataIndex: ["attributes", "telefon"]
    },
    /*{
      title: 'Principal',
      key: "principal",
      render: renderBooleanColumn("principal")
    },*/
    {
      title: 'Vàlid fins',
      key: "valid_until",
      render: renderRecordDate("valid_fins")
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="nom">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item name="cognoms">
          <Input placeholder="Cognoms" />
        </Form.Item>
        <Form.Item name="phone" style={{marginRight: 0}}>
          <Input placeholder="Telèfon" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["contacts:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form }: DrawerFormChildProps) => (
          <ContactForm form={form} />
        )}
      </DrawerFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default ContactsList;
