import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IExpedientCoordinacioResult, IExpedientCoordinacionsResult, IExpedientCoordinacio } from '../../interfaces/expedient/Coordinacio'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class ExpedientCoordinacions extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly expedient_id: string;

  protected dateFields: string[] = ["data_inici", "data_fi"];

  constructor(expedient_id: string, host?: string) {
    super(host);
    this.expedient_id = expedient_id;
  }

  public async list(params: IQuery = {}): Promise<IExpedientCoordinacionsResult> {
    return await this.request(GET, Routes.expedientCoordinacionsPath(this.expedient_id), {}, params) as IExpedientCoordinacionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientCoordinacioResult> {
    return await this.request(GET, Routes.expedientCoordinacioPath(this.expedient_id, id), {}, params) as IExpedientCoordinacioResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientCoordinacioResult> {
    return await this.request(POST, Routes.expedientCoordinacionsPath(this.expedient_id), {}, params) as IExpedientCoordinacioResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientCoordinacioResult> {
    return await this.request(PATCH, Routes.expedientCoordinacioPath(this.expedient_id, id), {}, params) as IExpedientCoordinacioResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientCoordinacioPath(this.expedient_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IExpedientCoordinacio {
    return {
      id: null,
      type: "coordinacio_expedient",
      attributes: {
        data_inici: moment(),
        data_fi: null,
        tipus_coordinacio_id: null,
        tipus_coordinacio_name: "",
        servei_id: null,
        servei_name: "",
        servei_contactes_ids: [],
        servei_contactes_names: "",
        observacions: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
