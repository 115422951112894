import React, { useState } from "react";
import Dropzone from "react-dropzone";
import {message, List, Modal, Select, Typography, Progress} from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { ActiveStorageFileUpload, DirectUploadProvider } from "@rbngzlv/react-activestorage-provider";

import {Attachment, AttachmentType} from "utils/models";
import request, {axiosConfig, RequestResult} from "utils/request";
import {filterOptionByLabel, readCookie} from "utils/helpers";
import { useAxiosRequest } from "use-axios-request";
import { AttachmentTypesResponse } from "utils/responses";

const { Option } = Select;
const { Paragraph } = Typography;

export interface UploadFormProps<T = Attachment> {
  title: string,
  createUrl: string, // The url where create new elements
  //form: FormInstance,
  visible: boolean;
  handleCreated: (items: T[]) => void,
  handleCancel: () => void,
}

export interface UploadFormState {
  category: string,
  loading: boolean,
}

const acceptedFileTypes = ".doc,.docx,.jpg,.jpeg,.odt,.fodt,.ods,.fods,.pdf,.png,.xls,.xlsx";
const categoriesRequest = { ...axiosConfig, url: "attachment_types", params: { items: "all" } };

const UploadForm = <T extends Attachment>(props: Readonly<UploadFormProps<T>>) => {
  const [state, setState] = useState<UploadFormState>({ category: "", loading: false});

  const { data: categories = { data: [] }, isFetching: isFetchingCategories } = useAxiosRequest<AttachmentTypesResponse>(categoriesRequest);

  const handleCategoryChange = (value: string): void => {
    setState({ ...state, category: value});
  };

  const handleCancel = () : void => {
    const { handleCancel } = props;

    setState({ ...state, loading: false });

    handleCancel();
  };

  const handleSubmit = async (signedIds: string[]) : Promise<void> =>  {
    const { createUrl } = props;
    const { category: category_id } = state;

    setState({ ...state, loading: true });

    const response = await request(createUrl, {
      method: "post",
      data: { "attachment": { attachment_type_id: category_id, files: signedIds } }
    });

    if (response.success)
      handleSubmitSuccess(response)
    else
      handleSubmitFailure();
  };

  const handleSubmitSuccess = (response: RequestResult) : void => {
    const { handleCreated } = props;

    const { data: { data: attachments }} = response;

    handleCreated(attachments);

    handleCancel();
    message.success("Document guardat correctament");
  };

  const handleSubmitFailure = () : void => {
    // param: error: AxiosError
    setState({ ...state, loading: false });

    handleCancel();
    message.error("Hi hagut un error desconegut", 10);
  };


  const { title, visible } = props;
  const { category, loading } = state;

  return (
    <Modal
      destroyOnClose
      title={title}
      onCancel={handleCancel}
      confirmLoading={loading}
      open={visible}
      footer={null}
    >
      <DirectUploadProvider
        // endpoint={{
        //   path: "/profile",
        //   model: "User",
        //   attribute: "avatar",
        //   method: "PUT",
        // }}
        headers={{
          "X-CSRF-Token": readCookie('CSRF-TOKEN')
        }}
        directUploadsPath="/api/active_storage/direct_uploads"
        multiple
        onSuccess={handleSubmit}
        render={({ handleUpload, uploads, ready }: { handleUpload: (files: any[]) => void, uploads: any[], ready: boolean}) : JSX.Element => (
          <div>
            {
              !uploads.length &&
              <div>
                <Select loading={isFetchingCategories} placeholder="Selecciona el tipus de fitxer" style={{ width: "100%" }} onChange={handleCategoryChange} filterOption={filterOptionByLabel} showSearch>
                  {categories && categories.data.map((cat: AttachmentType) => <Option key={cat.id} value={cat.id}>{cat.attributes.nom}</Option>)}
                </Select>
              </div>
            }

            { category && !uploads.length &&
              <div className="mt-20">
                <Dropzone onDrop={(acceptedFiles): void => handleUpload(acceptedFiles)}>
                  {({getRootProps, getInputProps}): JSX.Element => (
                    <div className="ant-upload ant-upload-drag">
                      <span tabIndex={0} className="ant-upload ant-upload-btn" role="button">
                        <div {...getRootProps()}>
                          <input {...getInputProps({disabled: (!ready || !category), accept: acceptedFileTypes})} />
                          <p className="ant-upload-drag-icon"><InboxOutlined  /></p>
                          <p className="ant-upload-text">Fes clic o arrossega el fitxer aquí per pujar-lo</p>
                          <p className="ant-upload-hint">{acceptedFileTypes} </p>
                        </div>
                      </span>
                    </div>
                  )}
                </Dropzone>
              </div>
            }

            {
              uploads.length > 0 &&
              <List
                size="small"
                itemLayout="vertical"
                dataSource={uploads}
                pagination={uploads.length > 4 ? {position: "bottom", defaultPageSize: 4, size: "small", hideOnSinglePage: true} : false}
                renderItem={(upload: ActiveStorageFileUpload): JSX.Element => (
                  <List.Item>
                    <Paragraph ellipsis={{ rows: 1, expandable: false }}>{upload.file.name}</Paragraph>
                    <Progress percent={upload.progress || 0} />
                  </List.Item>
                )}
              />
            }
          </div>
        )}
      />

    </Modal>
  );
}

export default UploadForm;
