import React from "react";
import { DrawerFormChildProps } from "components/DrawerFormApi";
import { dateFormats } from "utils/formats";
import { Form, DatePicker, Input, Select } from "antd";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import {axiosConfig} from "utils/request";
import {filterOptionByLabel} from "utils/helpers"

const tramsRequest = { ...axiosConfig, url: "av_d_avaluacio_trams", params: { items: "all" } };
const noPropietarisRequest = { ...axiosConfig, url: "av_d_gestio_no_propietaris", params: { items: "all" } };
const reunionsRequest = { ...axiosConfig, url: "av_d_desenvolupament_reunions", params: { items: "all" } };
const decisionsRequest = { ...axiosConfig, url: "av_d_presa_decisions", params: { items: "all" } };
const grupsRequest = { ...axiosConfig, url: "av_d_tipologia_grups", params: { items: "all" } };

const AvParticipacioForm: React.FC<DrawerFormChildProps> = ({ form, readOnly }) => {

  const { data: trams = { data: [] }, isFetching: isFetchingTrams } = useAxiosRequest<{ data: DicitonaryType[] }>(tramsRequest);
  const { data: noPropietaris = { data: [] }, isFetching: isFetchingNoPropietaris } = useAxiosRequest<{ data: DicitonaryType[] }>(noPropietarisRequest);
  const { data: reunions = { data: [] }, isFetching: isFetchingReunions } = useAxiosRequest<{ data: DicitonaryType[] }>(reunionsRequest);
  const { data: decisions = { data: [] }, isFetching: isFetchingDecisions } = useAxiosRequest<{ data: DicitonaryType[] }>(decisionsRequest);
  const { data: grups = { data: [] }, isFetching: isFetchingGrups } = useAxiosRequest<{ data: DicitonaryType[] }>(grupsRequest);

  return (
    <>
      <Form.Item name={["attributes", "data"]} label="Data">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "participacio_reunions_junta"]} label="Qui participa reunions de junta">
        <Select placeholder="Selecciona un valor" loading={isFetchingTrams} filterOption={filterOptionByLabel} showSearch>
          {trams && trams.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "gestio_no_propietaris"]} label="Participació en la gestió de la comunitat de persones sense títol de propietat">
        <Select placeholder="Selecciona un valor" loading={isFetchingNoPropietaris} filterOption={filterOptionByLabel} showSearch>
          {noPropietaris && noPropietaris.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "desenvolupament_reunions"]} label="Forma de desenvolupar les reunions">
        <Select placeholder="Selecciona un valor" loading={isFetchingReunions} filterOption={filterOptionByLabel} showSearch>
          {reunions && reunions.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "presa_decisions"]} label="Forma en la presa de decisions">
        <Select placeholder="Selecciona un valor" loading={isFetchingDecisions} filterOption={filterOptionByLabel} showSearch>
          {decisions && decisions.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "tipologia_grups"]} label="Tipologia de grups">
        <Select placeholder="Selecciona un valor" loading={isFetchingGrups} filterOption={filterOptionByLabel} showSearch>
          {grups && grups.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
}


export default AvParticipacioForm;
