import React, { useMemo } from "react"
import {DatePicker, Form, Input, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {axiosConfig} from "../../../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import { Routes } from "api/routes";
import { IExpedientIncidencia } from "api/interfaces/expedient/Incidencia";

import ComboServeiContactes from "components/Forms/ComboServeiContactes";

export interface ConsultaFormProps {
  form: FormInstance;
  expedientId: string;
  readOnly?: boolean;
  eventBusPrefix: string;
}

const rules = {
  data_realitzacio: [ { required: true, message: "Aquest camp és requerit" } ],
  tipus_consulta_id: [ { required: true, message: "Aquest camp és requerit" } ],
  servei_id: [ { required: true, message: "Aquest camp és requerit" } ],
};

const ConsultaForm: React.FC<ConsultaFormProps> = ({ expedientId, readOnly = false, form, eventBusPrefix }) => {
  const incidencesRequest = useMemo(() => ({ ...axiosConfig, baseURL: "/", url: Routes.expedientIncidenciesPath(String(expedientId)), params: { items: "all" }}), [expedientId]);
  const { data: incidenciesData, isFetching: isFetchingIncidencies } = useAxiosRequest<{ data: IExpedientIncidencia[] }>(incidencesRequest);
  const { data: incidencies = [] } = incidenciesData || {};

  return (
    <>
      <Form.Item name={["attributes", "expedient_id"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "data_realitzacio"]} label="Data" rules={rules.data_realitzacio}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>

      <ComboServeiContactes form={form} eventBusPrefix={eventBusPrefix} rules={rules} />

      <Form.Item name={["attributes", "incidence_ids"]} label="Incidències relacionades">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona les incidències" loading={isFetchingIncidencies} filterOption={filterOptionByLabel} showSearch>
          {incidencies.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.identifier}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default ConsultaForm;
