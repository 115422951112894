import React from "react"
import { Form, Input } from "antd"
import { FormInstance } from "antd/lib/form";
import RemoteDictionarySelect from "components/RemoteDictionarySelect";

export interface DictionaryFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ]
}

const TipusIncidenciaForm: React.FC<DictionaryFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "nom"]} label="Nom" rules={rules.required}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "ambit_tipus_incidencia_id"]} label="Àmbit" rules={rules.required}>
        <RemoteDictionarySelect path="ambits_tipus_incidencies" />
      </Form.Item>
    </>
  )
}

export default TipusIncidenciaForm;
