import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IExpedientIncidenciaResult, IExpedientIncidenciesResult, IExpedientIncidencia } from '../../interfaces/expedient/Incidencia'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'
import qs from 'qs'

export default class ExpedientIncidencies extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly expedient_id: string;

  protected dateFields: string[] = ["data_inici", "data_tancament"];

  constructor(expedient_id: string, host?: string) {
    super(host);
    this.expedient_id = expedient_id;
  }

  public async list(params: IQuery = {}): Promise<IExpedientIncidenciesResult> {
    return await this.request(GET, Routes.expedientIncidenciesPath(this.expedient_id), {}, params) as IExpedientIncidenciesResult
  }

  public exportUrl(format: string, params: IQuery = {}): string {
    return '/' + Routes.expedientIncidenciesPath(this.expedient_id) + `?${qs.stringify({ ...params, export: format}, { encodeValuesOnly: true, arrayFormat: 'brackets' })}`;
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientIncidenciaResult> {
    return await this.request(GET, Routes.expedientIncidenciaPath(this.expedient_id, id), {}, params) as IExpedientIncidenciaResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientIncidenciaResult> {
    return await this.request(POST, Routes.expedientIncidenciesPath(this.expedient_id), {}, params) as IExpedientIncidenciaResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientIncidenciaResult> {
    return await this.request(PATCH, Routes.expedientIncidenciaPath(this.expedient_id, id), {}, params) as IExpedientIncidenciaResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientIncidenciaPath(this.expedient_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IExpedientIncidencia {
    return {
      id: null,
      type: "incidencia_expedient",
      attributes: {
        affects_community: false,
        data_inici: moment(),
        data_tancament: null,
        tipus_incidencia_id: null,
        tipus_incidencia_name: "",
        motiu_tancament_id: null,
        motiu_tancament_name: "",
        alias: "",
        identifier: "",
        observacions: "",
        servei_ids: [],
        serveis_noms: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
