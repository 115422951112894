import React, {useCallback} from "react"
import { useListener } from 'react-gbus';
import {DatePicker, Form, Input, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {axiosConfig} from "../../../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "../../preferences/dictionaries/model";
import {filterOptionByLabel} from "utils/helpers"
import { BEFORE_CREATE } from "components/DrawerFormApi";
import { IUser } from "api/interfaces/User";

export interface NewExpedientFormProps {
  form: FormInstance;
  readOnly?: boolean;
  communityId: string;
  eventBusPrefix: string;
}

const rules = {
  data: [ { required: true, message: "Aquest camp és requerit" } ],
  canal_entrada_id: [ { required: true, message: "Aquest camp és requerit" } ],
  demandant_id: [ { required: true, message: "Aquest camp és requerit" } ],
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const canalsRequest = { ...axiosConfig, url: "canals_entrada", params: { items: "all" } };
const demandantsRequest = { ...axiosConfig, url: "demandants_expedients", params: { items: "all" } };
const usersRequest = { ...axiosConfig, url: "users", params: { items: "all", filters: { role_in: ["admin", "dinamitzador_servei"] } } };

const NewExpedientForm: React.FC<NewExpedientFormProps> = ({ readOnly = false, eventBusPrefix, communityId }) => {
  const { data: canalsData, isFetching: isFetchingCanals } = useAxiosRequest<{ data: DicitonaryType[] }>(canalsRequest);
  const { data: canals = [] } = canalsData || {};

  const { data: demandantsData, isFetching: isFetchingDemandants } = useAxiosRequest<{ data: DicitonaryType[] }>(demandantsRequest);
  const { data: demandants = [] } = demandantsData || {};

  const { data: usersData, isFetching: isFetchingUsers } = useAxiosRequest<{ data: IUser[] }>(usersRequest);
  const { data: users = [] } = usersData || {};

  const setCommunityParam = useCallback((payload) => {
    payload.values.community = communityId;
  }, [communityId])

  useListener(setCommunityParam, [`@@form/${eventBusPrefix}/${BEFORE_CREATE}`]);

  return (
    <>
      <Form.Item name={["attributes", "data_inici"]} label="Data" rules={rules.data}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "canal_entrada_id"]} label="Canal d'entrada" rules={rules.canal_entrada_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingCanals} filterOption={filterOptionByLabel} showSearch>
          {canals.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "referencia_canal_entrada"]} label="Referència canal d'entrada">
        <Input disabled={readOnly} placeholder="Nº de referència" />
      </Form.Item>
      <Form.Item name={["attributes", "demandant_id"]} label="Demandant" rules={rules.demandant_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingDemandants} filterOption={filterOptionByLabel} showSearch>
          {demandants.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "professional_id"]} label="Professional de referència" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingUsers} filterOption={filterOptionByLabel} showSearch>
          {users.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)}
        </Select>
      </Form.Item>
    </>
  )
};

export default NewExpedientForm;
