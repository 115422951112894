import React from "react";
import {Button, Table, Alert, Form} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";

import {FormInstance} from "antd/lib/form";
import {IQueryList} from "api/interfaces/Query";
import {JsonApiDocument} from "api/interfaces/JsonApi";
import {useUpdateEffect} from "@umijs/hooks";

interface AvaluacioListProps {
  apiEndpoint: IQueryList
  hideHistory: () => void,
  columns: ColumnType<JsonApiDocument>[],
  showCount: number
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    tipus_consulta: undefined
  })
};

const AvaluacioList: React.FC<AvaluacioListProps> = ({ apiEndpoint, columns, hideHistory, showCount }) => {
  const [form] = Form.useForm();

  const { tableProps, error, reload } = useInnerTable<JsonApiDocument>(apiEndpoint, {form, formResetCallback });

  useUpdateEffect(() => {
    reload();
  }, [showCount]);

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-start' }}>
        <Button type="ghost" onClick={hideHistory}>Ocultar historial</Button>
      </div>

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </>
  );
};

export default AvaluacioList;
