import React, { useMemo } from "react";
import { Tabs, Row, Form, Col, Select, Input, Button, DatePicker } from "antd";
import { FormInstance } from "antd/lib/form";
import { rowConfig, fourColumns, sixColumns } from "utils/constants";
import { filterOptionByLabel } from "utils/helpers";
import { dateFormats, dateRangeFromServer } from "utils/formats";
import ComboCarrerPortal from "components/WebService/ComboCarrerPortal";
import DistricteSelector from "components/WebService/DistricteSelector";
import BarriSelector from "components/WebService/BarriSelector";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import { compact } from "lodash";
import moment from "moment";
import { axiosConfig } from "utils/request";
import { useQuery } from "hooks/useRouter";
import { Store } from "antd/lib/form/interface";
import FlatsSearcher from "pages/shared/searchers/flats";
import { IUser } from "api/interfaces/User";
import { Tab } from "rc-tabs/lib/interface";

export interface ExpedientsSearcherProps {
  form: FormInstance;
  loading: boolean;
  searchSubmit: (formValues: Store) => void,
  resetSearh: () => void
}

export const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    status: "all",
    canal_entrada: undefined,
    motiu_tancament: undefined,
    start_between: [undefined, undefined],
    end_between: [undefined, undefined],
    districte: undefined,
    barri: undefined,
    demandant: undefined,
    carrer: undefined,
    portal: undefined,
    professional: undefined,
    referencia_cadastral: undefined,
    num_expedient: undefined,
    had_has_not_incidencia: undefined,
    has_incidencia: undefined,
    had_has_not_gestions: undefined,
    has_gestions: undefined,

    has_coord_serveis: undefined,
    has_cons_serveis: undefined,
    pis_has_regim: undefined,
    pis_has_participacio: undefined,
    pis_has_tipus_propietat: undefined,
    pis_has_propietari: undefined,
  })
}

const { RangePicker } = DatePicker;

const canalsRequest = { ...axiosConfig, url: "canals_entrada", params: { items: "all" } };
const demandantsRequest = { ...axiosConfig, url: "demandants_expedients", params: { items: "all" } };
const incidenciesRequest = { ...axiosConfig, url: "tipus_incidencies", params: { items: "all" } };
const tancamentsRequest = { ...axiosConfig, url: "motius_tancament", params: { items: "all" } };
const gestionsRequest = { ...axiosConfig, url: "tipus_gestions", params: { items: "all" } };
const serveisRequest = { ...axiosConfig, url: "serveis", params: { items: "all" } };
const usersRequest = { ...axiosConfig, url: "users", params: { items: "all", filters: { role_in: ["admin", "dinamitzador_servei"] } } };

const ExpedientsSearcher: React.FC<ExpedientsSearcherProps> = ({ form, loading, searchSubmit, resetSearh}) => {
  const { filters: query } = useQuery();

  const searchQuery = useMemo(() => {
    if (!query) {
      return {status: "all"};
    }

    const { start_between: startRange, end_between: endRange } = query;

    const start_between = startRange ? dateRangeFromServer(startRange) : undefined;
    const end_between = endRange ? dateRangeFromServer(endRange) : undefined;

    return { ...query, start_between, end_between };
  }, [query]);

  const { data: canalsData, isFetching: isFetchingCanals } = useAxiosRequest<{ data: DicitonaryType[] }>(canalsRequest);
  const { data: canals = [] } = canalsData || {};

  const { data: demandantsData, isFetching: isFetchingDemandants } = useAxiosRequest<{ data: DicitonaryType[] }>(demandantsRequest);
  const { data: demandants = [] } = demandantsData || {};

  const { data: gestionsData, isFetching: isFetchingGestions } = useAxiosRequest<{ data: DicitonaryType[] }>(gestionsRequest);
  const { data: gestions = [] } = gestionsData || {};

  const { data: incidenciesData, isFetching: isFetchingIncidencies } = useAxiosRequest<{ data: DicitonaryType[] }>(incidenciesRequest);
  const { data: incidencies = [] } = incidenciesData || {};

  const { data: serveisData, isFetching: isFetchingServeis } = useAxiosRequest<{ data: DicitonaryType[] }>(serveisRequest);
  const { data: serveis = [] } = serveisData || {};

  const { data: tancamentsData, isFetching: isFetchingTancaments } = useAxiosRequest<{ data: DicitonaryType[] }>(tancamentsRequest);
  const { data: tancaments = [] } = tancamentsData || {};

  const { data: usersData, isFetching: isFetchingUsers } = useAxiosRequest<{ data: IUser[] }>(usersRequest);
  const { data: users = [] } = usersData || {};

  const onFinish = (formValues: {[key: string]: any}) : void =>  {
    const startRange = compact<moment.Moment>(formValues["start_between"]);
    const endRange = compact<moment.Moment>(formValues["end_between"]);

    const start_between = startRange.length > 0
      ? [startRange[0].format('YYYY-MM-DD'), startRange[1].format('YYYY-MM-DD')].join(",")
      : undefined;

      const end_between = endRange.length > 0
      ? [endRange[0].format('YYYY-MM-DD'), endRange[1].format('YYYY-MM-DD')].join(",")
      : undefined;

    searchSubmit({ ...formValues, start_between, end_between });
  }

  const tabs: Tab[] = [
    {
      label: "General",
      key: "1",
      children: (
        <>
          <Row {...rowConfig}>
            <Col {...sixColumns}>
              <Form.Item name="status">
                <Select placeholder="Selecciona l'estat" allowClear>
                  <Select.Option value="all">Tots</Select.Option>
                  <Select.Option value="active">Oberts</Select.Option>
                  <Select.Option value="closed">Tancats</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name="num_expedient">
                <Input type="text" placeholder="Nº expedient" />
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name="referencia_cadastral">
                <Input type="text" placeholder="Referència cadastral" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="start_between">
                <RangePicker className="w100" placeholder={["Data d'inici inicial","Data d'inici final"]} format={dateFormats.display} separator="-" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="end_between">
                <RangePicker className="w100" placeholder={["Data fi inicial","Data fi final"]} format={dateFormats.display} separator="-" />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <ComboCarrerPortal form={form} field_names={{ carrer: "carrer", portal: "portal" }} webservice={false} />
            <Col {...fourColumns}>
              <DistricteSelector field_name="districte" />
            </Col>
            <Col {...fourColumns}>
              <BarriSelector field_name="barri" />
            </Col>
          </Row>
          <Row {...rowConfig}>
          <Col {...fourColumns}>
              <Form.Item name="canal_entrada">
                <Select placeholder="Selecciona el canal d'entrada" loading={isFetchingCanals} filterOption={filterOptionByLabel} showSearch allowClear>
                  {canals.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="demandant">
              <Select placeholder="Selecciona el demandant" loading={isFetchingDemandants} filterOption={filterOptionByLabel} showSearch allowClear>
                  {demandants.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="motiu_tancament">
                <Select placeholder="Selecciona el motiu de tancament" loading={isFetchingTancaments} filterOption={filterOptionByLabel} showSearch allowClear>
                  {tancaments.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="professional">
                <Select placeholder="Selecciona un professional" loading={isFetchingUsers} filterOption={filterOptionByLabel} showSearch>
                  {users.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    },
    {
      label: "Registres",
      key: "2",
      children: (
        <>
          <Row {...rowConfig}>
            <Col {...fourColumns}>
              <Form.Item name="had_has_not_incidencia">
                <Select placeholder="Ha tingut incidència" loading={isFetchingIncidencies} filterOption={filterOptionByLabel} showSearch allowClear>
                  {incidencies.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="has_incidencia">
                <Select placeholder="Té incidència" loading={isFetchingIncidencies} filterOption={filterOptionByLabel} showSearch allowClear>
                  {incidencies.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="had_has_not_gestions">
                <Select placeholder="Ha tingut tipus amb" loading={isFetchingGestions} filterOption={filterOptionByLabel} showSearch allowClear>
                  {gestions.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="has_gestions">
                <Select placeholder="Té tipus amb" loading={isFetchingGestions} filterOption={filterOptionByLabel} showSearch allowClear>
                  {gestions.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...fourColumns}>
              <Form.Item name="has_coord_serveis">
                <Select placeholder="Coordinació amb servei" loading={isFetchingServeis} filterOption={filterOptionByLabel} showSearch allowClear>
                  {serveis.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="has_cons_serveis">
                <Select placeholder="Consulta amb servei" loading={isFetchingServeis} filterOption={filterOptionByLabel} showSearch allowClear>
                  {serveis.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      label: "Pisos / Locals",
      key: "3",
      children: <FlatsSearcher />
    }
  ]

  return (
    <Form form={form} initialValues={searchQuery} onFinish={onFinish}>
      <Tabs defaultActiveKey="1" items={tabs} animated={false} />
      <Row {...rowConfig} justify="center">
        <Form.Item style={{marginBottom: 0}}>
          <Button onClick={resetSearh} style={{marginRight: '8px'}}>Reiniciar</Button>
          <Button type="primary" htmlType="submit" loading={loading}>Buscar</Button>
        </Form.Item>
      </Row>
    </Form>
  )
};

export default ExpedientsSearcher;
