import axios, { AxiosRequestConfig } from 'axios'
import { cloneDeep } from 'lodash'
import * as pathToRegexp from 'path-to-regexp'
import { message } from 'antd'
import { CANCEL_REQUEST_MESSAGE } from 'utils/constants'
import qs from "qs";

export interface RequestResult {
  success: boolean;
  message?: string;
  statusCode?: number;
  data?: any;
}

export const axiosConfig : AxiosRequestConfig = {
  baseURL: "/api/",
  withCredentials: true,
  xsrfCookieName: "CSRF-TOKEN",
  xsrfHeaderName: "X-CSRF-Token",

  paramsSerializer: (params: any) => {
    return qs.stringify(params, { arrayFormat: 'brackets', encodeValuesOnly: true });
  }
};

window.cancelRequest = new Map()

// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   const { response } = error;

//   if (response && response.status === 401) {
//     history.push("/auth/login")
//   }

//   return Promise.reject(error);
// });

const { CancelToken } = axios

const client = axios.create(axiosConfig);

const request = async (url: string, options: AxiosRequestConfig = {}) : Promise<RequestResult> => {
  let { data, method = 'GET' } = options
  const cloneData = cloneDeep(data)

  try {
    let domain = ''
    const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/)

    if (urlMatch) {
      ;[domain] = urlMatch
      url = url.slice(domain.length)
    }

    const match = pathToRegexp.parse(url)
    url = pathToRegexp.compile(url)(data)

    for (const item of match) {
      if (item instanceof Object && item.name in cloneData) {
        delete cloneData[item.name]
      }
    }

    url = domain + url
  } catch (e) {
    message.error("request.ts: Error parsing the URL", e.message)
  }

  options.url = url
  options.method = method as AxiosRequestConfig["method"]

  if (method === "GET") {
    options.params = cloneData
  }

  options.cancelToken = new CancelToken(cancel => {
    window.cancelRequest.set(Symbol(Date.now()), {
      pathname: window.location.pathname,
      cancel,
    })
  })

  try {
    let response = await client(options)

    const { statusText, status, data } = response

    return{
      success: true,
      message: statusText,
      statusCode: status,
      data
    };
  }
  catch (error) {
    const { response: response_3, message: message_2 } = error
    if (String(message_2) === CANCEL_REQUEST_MESSAGE) {
      return {
        success: false,
      }
    }

    if (response_3 && response_3 instanceof Object) {
      const { data, statusText } = response_3

      return {
        success: false,
        message: data.message || data.error || statusText,
        statusCode: response_3.status,
        data
      }
    }
    else {
      return {
        success: false,
        message: error.message || 'Network Error',
        statusCode: 600,
        data: {}
      }
    }
  }
}

export default request;
