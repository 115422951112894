import React, { useMemo } from "react";
import { Button, Alert, Table } from "antd";
import { ColumnType } from "antd/lib/table";

import { useInnerTable } from "hooks/useInnerTable";
import { CommunityType, CommunityTypeRecord } from "pages/communities/model";
import { renderRecordActions } from "utils/helpers";
import { PlusOutlined } from "@ant-design/icons";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerForm";
import { ModalFormWithForwardRef, ModalFormChildProps } from "components/ModalForm";
import CreateCommunityForm from "pages/communities/create";


interface CommunitiesListProps {
  expedientId: string
}

const CommunitiesList: React.FC<CommunitiesListProps> = ({ expedientId }) => {
  const communitiesRequest = useMemo(() => `/communities?filters[expedients_id_eq]=${expedientId}`, [expedientId]);

  const goToCommunity = (id: string) => window.open(`/communities/${id}`, "_blank");

  const { tableProps, error, refresh, reload } = useInnerTable<CommunityType>(communitiesRequest);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<CommunityType> => {
    return {
      title: "Afegir comunitat",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: {
        ...CommunityTypeRecord,
        meta: {
          permissions: {
            can_edit: true,
            can_show: true
          }
        }
      }
    }
  }, [refresh])

  const endpoint = useMemo(() => `expedients/${expedientId}/add-community`, [expedientId])
  const { create, drawerProps } = useDrawerForm<CommunityType>(endpoint, formOptions);

  const columns: ColumnType<CommunityType>[] = [
    {
      title: 'Carrer',
      dataIndex: ["attributes", "address_fmt"],
      key: "street"
    },
    {
      title: "Codi postal",
      dataIndex: ["attributes", "codi_postal"],
      key: "codi_postal"
    },
    {
      title: "Barri",
      dataIndex: ["attributes", "nom_barri"],
      key: "barri"
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToCommunity)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form }: ModalFormChildProps) => (
          <CreateCommunityForm form={form} />
        )}
      </ModalFormWithForwardRef>
    </>
  );
};

export default CommunitiesList;
