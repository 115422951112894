import React from "react";
import {Button, Table, Alert, Form, Input, message, Select} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import {IExpedient} from "api/interfaces/Expedient";
import api from "api";

import {useStoreState} from "../../../utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {FormInstance} from "antd/lib/form";
import {useDrawerForm, useDrawerFormOptionsType} from "../../../hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDateRange, renderObservations} from "../../../utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "../../../components/DrawerFormApi";
import NewExpedientForm from "../forms/expedient";
import {useRouter} from "../../../hooks/useRouter";

interface ExpedientsListProps {
  communityId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    status: "all",
    num_expedient: undefined
  })
};

const ExpedientsList: React.FC<ExpedientsListProps> = ({ communityId }) => {
  const [form] = Form.useForm();
  const baseQuery = React.useMemo(() => ({ ccvv_id: communityId }), [communityId]);
  const router = useRouter();

  const { tableProps, error, refresh, reload, search } = useInnerTable<IExpedient>(api.expedients, { baseQuery, form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedient> => {
    return {
      title: "Nou expedient",
      eventBusPrefix: "createExpedient",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.expedients.newInstance()
    }
  }, [refresh]);

  const { create, drawerProps } = useDrawerForm<IExpedient>(api.expedients, formOptions);

  const goToExpedient = (id: string) => router.push(`/expedients/${id}`);

  const handleDestroy = async (id: string) => {
    const response = await api.expedients.destroy(id);

    if (response.isSuccess()) {
      refresh();
    } else {
      const error = response.fail().summary || "No s'ha pogut eliminar el registre"
      message.error(error, 10);
    }
  }

  const columns: ColumnType<IExpedient>[] = [
    {
      title: 'Nº expedient',
      key: "num_expedient",
      dataIndex: ["attributes", "num_expedient"]
    },
    {
      title: "Tipus",
      key: "tipus",
      dataIndex: ["attributes", "tipus"]
    },
    {
      title: "Dates",
      key: "start_date",
      render: renderRecordDateRange("data_inici", "data_fi")
    },
    {
      title: "Canal d'entrada",
      key: "canal_entrada",
      dataIndex: ["attributes", "canal_entrada_name"]
    },
    {
      title: "Motiu tancament",
      key: "motiu_tancament",
      dataIndex: ["attributes", "motiu_tancament_name"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToExpedient, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={{status: "all"}} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="status">
          <Select className="w100" placeholder="Estat">
            <Select.Option key="all" value="all">Tots</Select.Option>
            <Select.Option key="open" value="open">Oberts</Select.Option>
            <Select.Option key="closed" value="closed">Tancats</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="num_expedient" style={{marginRight: 0}}>
          <Input placeholder="Nº expedient" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit} loading={tableProps.loading as boolean}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["serveis:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly, eventBusPrefix }: DrawerFormChildProps) => (
          <NewExpedientForm form={form} readOnly={readOnly} eventBusPrefix={eventBusPrefix} communityId={communityId} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default ExpedientsList;
