import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IExpedientIncidenciaLocalResult, IExpedientIncidenciaLocalsResult, IExpedientIncidenciaLocal } from '../../interfaces/expedient/IncidenciaLocal'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";

export default class ExpedientIncidenciaLocals extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly expedient_id: string;
  private readonly incidencia_id: string;

  constructor(expedient_id: string, incidencia_id: string, host?: string) {
    super(host);
    this.expedient_id = expedient_id;
    this.incidencia_id = incidencia_id;
  }

  public async list(params: IQuery = {}): Promise<IExpedientIncidenciaLocalsResult> {
    return await this.request(GET, Routes.expedientIncidenciaLocalsPath(this.expedient_id, this.incidencia_id), {}, params) as IExpedientIncidenciaLocalsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientIncidenciaLocalResult> {
    return await this.request(GET, Routes.expedientIncidenciaLocalPath(this.expedient_id, this.incidencia_id, id), {}, params) as IExpedientIncidenciaLocalResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientIncidenciaLocalResult> {
    return await this.request(POST, Routes.expedientIncidenciaLocalsPath(this.expedient_id, this.incidencia_id), {}, params) as IExpedientIncidenciaLocalResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientIncidenciaLocalResult> {
    return await this.request(PATCH, Routes.expedientIncidenciaLocalPath(this.expedient_id, this.incidencia_id, id), {}, params) as IExpedientIncidenciaLocalResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientIncidenciaLocalPath(this.expedient_id, this.incidencia_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IExpedientIncidenciaLocal {
    return {
      id: null,
      type: "incidencia_local",
      attributes: {
        rol_incidencia_id: null,
        rol_incidencia_name: "",
        local_id: null,
        local_name: "",
        observacions: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
