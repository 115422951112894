import React from "react";

import { Button, Form, message, Modal, Spin } from "antd";
import request, {RequestResult} from "utils/request";
import { FormInstance, FormProps } from "antd/lib/form";
import { isFunction } from "lodash";
import { FieldData } from "rc-field-form/lib/interface";
import { BasicRecord } from "utils/models";

export interface ModalFormProps<T> {
  title: string,
  createUrl: string, // The url where create new elements
  handleCreated: (item: T | T[]) => void,
  handleUpdated: (item: T) => void,
  form: FormInstance
  initialValues: T,
  /**
   * React children or child render callback
  */
  children?:
    | ((props: any) => React.ReactNode)
    | React.ReactNode;
}

export interface ModalFormState<T> {
  multiple: boolean,
  visible: boolean,
  loading: boolean,
  record?: T
}

export interface ModalFormChildProps {
  form: FormInstance,
  record: BasicRecord
  readOnly: boolean,
}

class ModalForm<T extends BasicRecord, P extends ModalFormProps<T> = ModalFormProps<T>> extends React.Component<P, ModalFormState<T>> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Readonly<P>) {
    super(props);

    this.state = {
      multiple: false,
      visible: false,
      loading: false,
      record: props.initialValues
    };
  }

  handleCancel = () : void => {
    this.setState({ loading: false, visible: false });

    this.formRef.current?.resetFields();
  };

  onFinishFailed: FormProps["onFinishFailed"] = ({ values, errorFields, outOfDate }) => {
    // console.log(values, errorFields);
  }

  onFinish = async (formValues: {[key: string]: any}) : Promise<void> =>  {
    const { record } = this.state;
    const { createUrl } = this.props;

    this.setState({ loading: true });

    const method = record?.id ? "put" : "post";
    const endpoint = record?.id ? `${createUrl}/${record.id}` : createUrl;

    const response = await request(endpoint, {
      method,
      data: formValues
    })

    if (response.success)
      this.handleSubmitSuccess(response)
    else
      this.handleSubmitFailure(formValues, response);
  };

  handleSubmitSuccess = (response: RequestResult) : void => {
    const { record } = this.state;
    const { handleCreated, handleUpdated } = this.props;

    const { data: { data: item }} = response;

    if (record?.id)
      handleUpdated(item);
    else
      handleCreated(item);

    this.handleCancel();
    message.success("Registre guardat correctament");
  };

  handleSubmitFailure = (values: any, response: RequestResult) : void => {
    this.setState({ loading: false });

    if (response.statusCode === 422) {
      const { data, message: errorText } = response;

      const fields : FieldData[] = [];

      Object.keys(data.errors).forEach((field: string) => {
        fields.push({
          name: field,
          value: values[field],
          errors: data.errors[field]
        });
      });

      if (fields.length > 0) {
        this.formRef.current?.setFields(fields);
      } else if (errorText) {
        // try to show a general error
        message.error(errorText, 10);
      }
    } else {
      message.error("Hi hagut un error desconegut", 10);
    }
  };

  show = (values: T | null = null) : void => {
    if (values) {
      this.formRef.current?.setFieldsValue(values)
    } else {
      this.formRef.current?.resetFields();
    }

    this.setState({ loading: false, visible: true, record: (values ? {...values} : {...this.props.initialValues}) });
  };

  submitForm = () => {
    this.formRef.current?.submit();
  }

  render() : JSX.Element {
    const { title, children } = this.props;
    const { loading, record, visible } = this.state;
    const readOnly = !record?.meta?.permissions.can_edit;

    const footer = (
      // this.state.loading ? null :
      <div style={{textAlign: 'right' }}>
        <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>
          Cancel·lar
        </Button>
        { record?.meta?.permissions.can_edit &&
          <Button onClick={this.submitForm} type="primary" loading={loading}>
            {record?.id ? "Guardar" : "Crear"}
          </Button>
        }
      </div>
    )

    return (
      <Modal
        title={title}
        closable
        destroyOnClose
        onCancel={this.handleCancel}
        // onOk={this.handleOk}
        // confirmLoading={loading}
        open={visible}
        footer={footer}
      >
        <Spin spinning={loading}>
          <Form form={this.props.form} ref={this.formRef} initialValues={this.props.initialValues}  onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} layout="vertical">
            { children && isFunction(children)
              ? (children as (childProps: ModalFormChildProps) => React.ReactNode)({
                  form: this.props.form,
                  record,
                  readOnly
                })
              : null
            }
          </Form>
        </Spin>
      </Modal>
    );
  };
}

export default ModalForm;

type ModalFormWithForwardRefProps<T extends BasicRecord> = {
  // ref is not a valid prop name and treated
  // differently by React, so we use forwardedRef
  forwardedRef: React.Ref<ModalForm<T>>;
} & ModalFormProps<T>;

export const ModalFormWithForwardRef = <T extends BasicRecord>({
  forwardedRef,
  ...rest
}: ModalFormWithForwardRefProps<T>) => (
  <ModalForm {...rest} ref={forwardedRef} />
);
