import React from "react"
import { Form, Input, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import { filterOptionByLabel } from "utils/helpers";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import { axiosConfig } from "utils/request";

export interface PropietariFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}

const tipusPropietatsRequest = { ...axiosConfig, url: "tipus_propietat", params: { items: "all" } };

const PropietariForm: React.FC<PropietariFormProps> = ({ readOnly = false }) => {
  const { data: tipusPropietatsData, isFetching: isFetchingTipusPropietats } = useAxiosRequest<{ data: DicitonaryType[] }>(tipusPropietatsRequest);
  const { data: tipusPropietats = [] } = tipusPropietatsData || {};

  return (
    <>
      <Form.Item name={["attributes", "nom"]} label="Nom" rules={rules.required}>
        <Input disabled={readOnly} placeholder="Nom del propietari" />
      </Form.Item>
      <Form.Item name={["attributes", "tipus_propietat_id"]} label="Tipus propietat" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingTipusPropietats} filterOption={filterOptionByLabel} showSearch>
          {tipusPropietats.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
}

export default PropietariForm;
