import React, {ReactElement} from "react";

import { Form, Table, Alert, Button, Collapse } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useRouter } from "hooks/useRouter";
import { useTable } from "hooks/useTable";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerForm";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerForm";
import { FlatType, FlatTypeRecord } from "./model";
import FlatForm from "./form";
import {EditOutlined,EyeOutlined} from "@ant-design/icons";
import FlatsSearcher, { searchFormResetCallback } from "./searcher";

const renderRecordActions = (goToCommunity: Function = ():void => {}, handleEdit: Function = ():void => {}) => (text: string, record: FlatType) : ReactElement => {
  return (
    <span>
      { record.meta.permissions.can_show && <Button type="link" onClick={() : void => goToCommunity(record.attributes.ccvv_id)} icon={<EyeOutlined />} />}
      { record.meta.permissions.can_edit && <Button type="link" onClick={() : void => handleEdit(record.id)} icon={<EditOutlined />} />}
    </span>
  );
};

const FlatsList: React.FC = () => {

  const [form] = Form.useForm();
  const baseUrl = React.useMemo(() => "flats", []);

  const router = useRouter();

  const { tableProps, error, refresh, reload, search } = useTable<FlatType>(baseUrl, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const goToCommunity = (communityId: string) => router.push(`/communities/${communityId}`);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<FlatType> => {
    return {
      title: "Pis / Local",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: {
        ...FlatTypeRecord,
        meta: {
          permissions: {
            can_edit: true,
            can_show: true
          }
        }
      }
    }
  }, [refresh]);

  const { edit, drawerProps } = useDrawerForm<FlatType>(baseUrl, formOptions);

  const columns: ColumnType<FlatType>[] = [
    {
      title: 'Adreça',
      dataIndex: ["attributes", 'adr1'],
      key: "address"
    },
    {
      title: 'Tipus',
      dataIndex: ["attributes", "descripcio_us_local"],
      key: "type"
    },
    {
      title: 'Barri',
      dataIndex: ["attributes", 'nom_barri'],
      key: "nom_barri"
    },
    {
      title: 'Codi postal',
      dataIndex: ["attributes", 'codi_postal'],
      key: "codi_postal"
    },
    {
      title: 'Referència cadastral',
      dataIndex: ["attributes", "referencia_cadastral"],
      key: "referencia_cadastral"
    },
    {
      title: 'Quota',
      dataIndex: ["attributes", "quota_participacio"],
      key: "quota_participacio"
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToCommunity, edit)
    }
  ];

  const toolbar = (
    <Collapse style={{marginBottom: '16px'}} activeKey="1">
      <Collapse.Panel header="Buscador" key="1">
        <FlatsSearcher form={form} searchSubmit={searchSubmit} resetSearh={resetSearh} loading={tableProps.loading as boolean} />
      </Collapse.Panel>
    </Collapse>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <FlatForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default FlatsList;
