import React, { useCallback } from "react";

import {Form, Table, Alert, Button, Input, Collapse, Row, Col, Select, message} from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useQuery, useRouter } from "hooks/useRouter";
import {renderRecordActions} from "utils/helpers";
import { useTable } from "hooks/useTable";
import { FormInstance } from "antd/lib/form";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerForm";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { useStoreState } from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import { rowConfig, twoColumns, fourColumns } from "utils/constants";
import DistricteSelector from "components/WebService/DistricteSelector";
import BarriSelector from "components/WebService/BarriSelector";
import { CommunityType, CommunityTypeRecord } from "./model";
import CreateCommunityForm from "./create";
import { ModalFormChildProps, ModalFormWithForwardRef } from "components/ModalForm";
import ComboCarrerPortal from "components/WebService/ComboCarrerPortal";
import api from "../../api";

const { Panel } = Collapse;

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    barri_districte_id_eq: undefined,
    id_portal_eq: undefined,
    barri_id_eq: undefined,
    codi_postal_cont: undefined,
    codi_carrer_eq: undefined,
    referencia_cadastral_cont: undefined,
    ite_caducada_eq: undefined,
    ite_valida_fins_gteq: undefined,
  })
}

const renderCarrer = (item: CommunityType) => {
  if (!item.attributes.has_application_messages) {
    return item.attributes.address_fmt;
  }

  return (
    <>
      {item.attributes.address_fmt}
      {/* <Tag color="warning" style={{marginLeft: "10px"}}>errors</Tag> */}
    </>
  )
};

const ComunitatsList: React.FC = () => {
  // useWhyDidYouUpdate('ComunitatsList', props);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const baseUrl = React.useMemo(() => "communities", []);

  const { filters: query } = useQuery();
  const router = useRouter();

  const { tableProps, error, refresh, reload, search } = useTable<CommunityType>(baseUrl, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const goToCommunity = useCallback((id: string) => router.push(`/communities/${id}`), [router]);

  const handleDestroy = async (id: string) => {
    const response = await api.communities.destroy(id);

    if (response.isSuccess()) {
      refresh();
    } else {
      const error = response.fail().summary || "No s'ha pogut eliminar el registre"
      message.error(error, 10);
    }
  }

  const columns: ColumnType<CommunityType>[] = [
    {
      title: 'Carrer',
      render: renderCarrer,
      key: "street"
    },
    {
      title: "Codi postal",
      dataIndex: ["attributes", "codi_postal"],
      key: "codi_postal"
    },
    {
      title: "Barri",
      dataIndex: ["attributes", "nom_barri"],
      key: "barri"
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToCommunity, handleDestroy)
    }
  ];

  const toolbar = (
    <Collapse style={{marginBottom: '16px'}} activeKey="1">
      <Panel header="Buscador" key="1">
        <Form form={form} initialValues={query} onFinish={searchSubmit}>
          <Row {...rowConfig}>
            <ComboCarrerPortal form={form} field_names={{ carrer: "codi_carrer_eq", portal: "id_portal_eq" }} webservice={false} />
            <Col {...fourColumns}>
              <DistricteSelector field_name="barri_districte_id_eq" />
            </Col>
            <Col {...fourColumns}>
              <BarriSelector field_name="barri_id_eq" />
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name="referencia_cadastral_cont">
                <Input type="text" placeholder="Referència cadastral" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="codi_postal_cont">
                <Input type="text" placeholder="Codi postal" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="ite_caducada_eq">
                <Select placeholder="ITE caducada" allowClear>
                  <Select.Option key="1" value="1">Sí</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button onClick={resetSearh} style={{marginRight: '8px'}}>Reiniciar</Button>
              <Button type="primary" htmlType="submit" loading={tableProps.loading as boolean}>Buscar</Button>
            </Form.Item>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<CommunityType> => {
    return {
      title: "Nova comunitat",
      handleCreated: (item: CommunityType) => goToCommunity(item.id),
      handleUpdated: refresh,
      newRecord: {
        ...CommunityTypeRecord,
        meta: {
          permissions: {
            can_edit: true,
            can_show: true
          }
        }
      }
    }
  }, [refresh, goToCommunity])

  const { create, drawerProps } = useDrawerForm<CommunityType>(baseUrl, formOptions);

  const headerContent = (
    <div>
      <PermissibleRender userPermissions={userPermissions} requiredPermissions={["communities:create"]}>
        <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
      </PermissibleRender>
    </div>
  );

  return (
    <PageHeaderWrapper content={headerContent}>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form }: ModalFormChildProps) => (
          <CreateCommunityForm form={form} />
        )}
      </ModalFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default ComunitatsList;
