import React from "react"
import {Col, Form, Input, InputNumber, Row} from "antd"
import { FormInstance } from "antd/lib/form";
import {rowConfig, twoColumns} from "utils/constants";

export interface IntervencioFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

// const rules = {
//   data: [ { required: true, message: "Aquest camp és requerit" } ],
// };

const ParticipacioForm: React.FC<IntervencioFormProps> = ({ readOnly = false }) => {
  return (
    <>
      {/* <Form.Item name={["attributes", "data"]} label="Any registre" rules={rules.data}>
        <DatePicker className="w100" disabled format="YYYY" placeholder="Selecciona una data" />
      </Form.Item> */}
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <p>Dones nacionalitat espanyola</p>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "dones_esp_paies"]} label="Paia">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "dones_esp_gitanes"]} label="Gitana">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col {...twoColumns}>
          <p>Dones nacionalitat estrangera</p>
          <Row>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "dones_est_paies"]} label="Paia">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "dones_est_gitanes"]} label="Gitana">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <p>Homes nacionalitat espanyola</p>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "homes_esp_paios"]} label="Paio">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "homes_esp_gitanos"]} label="Gitano">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col {...twoColumns}>
          <p>Homes nacionalitat estrangera</p>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "homes_est_paios"]} label="Paio">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "homes_est_gitanos"]} label="Gitano">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <p>Binarines nac. espanyola</p>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "binari_esp_paies"]} label="Paia">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "binari_esp_gitanes"]} label="Gitana">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col {...twoColumns}>
          <p>Binari nac. estrangera</p>
          <Row>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "binari_est_paies"]} label="Paia">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
            <Col {...twoColumns}>
              <Form.Item name={["attributes", "binari_est_gitanes"]} label="Gitana">
                <InputNumber disabled={readOnly} min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
}

export default ParticipacioForm;
