import React, {useCallback} from "react";
import {Alert, Button, message, Table, Tabs} from "antd";
import {ColumnType} from "antd/lib/table/interface";
import {PlusOutlined} from "@ant-design/icons";
import {PermissibleRender} from "@brainhubeu/react-permissible";

import api from "api";
import {IExpedientLocal} from "api/interfaces/expedient/Local";
import {useInnerTable} from "hooks/useInnerTableApi";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderObservations} from "utils/helpers";
import {useStoreState} from "utils/store";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import LocalForm from "../forms/local";
import ContactesSection from "pages/shared/contacts/list";
import LocalsHistory from "pages/expedients/sections/locals_history";
import { Tab } from "rc-tabs/lib/interface";

interface LocalsListProps {
  expedientId: string
}

const baseQuery = { last_record: true }

const LocalsList: React.FC<LocalsListProps> = ({ expedientId}) => {
  const apiEndpoint = React.useMemo(() => api.expedientLocals(expedientId), [expedientId]);

  const expandedRowRender = useCallback((record: IExpedientLocal): React.ReactNode => {
    const tabs: Tab[] = [
      { key: "1", label: "Contactes",
        children: <ContactesSection type="Flat" id={record.attributes.local_id} context={`Expedient:${expedientId}`} hideToolbar style={{marginTop: "5px", marginBottom: 0}} /> },
      { key: "2", label: "Històric",
        children: <LocalsHistory expedientId={expedientId} localId={String(record.attributes.local_id)} /> }
    ];

    return (
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} />
    )
  },[expedientId]) ;

  const { tableProps, error, refresh, reload } = useInnerTable<IExpedientLocal>(apiEndpoint, { baseQuery });

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientLocal> => {
    return {
      title: "Expedient: pis/local",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IExpedientLocal>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<IExpedientLocal>[] = [
    {
      title: 'Pis',
      key: "flat",
      dataIndex: ["attributes", "local_name"]
    },
    {
      title: 'Règim',
      key: "regim",
      dataIndex: ["attributes", "regim_name"]
    },
    {
      title: 'Propietari',
      key: "propietari",
      dataIndex: ["attributes", "propietari_name"]
    },
    {
      title: 'Tipus propietat',
      key: "tipus_propietat",
      dataIndex: ["attributes", "tipus_propietat_name"]
    },
    {
      title: 'Participació òrgans',
      key: "participacio_organ",
      dataIndex: ["attributes", "participacio_organ_name"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
        <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
      </PermissibleRender>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} expandable={{ expandedRowRender }} />

      <DrawerFormWithForwardRef {...drawerProps} alwaysCreateNewRecord>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <LocalForm form={form} readOnly={readOnly} expedientId={expedientId} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default LocalsList;
