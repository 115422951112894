import React, { useEffect, useReducer } from "react";
import { DatePicker, Form, Row, Col, Input, Button, Select } from "antd";
import { rowConfig, oneColumn, sixColumns } from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import { useAxiosRequest } from "use-axios-request";
import NotFound from "components/NotFound";
import { PageLoading } from "@ant-design/pro-layout";
import LoadingError from "components/LoadingError";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import { dateFormats } from "utils/formats";
import { IGestioCcvv } from "api/interfaces/community/GestioCcvv";
import GestioList from "./gestio_list";
import api from "api";
import {axiosConfig} from "utils/request";
import {filterOptionByLabel} from "utils/helpers"

interface GestioFormProps {
  communitiyId: string
}

class InnerForm extends PageForm<IGestioCcvv> {}

interface IState {
  item?: IGestioCcvv
  isFetching: boolean
  error: string
  hasHistory: boolean
  showHistory: boolean
}

const initialState: IState = {
  item: null,
  isFetching: false,
  error: null,
  hasHistory: false,
  showHistory: false
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const optionsRequest = { ...axiosConfig, url: "av_d_si_no_nsnc_tramit", params: { items: "all" } };

const GestioForm: React.FC<GestioFormProps> = ({ communitiyId }) => {
  const [form] = Form.useForm();

  const handleCreated = () => void 0;

  const apiEndpoint = React.useMemo(() => api.gestioCcvvs(communitiyId), [communitiyId]);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await apiEndpoint.list({
        items: 1,
        filters: { order: "date" }
      });

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data[0] || apiEndpoint.newInstance(),
            isFetching: false,
            hasHistory: result.success().meta.pagy.count > 1,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            isFetching: false,
            hasHistory: false,
            error: result.fail().message
          }
        })
      }
    };

    if (communitiyId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true
        }
      });

      fetchData();
    }
  }, [apiEndpoint, communitiyId, dispatch]);

  const { data: options = { data: [] }, isFetching: isFetchingOptions } = useAxiosRequest<{ data: DicitonaryType[] }>(optionsRequest);

  if (!communitiyId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }

  const hideHistory = () => {
    dispatch({
      type: 'updateState',
      payload: {
        showHistory: false
      }
    })
  };

  const showHistory = () => {
    dispatch({
      type: 'updateState',
      payload: {
        showHistory: true
      }
    })
  };

  if (state.showHistory) {
    return (
      <GestioList communitiyId={communitiyId} hideHistory={hideHistory} />
    )
  }

  return (
    <InnerForm
      api={apiEndpoint}
      form={form}
      alwaysCreateNewRecord={true}
      initialValues={state.item}
      handleCreated={handleCreated}
      handleUpdated={handleCreated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "data"]} label="Data">
                <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
              </Form.Item>
            </Col>
            <Col xs={12} lg={20} style={{textAlign: "right"}}>
              <Button type="ghost" onClick={showHistory}>Historial</Button>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "junta"]} label="Junta">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "nif"]} label="NIF">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "reunions_extraordinares"]} label="Reunions extraordinàries">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "reunions_ordinaries"]} label="Reunions ordinàries">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "llibre_actes"]} label="Llibre d'actes">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "llibre_comptes"]} label="Llibre de comptes">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "compte_bancari"]} label="Compte bancari">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "gestoria"]} label="Gestoria">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "neteja"]} label="Neteja">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...sixColumns}>
              <Form.Item name={["attributes", "altres_serveis"]} label="Altres serveis">
                <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
                  {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "observacions"]} label="Observacions">
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
            </Row>
          </>
      )}
    </InnerForm>
  );
};

export default GestioForm;
