import React, {ReactElement} from "react";
import {Alert, Button, Popconfirm, Table} from "antd";
import {ColumnType} from "antd/lib/table/interface";
import {PlusOutlined} from "@ant-design/icons";
import {PermissibleRender} from "@brainhubeu/react-permissible";

import api from "api";
import {useInnerTable} from "hooks/useInnerTableApi";
import {useStoreState} from "utils/store";
import ContactLocalForm from "../forms/contact_local";
import {IExpedientLocal} from "api/interfaces/expedient/Local";
import {useRelationModal, useRelationModalOptionsType} from "hooks/useRelationModal";
import {IContact} from "api/interfaces/Contact";
import {ModalRelationChildProps, ModalRelationWithForwardRef} from "components/ModalRelationApi";
import {DeleteOutlined} from "@ant-design/icons/lib";


interface ContactsLocalsListProps {
  expedientId: string
  contactId: number|string
}

export const renderActions = (handleDeassociate: Function) => (text: string, record: IExpedientLocal, index: number) : ReactElement => {
  return (
    <span>
      {
        <Popconfirm placement="leftBottom" title="Eliminar associació?" onConfirm={() => handleDeassociate(record.attributes.local_id)} okText="Sí" cancelText="No">
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      }
    </span>
  );
};

const ContactsLocalsList: React.FC<ContactsLocalsListProps> = ({ expedientId, contactId }) => {
  const apiEndpoint = React.useMemo(() => api.expedientLocals(expedientId), [expedientId]);
  const baseQuery = React.useMemo(() => ({ contact_id: contactId, last_record: true }), [contactId]);

  const { tableProps, error, refresh, reload } = useInnerTable<IExpedientLocal>(apiEndpoint, { baseQuery });

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useRelationModalOptionsType<IContact> => {
    return {
      title: "Associar local/pis",
      source_ids: [contactId],
      associatable_type: "Flat",
      context_type: "Expedient",
      context_id: expedientId,
      handleAssociated: refresh,
      handleDeassociated: refresh,
    }
  }, [contactId, expedientId, refresh]);

  const { associate, deassociate, modalProps } = useRelationModal<IContact>(api.contacts, formOptions);

  const columns: ColumnType<IExpedientLocal>[] = [
    {
      title: 'Pis',
      key: "flat",
      dataIndex: ["attributes", "local_name"]
    },
    {
      title: 'Règim',
      key: "regim",
      dataIndex: ["attributes", "regim_name"]
    },
    {
      title: 'Propietari',
      key: "propietari",
      dataIndex: ["attributes", "propietari_name"]
    },
    {
      title: 'Tipus propietat',
      key: "tipus_propietat",
      dataIndex: ["attributes", "tipus_propietat_name"]
    },
    {
      title: 'Participació òrgans',
      key: "participacio_organ",
      dataIndex: ["attributes", "participacio_organ_name"]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderActions(deassociate)
    }
  ];

  const toolbar = (
    <div style={{ marginTop: "5px" }}>
      <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
        <Button icon={<PlusOutlined />} onClick={associate} size="small">Associar local/pis</Button>
      </PermissibleRender>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalRelationWithForwardRef {...modalProps}>
        {({ rowSelection }: ModalRelationChildProps<IContact>) => (
          <ContactLocalForm rowSelection={rowSelection} expedientId={expedientId} />
        )}
      </ModalRelationWithForwardRef>
    </>
  );
};

export default ContactsLocalsList;
