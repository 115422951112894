import React from "react"
import { Form, Input, Radio } from "antd"
import { FormInstance } from "antd/lib/form";

export interface DictionaryFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ]
}

const MotiuTancamentForm: React.FC<DictionaryFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "nom"]} label="Nom" rules={rules.required}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "requires_selecting_a_service"]} label="Requereix seleccionar servei" rules={rules.required}>
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default MotiuTancamentForm;
