import { BasicRecord } from "utils/models";

export interface FlatType extends BasicRecord {
  attributes: {
    ccvv_id: string;
    adr1: string;
    adr_baixa: string;
    codi_postal: string;
    descripcio_us_local: string;
    escala: string;
    nom_barri: string;
    nom_districte: string;
    observacions: string;
    pis: string;
    porta: string;
    quota_participacio: string;
    referencia_cadastral: string;
    display_name: string;
  }
}

export const FlatTypeRecord : FlatType = {
  id: null,
  type: "flats",

  attributes: {
    ccvv_id: "",
    adr1: "",
    adr_baixa: "",
    codi_postal: "",
    descripcio_us_local: "",
    escala: "",
    nom_barri: "",
    nom_districte: "",
    observacions: "",
    pis: "",
    porta: "",
    quota_participacio: "",
    referencia_cadastral: "",
    display_name: "",
  }
}
