import "./patches";

import React from 'react';
import { createRoot } from "react-dom/client";
import * as Sentry from '@sentry/browser';

import { Router } from "react-router-dom";
import { StoreProvider } from 'easy-peasy';
import { PageLoading } from '@ant-design/pro-layout';

import BusProvider from "react-gbus";

import history from "utils/history";
import store from "utils/store";

import App from './App';
import ScrollToTop from "./components/ScrollToTop";
import * as serviceWorker from './serviceWorker';

// Set moment locale
import { ConfigProvider } from 'antd';
import ca_ES from "antd/es/locale/ca_ES"
import moment from "moment"
import "moment/locale/ca";

moment.locale("ca");

Sentry.init({dsn: "https://5d6f7f057be049cca8eb1c98b5b88a19@o47297.ingest.sentry.io/5286311"});

const Root = () => {
  return (
    <StoreProvider store={store}>
      <BusProvider>
        <React.Suspense fallback={<PageLoading />}>
          <Router history={history}>
            <ConfigProvider locale={ca_ES}>
              <ScrollToTop />
              <App />
            </ConfigProvider>
          </Router>
        </React.Suspense>
      </BusProvider>
    </StoreProvider>
  );
};

const node = document.getElementById("root");

if (node) {
  const root = createRoot(node);
  root.render(<Root />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
