import React from "react"
import { Form, Input, InputNumber, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import {filterOptionByLabel} from "utils/helpers"

export interface DictionaryFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ]
}

const TipusRolContacteForm: React.FC<DictionaryFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "name"]} label="Nom" rules={rules.required}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "groups"]} label="Grups" rules={rules.required}>
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els grups" filterOption={filterOptionByLabel} showSearch>
          <Select.Option key="Expedients" value="Expedient">Expedients</Select.Option>
          <Select.Option key="Propietaris" value="Propietari">Propietaris</Select.Option>
          <Select.Option key="Serveis" value="Servei">Serveis</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "color"]} label="Color">
        <Input type="color" />
      </Form.Item>
      <Form.Item name={["attributes", "position"]} label="Posició">
        <InputNumber step={1} min={1} />
      </Form.Item>
    </>
  )
}

export default TipusRolContacteForm;
