import React from "react"
import {DatePicker, Form, Input} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "utils/formats";

export interface AdministracioFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  data_realitzacio: [ { required: true, message: "Aquest camp és requerit" } ]
};

const AdministracioForm: React.FC<AdministracioFormProps> = ({ readOnly = false }) => {

  return (
    <>
      <Form.Item name={["attributes", "data"]} label="Data" rules={rules.data_realitzacio}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>

      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default AdministracioForm;
