import React from "react";
import { DrawerFormChildProps } from "components/DrawerFormApi";
import { dateFormats } from "utils/formats";
import { Form, DatePicker, Input, Select } from "antd";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import {axiosConfig} from "utils/request";
import {filterOptionByLabel} from "utils/helpers"


const primerGrup = [
 { name: "comunitat_constituida", title: "Comunitat constituïda" },
 { name: "organs_representacio_interns", title: "Existència òrgans representació interns" },
 { name: "efectivitat_organs_interns", title: "Efectivitat òrgans representació interns" },
 { name: "organs_representacio_externs", title: "Existència òrgans representació externs" },
 { name: "efectivitat_organs_externs", title: "Efectivitat òrgans representació externs" },
 { name: "rotacio_carrecs", title: "Rotació de càrrecs" },
 { name: "reunions_ordinaries_anuals", title: "Realització de reunions ordinàries anuals" },
 { name: "asseguranca", title: "Assegurança" }
];

const segonGrup = [
  { name: "demanda_subvencio", title: "Demanda de subvenció" },
  { name: "fons_reserva", title: "Fons de reserva" },
  { name: "existencia_estatus", title: "Existència Estatuts" },
  { name: "normes_regim_intern", title: "Existència Normes règim intern" },
];

const tramsRequest = { ...axiosConfig, url: "av_d_avaluacio_trams", params: { items: "all" } };
const optionsRequest = { ...axiosConfig, url: "av_d_si_no_nsnc_tramit", params: { items: "all" } };

const AvOrganitzacioForm: React.FC<DrawerFormChildProps> = ({ form, readOnly }) => {
  const { data: trams = { data: [] }, isFetching: isFetchingTrams } = useAxiosRequest<{ data: DicitonaryType[] }>(tramsRequest);
  const { data: options = { data: [] }, isFetching: isFetchingOptions } = useAxiosRequest<{ data: DicitonaryType[] }>(optionsRequest);

  return (
    <>
      <Form.Item name={["attributes", "data"]} label="Data">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>

      {primerGrup.map((field) => (
        <Form.Item key={field.name} name={["attributes", field.name]} label={field.title}>
          <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
            {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
          </Select>
        </Form.Item>
      ))}

      <Form.Item key="ite" name="ite" label="ITE">
        <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
          <Select.Option key="en_tramit" value="En tràmit" disabled={readOnly}>En tràmit</Select.Option>
          {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item name={["attributes", "pagament_quotes"]} label="Pagament quotes">
        <Select placeholder="Selecciona un valor" loading={isFetchingTrams} filterOption={filterOptionByLabel} showSearch>
          {trams && trams.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "pagament_derrames"]} label="Pagament derrames">
        <Select placeholder="Selecciona un valor" loading={isFetchingTrams} filterOption={filterOptionByLabel} showSearch>
          {trams && trams.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>

      {segonGrup.map((field) => (
        <Form.Item key={field.name} name={["attributes", field.name]} label={field.title}>
          <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
            {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
          </Select>
        </Form.Item>
      ))}

      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default AvOrganitzacioForm;
