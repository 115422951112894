import React, {useCallback, useEffect, useMemo, useReducer} from "react";
import {Button, Skeleton, List, Typography, message} from "antd";
import LoadingError from "components/LoadingError";
import { DrawerFormWithForwardRef, DrawerFormChildProps } from "components/DrawerFormApi";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";

import {IQueryEditable, IQueryList} from "api/interfaces/Query";
import {JsonApiDocument, JsonApiDocumentGenerator} from "api/interfaces/JsonApi";
import {isFunction} from "lodash";

import {
  ClockCircleFilled,
  ClockCircleOutlined, ReadFilled,
  ReadOutlined,
} from "@ant-design/icons";

import { dateFormats } from "utils/formats";
import AvaluacioList from "./avaluacio_list";
import {ColumnType} from "antd/lib/table/interface";
import {BaseType} from "antd/lib/typography/Base";
import {renderRecordActions} from "../../../utils/helpers";

const { Text } = Typography;

export interface AvaluacioItemProps {
  title: string
  description: string
  apiEndpoint: IQueryList & IQueryEditable & JsonApiDocumentGenerator
  reloadScores: () => void
  formComponent: React.FC<DrawerFormChildProps>
  historyColumns: ColumnType<JsonApiDocument>[]
  /**
   * React children or child render callback
   */
  children?:
    | ((props: ChildProps) => React.ReactNode)
    | React.ReactNode;
}

export interface ChildProps {
  item: JsonApiDocument
  showHistory: boolean
}

interface IState {
  item?: JsonApiDocument
  historyCount: number
  isFetching: boolean
  error: string
  editing: boolean
  showHistory: boolean
  showHistoryCount: number
  initialLoad: boolean
}

const initialState: IState = {
  item: null,
  historyCount: null,
  isFetching: true,
  error: null,
  showHistory: false,
  editing: false,
  showHistoryCount: 0,
  initialLoad: true
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    case 'addNewItem':
      return {
        ...state,
        ...action.payload,
        historyCount: state.historyCount + 1
      };
    default:
      throw new Error();
  }
};

const IconText = ({ icon, text, textType = "secondary", onClick = null }) => {
  const node = (
    <span>
      {icon ? React.createElement(icon, { style: { marginRight: 8 } }) : null}
      <Text type={textType as BaseType}>{text}</Text>
    </span>
  );

  return onClick
    ? (<Button type="link" onClick={onClick} style={{padding: 0}}>{node}</Button>)
    : node;
};

const getDate = (item: JsonApiDocument) => {
  return item && item.attributes.data
  ? item.attributes.data.format(dateFormats.display)
  : "-"
};

const AvaluacioItem: React.FC<AvaluacioItemProps> = ({ apiEndpoint, reloadScores, children , ...props}) => {
  const store = useMemo(() => apiEndpoint, [apiEndpoint]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { historyColumns } = props;

  useEffect(() => {
    const fetchData = async () => {
      const result = await store.list({
        items: 1,
        filters: { order: "date" }
      });

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data[0],
            historyCount: result.success().meta.pagy.count,
            isFetching: false,
            initialLoad: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: initialState
        })
      }
    };

    dispatch({
      type: 'updateState',
      payload: {
        isFetching: true
      }
    });

    fetchData();

    if (!state.initialLoad) {
      reloadScores();
    }
  }, [store, dispatch, state.historyCount, state.initialLoad, reloadScores]);

  const hideHistory = () => {
    dispatch({
      type: 'updateState',
      payload: {
        showHistory: false
      }
    })
  };

  const showHistory = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: {
        showHistory: true,
        showHistoryCount: state.showHistoryCount + 1
      }
    })
  }, [dispatch, state.showHistoryCount]);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<JsonApiDocument> => {
    const handleCreated = (item) => {
      reloadScores();

      dispatch({
        type: 'addNewItem',
        payload: {
          item: item
        }
      });
    }

    const handleUpdated = () => {
      showHistory();
      reloadScores();
    };

    return {
      title: props.title,
      handleCreated: handleCreated,
      handleUpdated: handleUpdated,
      newRecord: store.newInstance()
    }
  }, [dispatch, props.title, store, showHistory, reloadScores]);

  const { create, edit, drawerProps } = useDrawerForm<JsonApiDocument>(apiEndpoint, formOptions);

  const handleDestroy = useCallback(async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess()) {
      showHistory();
      reloadScores();
    } else {
      message.error("No s'ha pogut eliminar el registre", 10);
    }
  }, [apiEndpoint, showHistory, reloadScores])

  const columns = useMemo(() => {
    return historyColumns.concat([
      {
        title: " ",
        key: "actions",
        align: "right",
        render: renderRecordActions(edit, handleDestroy)
      }
    ])
  }, [edit, handleDestroy, historyColumns])

  if (state.isFetching) { return <Skeleton avatar paragraph={{ rows: 3 }} /> }
  if (state.error) { return <LoadingError /> }

  return (
    <>
      <List.Item
        actions={!state.showHistory && [
          <IconText icon={state.historyCount ? ReadFilled : ReadOutlined} text={state.historyCount || "-"} textType={state.historyCount ? "" : "secondary"} key="list-vertical-history-count" />,
          <IconText icon={state.item ? ClockCircleFilled : ClockCircleOutlined} text={getDate(state.item)} textType={state.item ? "" : "secondary"} key="list-vertical-updated-at" />,
          <IconText icon={null} text="Veure historial" key="list-vertical-view-history" onClick={showHistory} />,
          state.item && <IconText icon={null} text="Veure últim registre" key="list-vertical-view-last" onClick={() => edit(state.item.id)} />,
          <IconText icon={null} text="Nou registre" key="list-vertical-view-last" onClick={create} />,
        ].filter(Boolean)}
        style={{marginBottom: "10px"}}
      >
        <List.Item.Meta
          // avatar={<Avatar icon={<CheckOutlined />} style={{ backgroundColor: '#87d068' }} />}
          title={props.title}
          description={props.description}
        />

        <DrawerFormWithForwardRef {...drawerProps}>
          {(childProps: DrawerFormChildProps) => (
            React.createElement(props.formComponent, childProps)
          )}
        </DrawerFormWithForwardRef>

        { state.showHistory && <AvaluacioList showCount={state.showHistoryCount} apiEndpoint={store} hideHistory={hideHistory} columns={columns}/> }

        { children && isFunction(children)
          ? children({
            item: state.item,
            showHistory: state.showHistory
          })
          : null
        }
      </List.Item>
    </>
  );
};

export default AvaluacioItem;
