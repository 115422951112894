import { BasicRecord } from "utils/models";

export interface GestioType extends BasicRecord {
  attributes: {
    altres_serveis: string;
    compte_bancari: string;
    data: Date;
    gestoria: string;
    junta: string;
    llibre_actes: string;
    llibre_comptes: string;
    neteja: string;
    nif: string;
    observacions: string;
    reunions_extraordinares: string;
    reunions_ordinaries: string;
  }
}


export interface CommunityType extends BasicRecord {
  attributes: {
    id_adreca: string;
    id_portal: string;
    codi_carrer: string;
    des_sigla: string;
    nom_carrer: string;
    nro: string;
    bis: string;
    tipus_nro: string;
    nom_districte?: string;
    ite_obligatoria: boolean;
    ite_valida_fins: string,
    codi_postal: string;
    x_etrs89: string;
    y_etrs89: string;
    longitud: string;
    latitud: string;
    referencia_cadastral: string;
    any_construccio: string;
    observacions: string;
    has_application_messages: boolean;
    vault_id: number

    address_fmt?: string;
    nom_barri? :string;
  }
}

export const CommunityTypeRecord : CommunityType = {
  id: null,
  type: "communities",

  attributes: {
    id_adreca: "",
    id_portal: "",
    codi_carrer: "",
    des_sigla: "",
    nom_carrer: "",
    nro: "",
    bis: "",
    ite_obligatoria: false,
    ite_valida_fins: "",
    tipus_nro: "",
    nom_districte: "",
    codi_postal: "",
    x_etrs89: "",
    y_etrs89: "",
    longitud: "",
    latitud: "",
    referencia_cadastral: "",
    any_construccio: "",
    observacions: "",
    vault_id: null,
    has_application_messages:false
  }
}
