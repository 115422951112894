import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IAvParticipacioResult, IAvParticipacionsResult, IAvParticipacio } from '../../interfaces/community/AvParticipacio'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class AvParticipacions extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly community_id: string

  protected dateFields: string[] = ["data"];

  constructor(community_id: string, host?: string) {
    super(host);
    this.community_id = community_id;
  }

  public async list(params: IQuery = {}): Promise<IAvParticipacionsResult> {
    return await this.request(GET, Routes.avParticipacionsPath(this.community_id), {}, params) as IAvParticipacionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IAvParticipacioResult> {
    return await this.request(GET, Routes.avParticipacioPath(this.community_id, id), {}, params) as IAvParticipacioResult
  }

  public async create(params: JsonApiDocument): Promise<IAvParticipacioResult> {
    return await this.request(POST, Routes.avParticipacionsPath(this.community_id), {}, params) as IAvParticipacioResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IAvParticipacioResult> {
    return await this.request(PATCH, Routes.avParticipacioPath(this.community_id, id), {}, params) as IAvParticipacioResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.avParticipacioPath(this.community_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IAvParticipacio {
    return {
      id: null,
      type: "users",
      attributes: {
        gestio_no_propietaris: "",
        desenvolupament_reunions: "",
        presa_decisions: "",
        tipologia_grups: "",
        participacio_reunions_junta: "",
        data: moment(),
        observacions: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
