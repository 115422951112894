import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IGestioCcvvResult, IGestioCcvvsResult, IGestioCcvv } from '../../interfaces/community/GestioCcvv'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class GestioCcvvs extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly community_id: string

  protected dateFields: string[] = ["data"];

  constructor(community_id: string, host?: string) {
    super(host);
    this.community_id = community_id;
  }

  public async list(params: IQuery = {}): Promise<IGestioCcvvsResult> {
    return await this.request(GET, Routes.gestionsCcvvPath(this.community_id), {}, params) as IGestioCcvvsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IGestioCcvvResult> {
    return await this.request(GET, Routes.gestioCcvvPath(this.community_id, id), {}, params) as IGestioCcvvResult
  }

  public async create(params: JsonApiDocument): Promise<IGestioCcvvResult> {
    return await this.request(POST, Routes.gestionsCcvvPath(this.community_id), {}, params) as IGestioCcvvResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IGestioCcvvResult> {
    return await this.request(PATCH, Routes.gestioCcvvPath(this.community_id, id), {}, params) as IGestioCcvvResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.gestioCcvvPath(this.community_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IGestioCcvv {
    return {
      id: null,
      type: "users",
      attributes: {
        altres_serveis: "",
        compte_bancar: "",
        data: moment(),
        gestoria: "",
        junta: "",
        llibre_actes: "",
        llibre_comptes: "",
        neteja: "",
        nif: "",
        observacions: "",
        reunions_extraordinares: "",
        reunions_ordinaries: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
