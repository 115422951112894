import { useRef, RefObject } from "react";
import ModalRelation, { ModalRelationProps } from "components/ModalRelationApi";
import { IQueryAssociatable } from "api/interfaces/Query";
import { JsonApiDocument } from "api/interfaces/JsonApi";
import { notification } from "antd";


export interface useRelationModalReturnType<RecordType extends JsonApiDocument> {
  associate: () => void
  deassociate: (id: string) => void
  modalProps: {
    forwardedRef: RefObject<ModalRelation<RecordType>>
  } & ModalRelationProps<RecordType>
}

export interface useRelationModalOptionsType<RecordType extends JsonApiDocument> {
  title: string,
  source_ids?: (string|number)[],
  associatable_ids?: (string|number)[],
  associatable_type?: string,
  context_id?: string | number,
  context_type?: string,
  handleAssociated?: ModalRelationProps<RecordType>["handleAssociated"]
  handleDeassociated?: () => void
}

const noop = () => {};

export const useRelationModal = <RecordType extends JsonApiDocument>(api: IQueryAssociatable, options: useRelationModalOptionsType<RecordType>) : useRelationModalReturnType<RecordType> => {
  const drawerRef = useRef<ModalRelation<RecordType>>(null);

  const {
    title, handleAssociated = noop, handleDeassociated = noop, associatable_ids, associatable_type = "", source_ids,
    context_id, context_type
  } = options;

  const associate = () => {
    drawerRef.current?.show()
  }

  const deassociate = async (id: string) => {
    const params = source_ids
      ? { source_ids, associatable_type, associatable_ids: [id], context_type, context_id }
      : { source_ids: [id], associatable_type, associatable_ids, context_type, context_id };

    const response = await api.deassociate(params);

    if (response.isSuccess()) {
      handleDeassociated();
    } else {
      notification.error({
        message: response.fail().name,
        description: response.fail().message
      });
    }
  }

  const result: useRelationModalReturnType<RecordType> = {
    associate,
    deassociate,
    modalProps: {
      api,
      source_ids,
      associatable_ids,
      associatable_type,
      context_id,
      context_type,
      title,
      handleAssociated,
      forwardedRef: drawerRef,
    }
  }

  return result;
}
