import React from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";

interface CreateServeiFormProps {
  form: FormInstance;
}

const CreateServeiForm: React.FC<CreateServeiFormProps> = ({ form }) => {
  return (
    <>
      <Form.Item name={["attributes", "nom"]} label="Nom">
        <Input type="text" />
      </Form.Item>
      <Form.Item name={["attributes", "is_administracio_finques"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};

export default CreateServeiForm;
