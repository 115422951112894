import React, { useMemo } from "react";
import { Col, Form, Select } from "antd";
import { trim } from "lodash";
import { useAxiosRequest } from "use-axios-request";
import {FormInstance} from "antd/lib/form";

import CarrerSelector, {CarrerSelectorProps} from "components/WebService/CarrerSelector";
import {GetPortalsxCodiCarrer, GetPortalsxCodiCarrerTypeResponse} from "utils/webService";
import { fourColumns } from "utils/constants";
import {filterOptionByLabel} from "../../utils/helpers";
import { axiosConfig } from "utils/request";


export interface ComboCarrerPortalProps {
  form: FormInstance;
  webservice: boolean;
  field_names: {
    carrer: string;
    portal: string;
  }
}

const ComboCarrerPortal: React.FC<ComboCarrerPortalProps> = ({ form, field_names, webservice }) => {
  const initialRequest = useMemo(() => {
    const codiCarrer = trim(form.getFieldValue(field_names.carrer));

    if (codiCarrer) {
      if (webservice) {
        return {...GetPortalsxCodiCarrer, params: { CodiCarrer: codiCarrer }};
      } else {
        return { ...axiosConfig, url: "portals", params: { filters: { codi_carrer_eq: codiCarrer }}};
      }
    } else {
      return null;
    }
  }, [form, field_names.carrer, webservice]);

  const { data: portals = [], update: updatePortals, isFetching } = useAxiosRequest<GetPortalsxCodiCarrerTypeResponse[]>(initialRequest);

  const carrerSelectorProps : CarrerSelectorProps = {
    form,
    webservice,
    field_name: field_names.carrer,
    itemProps: {
      // label: "Carrer"
    },
    inputProps: {
      allowClear: true,
      onChange: (value) => {
        form.resetFields([field_names.portal]);
        if (webservice) {
          updatePortals({ ...GetPortalsxCodiCarrer, params: { CodiCarrer: value } });
        } else {
          updatePortals({ ...axiosConfig, url: "portals", params: { filters: { codi_carrer_eq: value } } });
        }
      }
    }
  }

  const values = webservice ? portals : (portals as any).data;

  return (
    <>
      <Col {...fourColumns}>
        <CarrerSelector {...carrerSelectorProps} />
      </Col>
      <Col {...fourColumns}>
        <Form.Item name={field_names.portal}>
          <Select placeholder="Selecciona el portal" loading={isFetching} filterOption={filterOptionByLabel} showSearch allowClear>
            {webservice && values && values.map((item) => <Select.Option key={item.id_portal} value={String(item.id_portal)}>{`${item.tipus_nro} ${item.nro} ${item.bis}`}</Select.Option>)}
            {!webservice && values && values.map((item) => <Select.Option key={item.attributes.id_portal} value={String(item.attributes.id_portal)}>{`${item.attributes.tipus_nro} ${item.attributes.nro} ${item.attributes.bis}`}</Select.Option>)}

          </Select>
        </Form.Item>
      </Col>
    </>
  )
}

export default ComboCarrerPortal;
