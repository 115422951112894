import React from "react";
import {Alert, Button, Table} from "antd";
import {ColumnGroupType, ColumnType} from "antd/lib/table/interface";
import {useInnerTable} from "hooks/useInnerTableApi";
import api from "api";

import {renderRecordActions, renderObservations} from "utils/helpers";
import {IExpedientParticipacio} from "api/interfaces/expedient/Participacio";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import ParticipacioForm from "pages/expedients/forms/participacio";

interface ParticipacioHistoryProps {
  expedientId: string
  data: string,
  onUpdate: () => void
}

const ParticipacioHistory: React.FC<ParticipacioHistoryProps> = ({ expedientId, data, onUpdate }) => {
  const apiEndpoint = React.useMemo(() => api.expedientParticipacions(expedientId), [expedientId]);
  const baseQuery = React.useMemo(() => ({ data: data }), [data]);

  const { tableProps, error, refresh, reload } = useInnerTable<IExpedientParticipacio>(apiEndpoint, {baseQuery});

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientParticipacio> => {
    const reloadRecordAndSummary = () => { refresh(); onUpdate() }

    return {
      renderTitle: (item: IExpedientParticipacio) => item.attributes.grup_edat_nom,
      handleCreated: reloadRecordAndSummary,
      handleUpdated: reloadRecordAndSummary,
      newRecord: apiEndpoint.newInstance(),
    }
  }, [apiEndpoint, refresh, onUpdate]);

  const { edit, drawerProps } = useDrawerForm<IExpedientParticipacio>(apiEndpoint, formOptions);

  const columns: (ColumnType<IExpedientParticipacio> | ColumnGroupType<IExpedientParticipacio>)[] = [
    {
      title: "Grup d'edat",
      key: "group",
      dataIndex: ["attributes", "grup_edat_nom"]
    },
    {
      title: 'Dones nacionals',
      key: "dones_esp",
      children: [
        {
          title: "Paies",
          key: "dones_esp_paies",
          align: "center",
          dataIndex: ["attributes", "dones_esp_paies"]
        },
        {
          title: "Gitanes",
          key: "dones_esp_gitanes",
          align: "center",
          dataIndex: ["attributes", "dones_esp_gitanes"]
        }
      ]
    },
    {
      title: 'Dones estrangeres',
      key: "dones_est",
      children: [
        {
          title: "Paies",
          key: "dones_est_paies",
          align: "center",
          dataIndex: ["attributes", "dones_est_paies"]
        },
        {
          title: "Gitanes",
          key: "dones_est_gitanes",
          align: "center",
          dataIndex: ["attributes", "dones_est_gitanes"]
        }
      ]
    },
    {
      title: 'Homes nacionals',
      key: "dones_esp",
      children: [
        {
          title: "Paios",
          key: "homes_esp_paios",
          align: "center",
          dataIndex: ["attributes", "homes_esp_paios"]
        },
        {
          title: "Gitanos",
          key: "homes_esp_gitanos",
          align: "center",
          dataIndex: ["attributes", "homes_esp_gitanos"]
        }
      ]
    },
    {
      title: 'Homes estrangers',
      key: "dones_est",
      children: [
        {
          title: "Paios",
          key: "homes_est_paios",
          align: "center",
          dataIndex: ["attributes", "homes_est_paios"]
        },
        {
          title: "Gitanos",
          key: "homes_est_gitanos",
          align: "center",
          dataIndex: ["attributes", "homes_est_gitanos"]
        }
      ]
    },
    {
      title: 'Nº binari nacionals',
      key: "binari_esp",
      children: [
        {
          title: "Paies",
          key: "binari_esp_paies",
          align: "center",
          dataIndex: ["attributes", "binari_esp_paies"]
        },
        {
          title: "Gitanes",
          key: "binari_esp_gitanes",
          align: "center",
          dataIndex: ["attributes", "binari_esp_gitanes"]
        }
      ]
    },
    {
      title: 'Nº binari estrangeres',
      key: "binari_est",
      children: [
        {
          title: "Paies",
          key: "binari_est_paies",
          align: "center",
          dataIndex: ["attributes", "binari_est_paies"]
        },
        {
          title: "Gitanes",
          key: "binari_est_gitanes",
          align: "center",
          dataIndex: ["attributes", "binari_est_gitanes"]
        }
      ]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 0, display: 'flex', alignItems: "center", justifyContent: 'space-between' }} />
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <ParticipacioForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default ParticipacioHistory;
