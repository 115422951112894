import React from "react"
import { Form, Input, InputNumber } from "antd"
import { FormInstance } from "antd/lib/form";

export interface CadastreFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const CadastreForm: React.FC<CadastreFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "referencia_cadastral"]} label="Referència cadastral">
        <Input disabled={readOnly} placeholder="Referència cadastral" />
      </Form.Item>
      <Form.Item name={["attributes", "any_construccio"]} label="Any construcció">
        <InputNumber disabled={readOnly} min={0} step={1} placeholder="Any construcció" />
      </Form.Item>
      <Form.Item name={["attributes", "ite_valida_fins"]} label="ITE vàlida fins">
        <InputNumber disabled={readOnly} min={0} step={1} placeholder="Any (ex. 2035)" />
      </Form.Item>
    </>
  )
}

export default CadastreForm;
