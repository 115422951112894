import moment from "moment";

export const dateFormats = {
  display: "DD/MM/YYYY",
  displayYear: "YYYY",
  server: "YYYY-MM-DD"
};

export const timeFormats = {
  display: "HH:mm",
  server: "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
};

export const dateTimeFormats = {
  display: "DD/MM/YYYY HH:mm",
  server: "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
}

export const ISO8601 = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const dateFromServer = (date: Date) : moment.Moment | null => {
  return date ? moment(date, ISO8601) : null;
};

export const dateRangeFromServer = (range: String) : moment.Moment[] | null[] => {
  const dates = range.split(",");

  return dates.length === 2 ? [moment(dates[0], ISO8601), moment(dates[1], ISO8601)] : [null, null];
};

export const timeFromServer = (date: Date) : moment.Moment | null => {
  return date ? moment(date, ISO8601) : null;
};

export const dateTimeFromServer = (date: Date, time: Date) : moment.Moment | null => {
  if (date && time) {
    const momentDate = dateFromServer(date);
    const momentTime = timeFromServer(time);

    return momentDate!.hour(momentTime!.hour()).minute(momentTime!.minute());
  }

  return null;
};
