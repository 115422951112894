import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IAdministracioCcvvResult, IAdministracioCcvvsResult, IAdministracioCcvv } from '../interfaces/AdministracioCcvv'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";
import moment from 'moment'

export default class AdministracioCcvvs extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly community_id: string

  protected dateFields: string[] = ["data_inici", "data_fi"];

  public async list(params: IQuery = {}): Promise<IAdministracioCcvvsResult> {
    return await this.request(GET, Routes.administracionsCcvvPath(), {}, params) as IAdministracioCcvvsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IAdministracioCcvvResult> {
    return await this.request(GET, Routes.administracioCcvvPath(id), {}, params) as IAdministracioCcvvResult
  }

  public async create(params: JsonApiDocument): Promise<IAdministracioCcvvResult> {
    return await this.request(POST, Routes.administracionsCcvvPath(), {}, params) as IAdministracioCcvvResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IAdministracioCcvvResult> {
    return await this.request(PATCH, Routes.administracioCcvvPath(id), {}, params) as IAdministracioCcvvResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.administracioCcvvPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(attributes = {}) : IAdministracioCcvv {
    return {
      id: null,
      type: "administracio_ccvv",
      attributes: {
        data_inici: moment(),
        data_fi: null,
        observacions: "",
        ccvv_id: null,
        ccvv_name: "",
        expedient_id: null,
        expedient_num: "",
        servei_id: null,
        servei_name: "",
        ...attributes,
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
