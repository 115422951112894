import React from "react";
import { MenuDataItem } from "@ant-design/pro-layout/lib/typings";
import {
  BuildOutlined,
  CalendarOutlined,
  ContactsOutlined,
  FileOutlined,
  GlobalOutlined,
  HomeOutlined,
  StarOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';

const routes: MenuDataItem[] = [
  {
    key: "dashboard",
    path: "/dashboard",
    name: "Inici",
    icon: <CalendarOutlined />,
    authority: ["dashboard"]
  },
  {
    key: "comunitats",
    path: "/communities",
    name: "Comunitats",
    icon: <BuildOutlined />,
    authority: ["communities:index"]
  },
  {
    key: "pisos",
    path: "/flats",
    name: "Pisos",
    icon: <HomeOutlined />,
    authority: ["flats:index"]
  },
  {
    key: "expedients",
    path: "/expedients",
    name: "Expedients",
    icon: <FileOutlined />,
    authority: ["expedients:index"]
  },
  {
    key: "contacts",
    path: "/contacts",
    name: "Contactes",
    icon: <ContactsOutlined />,
    authority: ["contacts:index"]
  },
  {
    key: "propietaris",
    path: "/propietaris",
    name: "Propietaris",
    icon: <ContactsOutlined />,
    authority: ["propietaris:index"]
  },
  {
    key: "administracio_finques",
    path: "/administracio-finques",
    name: "Adm. de finques",
    icon: <GlobalOutlined />,
    authority: ["serveis:index"]
  },
  {
    key: "serveis",
    path: "/serveis",
    name: "Serveis",
    icon: <GlobalOutlined />,
    authority: ["serveis:index"]
  },
  {
    key: "indicators",
    path: "/indicators",
    name: "Indicadors",
    icon: <StarOutlined />,
    authority: ["indicators:index"]
  },
  {
    key: "users",
    path: "/users",
    name: "Usuaris",
    icon: <UserOutlined />,
    hideOnMobile: true,
    authority: ["users:manage"]
    // children: [
    //   {
    //     path: "/users/two",
    //     name: "Users Two",
    //     key: "users-two"
    //   },
    // ]
  },
  {
    key: "preferences",
    path: "/preferences",
    name: "Preferències",
    icon: <ToolOutlined />,
    hideOnMobile: true,
    authority: ["preferences"],
    children: [
      {
        name: "Diccionaris",
        path: "/preferences/dictionaries",
        key: "preferences-dictionaries"
      },
    ]
  }
];

export default routes;
