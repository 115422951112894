import React from "react";

import { Form, Table, Alert, Button, Collapse, message } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import {useRouter} from "hooks/useRouter";
import { useTable } from "hooks/useTableApi";

import {IExpedient} from "api/interfaces/Expedient";
import {renderRecordActions, renderRecordDateRange, renderObservations} from "utils/helpers";
import ExpedientsSearcher, { searchFormResetCallback } from "./searcher";
import api from "api";

const ExpedientsList: React.FC = () => {
  const [form] = Form.useForm();
  const router = useRouter();

  const { tableProps, error, refresh, reload, search } = useTable<IExpedient>(api.expedients, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const goToExpedient = (id: string) => router.push(`/expedients/${id}`);

  const handleDestroy = async (id: string) => {
    const response = await api.expedients.destroy(id);

    if (response.isSuccess()) {
      refresh();
    } else {
      const error = response.fail().summary || "No s'ha pogut eliminar el registre"
      message.error(error, 10);
    }
  }

  const columns: ColumnType<IExpedient>[] = [
    {
      title: 'Nº expedient',
      key: "num_expedient",
      dataIndex: ["attributes", "num_expedient"]
    },
    {
      title: "Tipus",
      key: "tipus",
      dataIndex: ["attributes", "tipus"]
    },
    {
      title: "Dates",
      key: "start_date",
      render: renderRecordDateRange("data_inici", "data_fi")
    },
    {
      title: "Adreça",
      key: "address",
      dataIndex: ["attributes", "addresses"]
    },
    {
      title: "Canal d'entrada",
      key: "canal_entrada",
      dataIndex: ["attributes", "canal_entrada_name"]
    },
    {
      title: "Motiu tancament",
      key: "motiu_tancament",
      dataIndex: ["attributes", "motiu_tancament_name"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToExpedient, handleDestroy)
    }
  ];

  const toolbar = (
    <Collapse style={{marginBottom: '16px'}} activeKey="1">
      <Collapse.Panel header="Buscador" key="1">
        <ExpedientsSearcher form={form} searchSubmit={searchSubmit} resetSearh={resetSearh} loading={tableProps.loading as boolean} />
      </Collapse.Panel>
    </Collapse>
  );


  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper >
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </PageHeaderWrapper>
  );
};

export default ExpedientsList;
