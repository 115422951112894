import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable, IQueryAssociatable } from '../interfaces/Query'
import { IContactResult, IContactsResult, IContact } from '../interfaces/Contact'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument, JsonApiRelationDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Contacts extends Http implements IQueryList, IQueryEditable, IQueryAssociatable {
  protected dateFields: string[] = ["valid_fins"];

  public async list(params: IQuery = {}): Promise<IContactsResult> {
    return await this.request(GET, Routes.contactsPath(), {}, params) as IContactsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IContactResult> {
    return await this.request(GET, Routes.contactPath(id), {}, params) as IContactResult
  }

  public async create(params: JsonApiDocument): Promise<IContactResult> {
    return await this.request(POST, Routes.contactsPath(), {}, params) as IContactResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IContactResult> {
    return await this.request(PATCH, Routes.contactPath(id), {}, params) as IContactResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.contactPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public async associate(params: JsonApiRelationDocument): Promise<IContactsResult> {
    return await this.request(POST, Routes.contactsPath() + '/associate', {}, params) as IContactsResult
  }

  public async deassociate(params: JsonApiRelationDocument): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(POST, Routes.contactsPath() + '/deassociate', {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IContact {
    return {
      id: null,
      type: "contacts",
      attributes: {
        nom: "",
        cognoms: "",
        adreca: "",
        telefon: "",
        email: "",
        principal: false,
        observacions: "",
        full_name: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
