import React from "react";

import { Form, message } from "antd";
import { FormInstance, FormProps } from "antd/lib/form";
import { isFunction } from "lodash";
import { FieldData } from "rc-field-form/lib/interface";
import { IQueryEditable } from "api/interfaces/Query";
import { JsonApiDocument, JsonApiSingleResponse } from "api/interfaces/JsonApi";
import { ResultResponse } from "api/interfaces/ResultResponse";
import { ExpandedApiError } from "api/errors";

export interface PageFormProps<T> {
  api: IQueryEditable
  handleCreated: (item: T | T[]) => void,
  handleUpdated: (item: T) => void,
  form: FormInstance
  initialValues: T,
  alwaysCreateNewRecord?: boolean,
  /**
   * React children or child render callback
  */
  children?:
    | ((props: any) => React.ReactNode)
    | React.ReactNode;
}

export interface PageFormState<T extends JsonApiDocument> {
  multiple: boolean,
  loading: boolean,
  record?: T
}

export interface PageFormChildProps {
  form: FormInstance,
  readOnly: boolean,
  loading: boolean,
}

class PageForm<T extends JsonApiDocument, P extends PageFormProps<T> = PageFormProps<T>> extends React.Component<P, PageFormState<T>> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Readonly<P>) {
    super(props);

    this.state = {
      multiple: false,
      loading: false,
      record: props.initialValues
    };
  }

  handleCancel = () : void => {
    this.setState({ loading: false });

    // this.formRef.current?.resetFields();
  };

  onFinishFailed: FormProps["onFinishFailed"] = ({ values, errorFields, outOfDate }) => {
    // console.log(values, errorFields);
  }

  onFinish = async (formValues: {[key: string]: any}) : Promise<void> =>  {
    const { api, alwaysCreateNewRecord } = this.props;
    const { record } = this.state;

    this.setState({ loading: true });

    const response = record.id && !alwaysCreateNewRecord
       ? await api.update(record.id, { ...record, ...formValues })
       : await api.create({ ...record, ...formValues })

    if (response.isSuccess())
      this.handleSubmitSuccess(response)
    else
      this.handleSubmitFailure(formValues, response);
  };

  handleSubmitSuccess = (response: ResultResponse<JsonApiSingleResponse>) : void => {
    const { record } = this.state;
    const { handleCreated, handleUpdated } = this.props;

    const { data: item } = response.success();

    const fields : FieldData[] = [];

    this.formRef.current?.getFieldsError().forEach(error => {
      fields.push({
        name: error.name,
        errors: []
      })
    });

    this.formRef.current?.setFields(fields)

    if (record?.id)
      handleUpdated(item as T);
    else
      handleCreated(item as T);

    this.handleCancel();

    message.success("Registre guardat correctament");
  };

  handleSubmitFailure = (values: any, response: ResultResponse<JsonApiSingleResponse>) : void => {
    this.setState({ loading: false });

    if (response.fail().name === "ExpandedApiError") {
      const { summary, errors } = response.fail() as ExpandedApiError;

      const fields : FieldData[] = [];

      Object.keys(errors).forEach((field: string) => {
        fields.push({
          name: ["attributes", field],
          value: values.attributes[field],
          errors: errors[field]
        });
      });

      if (fields.length > 0) {
        this.formRef.current?.setFields(fields);
      }

      if (summary) {
        message.error(summary, 15);
      }
    } else {
      message.error("Hi hagut un error desconegut", 10);
    }
  };

  show = (values: T | null = null) : void => {
    if (values) {
      this.formRef.current?.setFieldsValue(values)
    } else {
      this.formRef.current?.resetFields();
    }

    this.setState({ loading: false, record: (values ? {...values} : {...this.props.initialValues}) });
  };

  submitForm = () => {
    this.formRef.current?.submit();
  }

  render() : JSX.Element {
    const { children } = this.props;
    const { loading, record } = this.state;
    const readOnly = !record?.meta?.permissions.can_edit;

    return (
      <Form form={this.props.form} ref={this.formRef} initialValues={this.props.initialValues}  onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} layout="vertical">
        { children && isFunction(children)
          ? (children as ({form}: PageFormChildProps) => React.ReactNode)({
              form: this.props.form,
              readOnly,
              loading
            })
          : null
        }
      </Form>
    );
  };
}

export default PageForm;

type PageFormWithForwardRefProps<T extends JsonApiDocument> = {
  // ref is not a valid prop name and treated
  // differently by React, so we use forwardedRef
  forwardedRef: React.Ref<PageForm<T>>;
} & PageFormProps<T>;

export const PageFormWithForwardRef = <T extends JsonApiDocument>({
  forwardedRef,
  ...rest
}: PageFormWithForwardRefProps<T>) => (
  <PageForm {...rest} ref={forwardedRef} />
);
