import React, {CSSProperties, ReactElement, useMemo} from "react";
import {Button, Table, Alert, Form, Input, Popconfirm, Typography, notification, Select} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";
import {filterOptionByLabel, noopElement, renderRecordDate, stringToColour} from "utils/helpers";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {useStoreState} from "../../../utils/store";
import { PlusOutlined, DeleteOutlined, EyeOutlined, LinkOutlined, UserOutlined } from "@ant-design/icons";
import {FormInstance} from "antd/lib/form";
import { IContact } from "api/interfaces/Contact";
import { ModalFormWithForwardRef, ModalFormChildProps } from "components/ModalFormApi";
import { DrawerFormWithForwardRef, DrawerFormChildProps } from "components/DrawerFormApi";
import ContactForm from "pages/contacts/form";
import {TableProps} from "antd/lib/table";
import { IContacting } from "api/interfaces/Contacting";
import ContactingForm from "./contacting_form"
import CreateContactingForm from "./create_contacting_form";
import { axiosConfig } from "utils/request";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";


interface ContactesSectionProps {
  type: string,
  id: string | number,
  context?: string // type:id
  style?: CSSProperties
  expandable?: TableProps<any>["expandable"]
  hideToolbar?: boolean
}

const { Text } = Typography;

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    role: undefined,
    nom: undefined,
    cognoms: undefined,
    phone: undefined
  })
}

const renderActions = (handleEdit: Function, handleEditAssociation: Function, handleDeassociate: Function) => (text: string, record: IContacting, index: number) : ReactElement => {
  const can_only_show = record.attributes.contact.data.meta.permissions.can_show && !record.attributes.contact.data.meta.permissions.can_edit;

  return (
    <span>
      { can_only_show && <Button type="link" onClick={() : void => handleEdit(record.attributes.contact.data.id)} icon={<EyeOutlined />} />}
      { record.meta.permissions.can_edit && <Button type="link" onClick={() : void => handleEditAssociation(record.id)} icon={<LinkOutlined />} />}
      { record.attributes.contact.data.meta.permissions.can_edit && <Button type="link" onClick={() : void => handleEdit(record.attributes.contact.data.id)} icon={<UserOutlined />} />}
      {
        <Popconfirm placement="leftBottom" title="Eliminar associació?" onConfirm={() => handleDeassociate(record.attributes.contact.data.id)} okText="Sí" cancelText="No">
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      }
    </span>
  );
};

const renderNameAndRoles = (text: string, record: IContacting, index: number) : ReactElement => {
  return (
    <>
      { record.attributes.contact.data.attributes.full_name } <br />
      { record.attributes.role_names.map((role, index) => (
        <Text key={role} style={{color: record.attributes.role_colors[index]}}>
          { !!index && <span style={{color: "#000"}}>, </span> }
          { role }
        </Text>
      ))}
    </>
  )
}

const ContactesSection: React.FC<ContactesSectionProps> = ({ type, id, style, expandable, hideToolbar, context}) => {
  const [form] = Form.useForm();
  const baseQuery = React.useMemo(() => {
    return context ? { for: `${type}:${id}`, context: context } : { for: `${type}:${id}` };
  }, [type, id, context]);

  const rolesRequest = useMemo(() => ({ ...axiosConfig, url: "tipus_rol_contacte", params: { items: "all", filters: { group: type } }}), [type]);
  const { data: rolsData, isFetching: isFetchingRols } = useAxiosRequest<{ data: DicitonaryType[] }>(rolesRequest);
  const { data: rols = [] } = rolsData || {};

  const { tableProps, error, refresh, reload, search } = useInnerTable<IContacting>(api.contactings, { baseQuery, form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IContact> => {
    return {
      title: "Contacte",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.contacts.newInstance()
    }
  }, [refresh])

  const { edit, drawerProps } = useDrawerForm<IContact>(api.contacts, formOptions);

  const contactingFormOptions = React.useMemo(() : useDrawerFormOptionsType<IContacting> => {
    const attributes = {
      contactable_id: id,
      contactable_type: type
    };

    if (context) {
      const contextAttributes = context.split(":");
      attributes["context_type"] = contextAttributes[0];
      attributes["context_id"] = contextAttributes[1];
    }

    return {
      title: "Associació contacte",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.contactings.newInstance(attributes)
    }
  }, [refresh, context, id, type])

  const { create: createContacting, edit: editContacting, drawerProps: contactingDrawerProps } = useDrawerForm<IContacting>(api.contactings, contactingFormOptions);

  const disassociate = async (conactId: string) => {
    const params = { source_ids: [conactId], associatable_type: type, associatable_ids: [id] };

    if (context) {
      const contextAttributes = context.split(":");
      params["context_type"] = contextAttributes[0];
      params["context_id"] = contextAttributes[1];
    }

    const response = await api.contacts.deassociate(params);

    if (response.isSuccess()) {
      reload();
    } else {
      notification.error({
        message: response.fail().name,
        description: response.fail().message
      });
    }
  }

  const columns: ColumnType<IContacting>[] = [
    {
      title: 'Nom',
      render: renderNameAndRoles,
      key: "name"
    },
    {
      title: 'E-mail',
      key: "email",
      dataIndex: ["attributes", "contact", "data", "attributes", "email"]
    },
    {
      title: 'Telèfon',
      key: "telefon",
      dataIndex: ["attributes", "contact", "data", "attributes", "telefon"]
    },
    /*{
      title: 'Principal',
      key: "principal",
      render: renderBooleanColumn("principal")
    },*/
    {
      title: 'Vàlid fins',
      key: "valid_until",
      render: renderRecordDate("valid_fins")
    },
    // {
    //   title: 'Observacions',
    //   key: "observations",
    //   ellipsis: { showTitle: false },
    //   render: renderObservations
    // },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: hideToolbar ? noopElement : renderActions(edit, editContacting, disassociate)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between', ...style }}>
      <Form form={form} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="role">
          <Select placeholder="Seleccionar un rol" loading={isFetchingRols} filterOption={filterOptionByLabel} showSearch>
            {rols.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="nom">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item name="cognoms">
          <Input placeholder="Cognoms" />
        </Form.Item>
        <Form.Item name="phone" style={{marginRight: 0}}>
          <Input placeholder="Telèfon" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["contacts:create"]}>
          <Button icon={<PlusOutlined />} onClick={createContacting}>Associar</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <>
      { hideToolbar ? <span /> : toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} expandable={expandable} />

{/*      <ModalRelationWithForwardRef {...modalProps} modalProps={{width: "80%"}}>
        {({ rowSelection }: ModalRelationChildProps<IContact>) => (
          <LinkOrCreateContact associate={associate} rowSelection={rowSelection} rolesGroup={type} />
        )}
      </ModalRelationWithForwardRef>*/}

      <ModalFormWithForwardRef {...contactingDrawerProps}>
        {({ record, form }: ModalFormChildProps) => (
          record.id
            ? <ContactingForm form={form} rolesGroup={type} />
            : <CreateContactingForm form={form} rolesGroup={type} />
        )}
      </ModalFormWithForwardRef>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form }: DrawerFormChildProps) => (
          <ContactForm form={form} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default ContactesSection;
