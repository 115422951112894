import React from "react";
import {Alert, Button, Table} from "antd";
import {ColumnType} from "antd/lib/table/interface";

import api from "api";
import {IExpedientLocal} from "api/interfaces/expedient/Local";
import {useInnerTable} from "hooks/useInnerTableApi";
import {renderObservations, renderRecordDate} from "utils/helpers";


interface LocalsHistoryProps {
  expedientId: string
  localId: string
}

const LocalsHistory: React.FC<LocalsHistoryProps> = ({ expedientId, localId}) => {
  const apiEndpoint = React.useMemo(() => api.expedientLocals(expedientId), [expedientId]);
  const baseQuery = React.useMemo(() => ({ local_id: localId }), [localId]);

  const { tableProps, error, reload } = useInnerTable<IExpedientLocal>(apiEndpoint, { baseQuery });

  const columns: ColumnType<IExpedientLocal>[] = [
    {
      title: 'Data',
      key: "data",
      render: renderRecordDate("data")
    },
    {
      title: 'Règim',
      key: "regim",
      dataIndex: ["attributes", "regim_name"]
    },
    {
      title: 'Propietari',
      key: "propietari",
      dataIndex: ["attributes", "propietari_name"]
    },
    {
      title: 'Tipus propietat',
      key: "tipus_propietat",
      dataIndex: ["attributes", "tipus_propietat_name"]
    },
    {
      title: 'Participació òrgans',
      key: "participacio_organ",
      dataIndex: ["attributes", "participacio_organ_name"]
    },
    {
      title: "Observacions",
      key: "observacions",
      render: renderObservations
    }
  ];

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      <span />
      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </>
  );
};

export default LocalsHistory;
