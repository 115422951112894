import Users from './endpoints/Users'
import ApplicationMessages from './endpoints/ApplicationMessages'
import AvConvivencies from './endpoints/community/AvConvivencies'
import AvEstructures from './endpoints/community/AvEstructures'
import AvOrganitzacions from './endpoints/community/AvOrganitzacions'
import AvParticipacions from './endpoints/community/AvParticipacions'
import AdministracioCcvv from './endpoints/AdministracioCcvv'
import ObservacioCcvv from './endpoints/community/ObservacioCcvv'
import GestioCcvv from './endpoints/community/GestioCcvv'
import Community from "./endpoints/Communities";
import Expedient from "./endpoints/Expedients";
import Contact from "./endpoints/Contactes";
import Contacting from "./endpoints/Contactings";
import Propietari from "./endpoints/Propietari";
import Servei from "./endpoints/Servei";
import CalendarEvent from "./endpoints/calendar/Event";
import ExpedientConsultes from "./endpoints/expedient/Consulta";
import ExpedientCoordinacions from "./endpoints/expedient/Coordinacio";
import ExpedientGestions from "./endpoints/expedient/Gestio";
import ExpedientLocals from "./endpoints/expedient/Local";
import ExpedientIncidencies from "./endpoints/expedient/Incidencia";
import ExpedientIncidenciaLocals from "./endpoints/expedient/IncidenciaLocal";
import ExpedientIntervencions from "./endpoints/expedient/Intervencio";
import ExpedientParticipacions from "./endpoints/expedient/Participacio";

export interface IClientConfig {
  host?: string
}

class Client {
  private readonly host?: string

  public users: Users
  public applicationMessages: ApplicationMessages
  public avConvivencies: (community_id: string) => AvConvivencies
  public avEstructures: (community_id: string) => AvEstructures
  public avOrganitzacions: (community_id: string) => AvOrganitzacions
  public avParticipacions: (community_id: string) => AvParticipacions
  public observacioCcvvs: (community_id: string) => ObservacioCcvv
  public gestioCcvvs: (community_id: string) => GestioCcvv
  public communities: Community
  public expedients: Expedient
  public expedientConsultes: (expedient_id: string) => ExpedientConsultes
  public expedientCoordinacions: (expedient_id: string) => ExpedientCoordinacions
  public expedientLocals: (expedient_id: string) => ExpedientLocals
  public expedientIncidencies: (expedient_id: string) => ExpedientIncidencies
  public expedientIncidenciaLocals: (expedient_id: string, incidencia_id: string) => ExpedientIncidenciaLocals
  public expedientGestions: (expedient_id: string) => ExpedientGestions
  public expedientsIntervencions: (expedient_id: string) => ExpedientIntervencions
  public expedientParticipacions: (expedient_id: string) => ExpedientParticipacions
  public administracioCcvvs: AdministracioCcvv
  public contacts: Contact
  public contactings: Contacting
  public propietaris: Propietari
  public serveis: Servei
  public calendarTasks:CalendarEvent

  constructor(config: IClientConfig = {}) {
    this.host = config.host || null
    this.addEndpoints()
  }

  private addEndpoints() {
    this.users = new Users(this.host)
    this.applicationMessages = new ApplicationMessages(this.host)
    this.avConvivencies = (community_id: string) => new AvConvivencies(community_id, this.host)
    this.avEstructures = (community_id: string) => new AvEstructures(community_id, this.host)
    this.avOrganitzacions = (community_id: string) => new AvOrganitzacions(community_id, this.host)
    this.avParticipacions = (community_id: string) => new AvParticipacions(community_id, this.host)
    this.observacioCcvvs = (community_id: string) => new ObservacioCcvv(community_id, this.host)
    this.gestioCcvvs = (community_id: string) => new GestioCcvv(community_id, this.host)
    this.communities = new Community(this.host)
    this.expedients = new Expedient(this.host)
    this.expedientConsultes = (expedient_id: string) => new ExpedientConsultes(expedient_id, this.host)
    this.expedientCoordinacions = (expedient_id: string) => new ExpedientCoordinacions(expedient_id, this.host)
    this.expedientGestions = (expedient_id: string) => new ExpedientGestions(expedient_id, this.host)
    this.expedientLocals = (expedient_id: string) => new ExpedientLocals(expedient_id, this.host)
    this.expedientIncidencies = (expedient_id: string) => new ExpedientIncidencies(expedient_id, this.host)
    this.expedientIncidenciaLocals = (expedient_id: string, incidencia_id: string) => new ExpedientIncidenciaLocals(expedient_id, incidencia_id, this.host)
    this.expedientsIntervencions = (expedient_id: string) => new ExpedientIntervencions(expedient_id, this.host)
    this.expedientParticipacions = (expedient_id: string) => new ExpedientParticipacions(expedient_id, this.host)
    this.administracioCcvvs = new AdministracioCcvv(this.host)
    this.contacts = new Contact(this.host)
    this.contactings = new Contacting(this.host)
    this.propietaris = new Propietari(this.host)
    this.serveis = new Servei(this.host)
    this.calendarTasks = new CalendarEvent(this.host)
  }
}

export default Client
