import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IExpedientResult, IExpedientsResult, IExpedient } from '../interfaces/Expedient'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";
import moment from 'moment';

export default class Expedients extends Http implements IQueryList, IQueryEditable {
  protected dateFields: string[] = ["data_inici", "data_fi"];

  public async list(params: IQuery = {}): Promise<IExpedientsResult> {
    return await this.request(GET, Routes.expedientsPath(), {}, params) as IExpedientsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientResult> {
    return await this.request(GET, Routes.expedientPath(id), {}, params) as IExpedientResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientResult> {
    return await this.request(POST, Routes.expedientsPath(), {}, params) as IExpedientResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientResult> {
    return await this.request(PATCH, Routes.expedientPath(id), {}, params) as IExpedientResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IExpedient {
    return {
      id: null,
      type: "expedients",
      attributes: {
        tipus: "",
        addresses: "",
        data_inici: moment(),
        canal_entrada_id: null,
        data_fi: null,
        motiu_tancament_id: null,
        motiu_tancament_servei_id: null,
        observacions: "",
        demandant_id: null,
        referencia_canal_entrada: "",
        num_expedient: "",
        vault_id: null,
        professional_id: null
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
