import React, {useMemo} from "react";
import { get } from "lodash";
import {SelectProps, SelectValue} from "antd/lib/select";
import {Select} from "antd";
import {axiosConfig} from "../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "../pages/preferences/dictionaries/model";
import {filterOptionByLabel} from "../utils/helpers";

interface RemoteSelectProps<ValueType> extends SelectProps<ValueType>{
  path: string
  valuePath?: string;
  labelPath?: string;
  castValue?: boolean;
}

const RemoteDictionarySelect = <ValueType extends SelectValue = SelectValue>(props: RemoteSelectProps<ValueType>) => {
  const { path, valuePath = "id", labelPath = "attributes.nom", castValue = true, ...selectProps} = props;

  const request = useMemo(() => (
    {
      ...axiosConfig,
      url: path,
      params: { items: "all" }
    }
  ), [path]);

  const { data, isFetching } = useAxiosRequest<{ data: DicitonaryType[] }>(request);
  const { data: items = [] } = data || {};

  const getLabel = (item: DicitonaryType) => get(item, labelPath);
  const getValue = (item: DicitonaryType) => castValue ? parseInt(get(item, valuePath)) : get(item, valuePath);

  return (
    <Select loading={isFetching} placeholder="Selecciona un element" {...selectProps} filterOption={filterOptionByLabel} showSearch>
      {items.map((item) => <Select.Option key={getValue(item)} value={getValue(item)}>{getLabel(item)}</Select.Option>)}
    </Select>
  );
};

export default RemoteDictionarySelect;
