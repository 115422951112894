import React, {useMemo} from "react"
import {Form, Input, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "../../preferences/dictionaries/model";
import {FlatType} from "../../flats/model";
import {filterOptionByLabel} from "utils/helpers"

export interface IncidenciaLocalFormProps {
  expedientId: string;
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  local_id: [ { required: true, message: "Aquest camp és requerit" } ],
  rol_incidencia_id: [ { required: true, message: "Aquest camp és requerit" } ],
};

const rolsRequest = { ...axiosConfig, url: "rols_incidencies", params: { items: "all" } };

const IncidenciaLocalForm: React.FC<IncidenciaLocalFormProps> = ({ expedientId, readOnly = false }) => {
  const flatsRequest = useMemo(() => (
    {...axiosConfig, url: `/flats?filters[local_expedients_expedient_id_eq]=${expedientId}&items=all` }
  ), [expedientId]);

  const { data: flatsData, isFetching: isFetchingFlats } = useAxiosRequest<{ data: FlatType[] }>(flatsRequest);
  const { data: flats = [] } = flatsData || {};

  const { data: rolsData, isFetching: isFetchingRols } = useAxiosRequest<{ data: DicitonaryType[] }>(rolsRequest);
  const { data: rols = [] } = rolsData || {};

  return (
    <>
      <Form.Item name={["attributes", "local_id"]} label="Pis / Local" rules={rules.local_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingFlats} filterOption={filterOptionByLabel} showSearch>
          {flats.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.adr1}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "rol_incidencia_id"]} label="Rol a la incidència" rules={rules.rol_incidencia_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingRols} filterOption={filterOptionByLabel} showSearch>
          {rols.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default IncidenciaLocalForm;
