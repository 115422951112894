import React, {useEffect, useReducer} from "react";
import NotFound from "components/NotFound";
import ServeiForm from "./form";
import { PageHeaderWrapper, PageLoading } from "@ant-design/pro-layout";
import { useRouter } from "hooks/useRouter";
import LoadingError from "components/LoadingError";
import { Tabs } from "antd";
import api from "api";
import {IServei} from "api/interfaces/Servei";
import ContactesSection from "pages/shared/contacts/list";
import { Tab } from "rc-tabs/lib/interface";

interface IState {
  item?: IServei
  isFetching: boolean
  error: string
}

const initialState: IState = {
  item: null,
  isFetching: false,
  error: null
}

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const ServeiEditPage: React.FC = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { query: { serveiId } } = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.serveis.show(serveiId);

      if (result.isSuccess()) {
        dispatch({
          type: 'updateState',
          payload: {
            item: result.success().data,
            isFetching: false,
            error: null
          }
        })
      } else {
        dispatch({
          type: 'updateState',
          payload: {
            item: null,
            isFetching: false,
            error: result.fail().message
          }
        })
      }
    };

    dispatch({
      type: 'updateState',
      payload: {
        isFetching: true
      }
    })

    fetchData();
  }, [serveiId, dispatch])

  if (!serveiId) { return <NotFound /> }
  if (state.isFetching) { return <PageLoading /> }
  if (state.error) { return <LoadingError /> }

  const tabs: Tab[] = [
    { key: "1", label: "Contactes", children: <ContactesSection type="Servei" id={serveiId} /> }
  ]

  return (
    <PageHeaderWrapper title="Editar servei">
      <ServeiForm api={api.serveis} record={state.item}/>
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} animated={false} className="mb-20" />
    </PageHeaderWrapper>
  );
};

export default ServeiEditPage;
