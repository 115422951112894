import React from "react";
import { Form, Row, Col, Input, Button, Select } from "antd";
import {rowConfig, oneColumn, fourColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IPropietari} from "api/interfaces/Propietari";
import {IQueryEditable} from "../../api/interfaces/Query";
import { axiosConfig } from "utils/request";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import { filterOptionByLabel } from "utils/helpers";

interface PropietariFormProps {
  api: IQueryEditable,
  record: IPropietari
}

class InnerForm extends PageForm<IPropietari> {}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}

const tipusPropietatsRequest = { ...axiosConfig, url: "tipus_propietat", params: { items: "all" } };

const PropietariForm: React.FC<PropietariFormProps> = ({ api, record }) => {
  const [form] = Form.useForm();
  const { data: tipusPropietatsData, isFetching: isFetchingTipusPropietats } = useAxiosRequest<{ data: DicitonaryType[] }>(tipusPropietatsRequest);
  const { data: tipusPropietats = [] } = tipusPropietatsData || {};

  const handleCreated = () => void 0;

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleCreated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "nom"]} label="Nom" rules={rules.required}>
                <Input disabled={readOnly} placeholder="Nom del propietari" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "tipus_propietat_id"]} label="Tipus propietat" rules={rules.required}>
                <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingTipusPropietats} filterOption={filterOptionByLabel} showSearch>
                  {tipusPropietats.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "observacions"]} label="Observacions">
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{minRows: 3}} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default PropietariForm;
