import React, {useCallback, useMemo} from "react";
import {Card, Col, Row, Typography} from "antd";
import {AxiosError} from "axios";
import {PageLoading} from "@ant-design/pro-layout";
import {useAxiosRequest} from "use-axios-request";
import {useListener} from "react-gbus";

import {IAvScore} from "api/interfaces/community/AvScore";
import {Routes} from "api/routes";
import LoadingError from "components/LoadingError";
import NotFound from "components/NotFound";
import {rowConfig} from "utils/constants";
import {axiosConfig} from "utils/request";

const  { Title } = Typography;

interface AvaluacioScoreProps {
  communityId: string
}

const AvaluacioScore: React.FC<AvaluacioScoreProps> = ({ communityId }) => {
  const scoresRequest = useMemo(() => ({ ...axiosConfig, baseURL: "/", url: Routes.avScorePath(communityId)}), [communityId]);

  const { data: scores, error, isFetching, refresh } = useAxiosRequest<{ data: IAvScore }>(scoresRequest);

  const reloadScores = useCallback(() => {
    refresh();
  }, [refresh])

  useListener(reloadScores, [`@@community/${communityId}/reloadScores`]);

  if (!communityId) { return <NotFound /> }
  if (isFetching) { return <PageLoading /> }

  if (error) {
    const { isAxiosError, response } = error as AxiosError;

    if (isAxiosError && response && response.status === 404) {
      return <NotFound />
    }

    return <LoadingError />
  }

  if (!scores) {
    return <NotFound />
  }

  return (
    <div style={{borderBottom: "1px solid rgb(240,240,240)", paddingBottom: "10px"}}>
      <Row {...rowConfig} justify="space-around">
        <Col flex="auto">
          <Card title="Organització" headStyle={{textAlign: "center"}} bodyStyle={{textAlign: "center"}} size="small">
            <Title level={4} style={{color: scores.data.color_organitzacio}}>
              {scores.data.av_organitzacio || <span>&nbsp;&nbsp;</span>}
            </Title>
          </Card>
        </Col>
        <Col flex="auto">
          <Card title="Participació" headStyle={{textAlign: "center"}} bodyStyle={{textAlign: "center"}} size="small">
            <Title level={4} style={{color: scores.data.color_participacio}}>
              {scores.data.av_participacio || <span>&nbsp;&nbsp;</span>}
            </Title>
          </Card>
        </Col>
        <Col flex="auto">
          <Card title="Estructura" headStyle={{textAlign: "center"}} bodyStyle={{textAlign: "center"}} size="small">
            <Title level={4} style={{color: scores.data.color_estructura}}>
              {scores.data.av_estructura || <span>&nbsp;&nbsp;</span>}
            </Title>
          </Card>
        </Col>
        <Col flex="auto">
          <Card title="Convivència" headStyle={{textAlign: "center"}} bodyStyle={{textAlign: "center"}} size="small">
            <Title level={4} style={{color: scores.data.color_convivencia}}>
              {scores.data.av_convivencia || <span>&nbsp;&nbsp;</span>}
            </Title>
          </Card>
        </Col>
        <Col flex="auto">
          <Card title="Valoració" headStyle={{textAlign: "center"}} bodyStyle={{textAlign: "center"}} size="small">
            <Title level={4} style={{color: scores.data.color_valoracio}}>
              {scores.data.valoracio || <span>&nbsp;&nbsp;</span>}
            </Title>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AvaluacioScore;
