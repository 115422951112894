import React from "react"
import { Col, Form, Input, InputNumber, Row, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import { axiosConfig } from "utils/request";
import { UsLocalDictionaryType } from "pages/preferences/dictionaries/model";
import { useAxiosRequest } from "use-axios-request";
import { filterOptionByLabel } from "utils/helpers";
import { rowConfig, threeColumns } from "utils/constants";

export interface FlatFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const usosRequest = { ...axiosConfig, url: "tipus_us_local", params: { items: "all" } };

const FlatForm: React.FC<FlatFormProps> = ({ readOnly = false }) => {
  const { data: usos = { data: [] }, isFetching: isFetchingUsos } = useAxiosRequest<{ data: UsLocalDictionaryType[] }>(usosRequest);

  return (
    <>
      <Form.Item name={["attributes", "ccvv_id"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Row {...rowConfig}>
        <Col {...threeColumns}>
          <Form.Item name={["attributes", "escala"]} label="Escala">
            <Input disabled={readOnly} placeholder="Escala" />
          </Form.Item>
        </Col>
        <Col {...threeColumns}>
          <Form.Item name={["attributes", "pis"]} label="Pis">
            <Input disabled={readOnly} placeholder="Pis" />
          </Form.Item>
        </Col>
        <Col {...threeColumns}>
          <Form.Item name={["attributes", "porta"]} label="Porta">
            <Input disabled={readOnly} placeholder="Porta" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={["attributes", "descripcio_us_local"]} label="Descriptiu ús local">
        <Select placeholder="Selecciona els elements" loading={isFetchingUsos} filterOption={filterOptionByLabel} showSearch>
          {usos && usos.data.map((item) => <Select.Option key={item.attributes.nom} value={item.attributes.nom}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "adr1"]} label="Adreça 1">
        <Input disabled={readOnly} placeholder="Adreça 1" />
      </Form.Item>
      <Form.Item name={["attributes", "adr_baixa"]} label="Adreça baixa">
        <Input disabled={readOnly} placeholder="Adreça baixa" />
      </Form.Item>
      <Form.Item name={["attributes", "referencia_cadastral"]} label="Referència cadastral">
        <Input disabled={readOnly} placeholder="Referència cadastral" />
      </Form.Item>
      <Form.Item name={["attributes", "quota_participacio"]} label="Quota participació (%)">
        <InputNumber disabled={readOnly} min={0} max={100} step={0.1} placeholder="Quota participació (%)" />
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
}

export default FlatForm;
