import React, { useMemo } from "react"
import { Form, DatePicker, Select } from "antd"
import { FormInstance } from "antd/lib/form";
import { dateFormats } from "utils/formats";
import { filterOptionByLabel } from "utils/helpers";
import { useAxiosRequest } from "use-axios-request";
import { axiosConfig } from "utils/request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";

export interface ContactingFormProps {
  form: FormInstance;
  rolesGroup: string;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}


const ContactingForm: React.FC<ContactingFormProps> = ({ readOnly = false, rolesGroup }) => {
  const rolesRequest = useMemo(() => ({ ...axiosConfig, url: "tipus_rol_contacte", params: { items: "all", filters: { group: rolesGroup } }}), [rolesGroup]);
  const { data: rolsData, isFetching: isFetchingRols } = useAxiosRequest<{ data: DicitonaryType[] }>(rolesRequest);
  const { data: rols = [] } = rolsData || {};

  return (
    <>
      <Form.Item name={["attributes", "role_ids"]} label="Rols del contacte" rules={rules.required}>
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els rols" loading={isFetchingRols} filterOption={filterOptionByLabel} showSearch>
          {rols.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "valid_fins"]} label="Vàlid fins">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
    </>
  )
}

export default ContactingForm;
