import React, {useMemo} from "react"
import {Button, DatePicker, Form, Input, Select} from "antd"
import {PlusOutlined} from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "../../preferences/dictionaries/model";
import {FlatType} from "../../flats/model";
import {dateFormats} from "../../../utils/formats";
import {IPropietari} from "api/interfaces/Propietari";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import PropietariForm from "pages/propietaris/create";
import api from "api";
import {filterOptionByLabel} from "utils/helpers"

export interface LocalFormProps {
  expedientId: string;
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

const regimsRequest = { ...axiosConfig, url: "regims", params: { items: "all" } };
const propietatsRequest = { ...axiosConfig, url: "propietaris", params: { items: "all" } };
const participacioOrgansRequest = { ...axiosConfig, url: "participacio_organs", params: { items: "all" } };

const LocalForm: React.FC<LocalFormProps> = ({ form, expedientId, readOnly = false }) => {
  const flatsRequest = useMemo(() => (
    {...axiosConfig, url: `/flats?filters[community_expedients_id_eq]=${expedientId}&items=all`}
  ), [expedientId]);

  const { data: flatsData, isFetching: isFetchingFlats } = useAxiosRequest<{ data: FlatType[] }>(flatsRequest);
  const { data: flats = [] } = flatsData || {};

  const { data: regimsData, isFetching: isFetchingRegims } = useAxiosRequest<{ data: DicitonaryType[] }>(regimsRequest);
  const { data: regims = [] } = regimsData || {};

  const { data: propietatsData, isFetching: isFetchingPropietats, refresh: reloadPropietaris } = useAxiosRequest<{ data: DicitonaryType[] }>(propietatsRequest);
  const { data: propietats = [] } = propietatsData || {};

  const { data: participacioOrgansData, isFetching: isFetchingParticipacioOrgans } = useAxiosRequest<{ data: DicitonaryType[] }>(participacioOrgansRequest);
  const { data: participacioOrgans = [] } = participacioOrgansData || {};

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IPropietari> => {
    const addPropietari = (item: IPropietari) => {
      reloadPropietaris();
      form.setFieldsValue({attributes: {propietari_id: parseInt(item.id)}});
    };

    return {
      title: "Propietari",
      handleCreated: addPropietari,
      handleUpdated: addPropietari,
      newRecord: api.propietaris.newInstance()
    }
  }, [form, reloadPropietaris]);

  const { create, drawerProps } = useDrawerForm<IPropietari>(api.propietaris, formOptions);

  return (
    <>
      <Form.Item name={["attributes", "data"]} label="Data">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "local_id"]} label="Pis / Local" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingFlats} filterOption={filterOptionByLabel} showSearch>
          {flats.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.adr1}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "regim_id"]} label="Règim" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingRegims} filterOption={filterOptionByLabel} showSearch>
          {regims.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "propietari_id"]} label="Propietari" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingPropietats}
                filterOption={filterOptionByLabel} showSearch
                notFoundContent={<Button icon={<PlusOutlined />} onClick={create} type="link">Afegir propietari</Button>}
        >
          {propietats.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "participacio_id"]} label="Participació òrgans" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingParticipacioOrgans} filterOption={filterOptionByLabel} showSearch>
          {participacioOrgans.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <PropietariForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  )
};

export default LocalForm;
