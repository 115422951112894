import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IObservacioCcvvResult, IObservacioCcvvsResult, IObservacioCcvv } from '../../interfaces/community/ObservacioCcvv'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class ObservacioCcvvs extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly community_id: string

  protected dateFields: string[] = ["data"];

  constructor(community_id: string, host?: string) {
    super(host);
    this.community_id = community_id;
  }

  public async list(params: IQuery = {}): Promise<IObservacioCcvvsResult> {
    return await this.request(GET, Routes.observacionsCcvvPath(this.community_id), {}, params) as IObservacioCcvvsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IObservacioCcvvResult> {
    return await this.request(GET, Routes.observacioCcvvPath(this.community_id, id), {}, params) as IObservacioCcvvResult
  }

  public async create(params: JsonApiDocument): Promise<IObservacioCcvvResult> {
    return await this.request(POST, Routes.observacionsCcvvPath(this.community_id), {}, params) as IObservacioCcvvResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IObservacioCcvvResult> {
    return await this.request(PATCH, Routes.observacioCcvvPath(this.community_id, id), {}, params) as IObservacioCcvvResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.observacioCcvvPath(this.community_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IObservacioCcvv {
    return {
      id: null,
      type: "observacio_ccvv",
      attributes: {
        data: moment(),
        observacions: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
