import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IExpedientConsultaResult, IExpedientConsultesResult, IExpedientConsulta } from '../../interfaces/expedient/Consulta'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class ExpedientConsultes extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly expedient_id: string;

  protected dateFields: string[] = ["data_realitzacio"];

  constructor(expedient_id: string, host?: string) {
    super(host);
    this.expedient_id = expedient_id;
  }

  public async list(params: IQuery = {}): Promise<IExpedientConsultesResult> {
    return await this.request(GET, Routes.expedientConsultesPath(this.expedient_id), {}, params) as IExpedientConsultesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientConsultaResult> {
    return await this.request(GET, Routes.expedientConsultaPath(this.expedient_id, id), {}, params) as IExpedientConsultaResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientConsultaResult> {
    return await this.request(POST, Routes.expedientConsultesPath(this.expedient_id), {}, params) as IExpedientConsultaResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientConsultaResult> {
    return await this.request(PATCH, Routes.expedientConsultaPath(this.expedient_id, id), {}, params) as IExpedientConsultaResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientConsultaPath(this.expedient_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IExpedientConsulta {
    return {
      id: null,
      type: "consulta_expedient",
      attributes: {
        data_realitzacio: moment(),
        tipus_consulta_id: null,
        tipus_consulta_name: "",
        servei_id: null,
        servei_name: "",
        servei_contactes_ids: [],
        servei_contactes_names: "",
        observacions: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
