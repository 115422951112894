import React from "react";

import { Button, message, Modal, Spin } from "antd";
import { isFunction } from "lodash";
import { IQueryAssociatable } from "api/interfaces/Query";
import { JsonApiDocument, JsonApiListResponse } from "api/interfaces/JsonApi";
import { ExpandedApiError } from "api/errors";
import { ResultResponse } from "api/interfaces/ResultResponse";
import { ModalProps } from "antd/lib/modal";
import { get as getProperty } from "lodash";

export interface ModalRelationProps<T extends JsonApiDocument> {
  title: string,
  api: IQueryAssociatable,
  source_ids?: (string|number)[],
  associatable_ids: (string|number)[]
  associatable_type: string
  context_id?: string | number,
  context_type?: string,
  handleAssociated: (items: T[]) => void,
  modalProps?: ModalProps
  /**
   * React children or child render callback
  */
  children?:
    | ((props: any) => React.ReactNode)
    | React.ReactNode;
}

export interface ModalRelationState {
  visible: boolean,
  loading: boolean,
  selectedRowKeys: []
}

export interface ModalRelationChildProps<T> {
  rowSelection: {
    selectedRowKeys?: React.Key[];
    onChange: (value: React.Key[], option: T | T[]) => void;
  }
}

class ModalRelation<T extends JsonApiDocument, P extends ModalRelationProps<T> = ModalRelationProps<T>> extends React.Component<P, ModalRelationState> {
  constructor(props: Readonly<P>) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      selectedRowKeys: []
    };
  }

  rowSelection = {
    onChange: (selectedRowKeys, option: any) => {
      // console.log('selectedRowKeys changed: ', selectedRowKeys, "selectedRows:", selectedRows);
      this.setState({ selectedRowKeys });
    },
    // getCheckboxProps: record => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  handleCancel = () : void => {
    this.setState({ loading: false, visible: false, selectedRowKeys: [] });
  };


  handleOk = async () : Promise<void> =>  {
    const { api, source_ids, associatable_ids, associatable_type, context_type, context_id } = this.props;
    const { selectedRowKeys } = this.state;

    this.setState({ loading: true });

    const params = source_ids
    ? { source_ids, associatable_type, associatable_ids: selectedRowKeys, context_type, context_id }
    : { source_ids: selectedRowKeys, associatable_type, associatable_ids, context_type, context_id };

    const response = await api.associate(params);

    if (response.isSuccess())
      this.handleSubmitSuccess(response)
    else
      this.handleSubmitFailure(response);
  };

  handleSubmitSuccess = (response:  ResultResponse<JsonApiListResponse>) : void => {
    const { handleAssociated } = this.props;

    const { data: items } = response.success();

    handleAssociated(items as T[]);

    this.handleCancel();
    message.success("Registre/s associats correctament");
  };

  handleSubmitFailure = (response: ResultResponse<JsonApiListResponse>) : void => {
    this.setState({ loading: false });

    if (response.fail().name === "ExpandedApiError") {
      const { summary } = response.fail() as ExpandedApiError;

      if (summary) {
        message.error(summary, 10);
      } else {
        message.error("Hi hagut un error desconegut", 10);
      }
    } else {
      message.error("Hi hagut un error desconegut", 10);
    }
  };

  show = () : void => {
    this.setState({ loading: false, visible: true, selectedRowKeys: [] });
  };

  render() : JSX.Element {
    const { title, children } = this.props;
    const { loading, visible } = this.state;

    const footer = (
      // this.state.loading ? null :
      <div style={{textAlign: 'right' }}>
        <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>
          Cancel·lar
        </Button>
        <Button onClick={this.handleOk} type="primary" loading={loading}>
          {getProperty(this.props, "modalProps.okText", "Associar")}
        </Button>
      </div>
    )

    return (
      <Modal
        title={title}
        closable
        destroyOnClose
        onCancel={this.handleCancel}
        // onOk={this.handleOk}
        // confirmLoading={loading}
        open={visible}
        footer={footer}
        {...this.props.modalProps}
      >
        <Spin spinning={loading}>
          { children && isFunction(children)
            ? (children as (props: ModalRelationChildProps<T>) => React.ReactNode)({
                rowSelection: {...this.rowSelection, selectedRowKeys: this.state.selectedRowKeys}
              })
            : null
          }
        </Spin>
      </Modal>
    );
  };
}

export default ModalRelation;

type ModalRelationWithForwardRefProps<T extends JsonApiDocument> = {
  // ref is not a valid prop name and treated
  // differently by React, so we use forwardedRef
  forwardedRef: React.Ref<ModalRelation<T>>;
} & ModalRelationProps<T>;

export const ModalRelationWithForwardRef = <T extends JsonApiDocument>({
  forwardedRef,
  ...rest
}: ModalRelationWithForwardRefProps<T>) => (
  <ModalRelation {...rest} ref={forwardedRef} />
);
