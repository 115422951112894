import React from "react";
import { Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import CarrerSelector, { CarrerSelectorProps } from "components/WebService/CarrerSelector";
import { useAxiosRequest } from "use-axios-request";
import { GetPortalsxCodiCarrerTypeResponse, GetPortalsxCodiCarrer } from "utils/webService";
import {filterOptionByLabel} from "../../utils/helpers";
import { axiosConfig } from "utils/request";
import { UsLocalDictionaryType } from "pages/preferences/dictionaries/model";

interface CreateCommunityFormProps {
  form: FormInstance;
}

const usosRequest = { ...axiosConfig, url: "tipus_us_local", params: { items: "all" } };

const CreateCommunityForm: React.FC<CreateCommunityFormProps> = ({ form }) => {
  const { resetFields } = form;

  const { data: portals = [], update: updatePortals, isFetching } = useAxiosRequest<GetPortalsxCodiCarrerTypeResponse[]>();
  const { data: usos = { data: [] }, isFetching: isFetchingUsos } = useAxiosRequest<{ data: UsLocalDictionaryType[] }>(usosRequest);

  const carrerSelectorProps : CarrerSelectorProps = {
    form,
    webservice: true,
    field_name: "codi_carrer",
    itemProps: {
      label: "Carrer"
    },
    inputProps: {
      onChange: (value) => {
        resetFields(["portal"]);
        updatePortals({...GetPortalsxCodiCarrer, params: { CodiCarrer: value }});
      }
    }
  }

  return (
    <>

          <CarrerSelector  {...carrerSelectorProps} />

          <Form.Item name="id_portal" label="Portal">
            <Select placeholder="Selecciona el portal" loading={isFetching} filterOption={filterOptionByLabel} showSearch>
              {portals && portals.map((item) => <Select.Option key={item.id_portal} value={item.id_portal}>{`${item.tipus_nro} ${item.nro} ${item.bis}`}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item name="usos_locals" label="Usos a importar">
            <Select placeholder="Selecciona els elements" mode="multiple" loading={isFetchingUsos} filterOption={filterOptionByLabel} showSearch>
              {usos && usos.data.map((item) => <Select.Option key={item.attributes.codi} value={item.attributes.codi}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>

    </>
  );
};

export default CreateCommunityForm;
