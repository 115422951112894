import React, {useCallback, ReactElement} from "react";
import {Button, Table, Alert, Form, Select, Typography, message, DatePicker, Input, Row, Col} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { DownloadOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {FormInstance} from "antd/lib/form";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderRecordDateRange, filterOptionByLabel, renderObservations} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IExpedientIncidencia} from "api/interfaces/expedient/Incidencia";
import IncidenciaForm from "../forms/incidencia";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "pages/preferences/dictionaries/model";

import IncidenciesLocalsList from "./incidencies_locals";
import { dateFormats } from "utils/formats";
import { compact } from "lodash";
import { fourColumns, rowConfig, sixColumns, threeColumns, twoColumns } from "utils/constants";

interface IncidenciesListProps {
  expedientId: string
}

const { RangePicker } = DatePicker;
const { Text } = Typography;

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    tipus: undefined,
    status: "all",
    alias: undefined,
    start_between: [undefined, undefined],
    end_between: [undefined, undefined],
  })
};

const incidenciesRequest = { ...axiosConfig, url: "tipus_incidencies", params: { items: "all" } };

const rowExpandable = (record: IExpedientIncidencia) => !record.attributes.affects_community;

const renderDateAndMeta = (text: string, record: IExpedientIncidencia) : ReactElement => {
  return (
    <>
      { renderRecordDateRange("data_inici", "data_tancament")(text, record) } <br />
      <Text type="secondary">{ record.attributes.affects_community && "Afecta a la comunitat" }</Text>
    </>
  )
};

const renderService = (text: string, record: IExpedientIncidencia) : ReactElement => {
  return (
    <>
      { record.attributes.tipus_incidencia_name } <br />
      <Text type="secondary">{ record.attributes.alias }</Text>
    </>
  )
};

const IncidenciesList: React.FC<IncidenciesListProps> = ({ expedientId }) => {
  const [showFilters, setShowFilters] = React.useState(false);

  const [form] = Form.useForm();
  const apiEndpoint = React.useMemo(() => api.expedientIncidencies(expedientId), [expedientId]);

  const expandedRowRender = useCallback((record: IExpedientIncidencia): React.ReactNode => {
    return <IncidenciesLocalsList expedientId={expedientId} incidenciaId={record.id}/>;
  },[expedientId]) ;

  const { tableProps, error, refresh, reload, search } = useInnerTable<IExpedientIncidencia>(apiEndpoint, { form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearh, searchQuery } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const exportURL = React.useMemo(() => {
    return apiEndpoint.exportUrl("xlsx", { filters: searchQuery })
  }, [apiEndpoint, searchQuery]);

  const { data: incidenciesData, isFetching: isFetchingIncidencies } = useAxiosRequest<{ data: DicitonaryType[] }>(incidenciesRequest);
  const { data: incidencies = [] } = incidenciesData || {};

  const onFinish = (formValues: {[key: string]: any}) : void =>  {
    const startRange = compact<moment.Moment>(formValues["start_between"]);
    const endRange = compact<moment.Moment>(formValues["end_between"]);

    const start_between = startRange.length > 0
      ? [startRange[0].format('YYYY-MM-DD'), startRange[1].format('YYYY-MM-DD')].join(",")
      : undefined;

      const end_between = endRange.length > 0
      ? [endRange[0].format('YYYY-MM-DD'), endRange[1].format('YYYY-MM-DD')].join(",")
      : undefined;

      searchSubmit({ ...formValues, start_between, end_between });
  }

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientIncidencia> => {
    return {
      title: "Nova incidència",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IExpedientIncidencia>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<IExpedientIncidencia>[] = [
    {
      title: "Data",
      key: "date_range",
      render: renderDateAndMeta
    },
    {
      title: 'Tipus / Alias',
      key: "type",
      render: renderService
    },
    {
      title: 'Motiu tancament',
      key: "motiu_tancament",
      dataIndex: ["attributes", "motiu_tancament"]
    },
    {
      title: 'Serveis',
      key: "serveis",
      dataIndex: ["attributes", "serveis_noms"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between'}}>
      <div>
        <Button icon={<SearchOutlined />} onClick={() => setShowFilters(!showFilters)}>Filtres</Button>
      </div>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
        <Button icon={<DownloadOutlined />} href={exportURL} target="_blank" style={{marginLeft: '5px'}}>Descarregar</Button>
      </div>
    </div>
  );

  const searcher = (
    <Form form={form} initialValues={{status: "all"}} layout="vertical" onFinish={onFinish} style={{marginBottom: '16px'}}>
      <Row {...rowConfig}>
        <Col {...sixColumns}>
          <Form.Item name="status" label="Estat">
            <Select className="w100" placeholder="Selecciona un estat">
              <Select.Option key="all" value="all">Totes</Select.Option>
              <Select.Option key="open" value="open">Obertes</Select.Option>
              <Select.Option key="closed" value="closed">Tancades</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col {...fourColumns}>
          <Form.Item name="start_between" label="Data d'inici">
            <RangePicker className="w100" placeholder={["Des de","Fins"]} format={dateFormats.display} separator="-" />
          </Form.Item>
        </Col>
        <Col {...fourColumns}>
          <Form.Item name="end_between" label="Data fi">
            <RangePicker className="w100" placeholder={["Des de","Fins"]} format={dateFormats.display} separator="-" />
          </Form.Item>
        </Col>
        <Col {...threeColumns}>
          <Form.Item name="alias" label="Alias conté">
            <Input className="w100" />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name="tipus" label="Tipus d'incidència">
            <Select className="w100" mode="multiple" placeholder="Selecciona un element" loading={isFetchingIncidencies} filterOption={filterOptionByLabel} showSearch>
              {incidencies.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig} justify="center">
        <Form.Item style={{marginBottom: 0}}>
          <Button type="ghost" onClick={resetSearh}>Reiniciar</Button>
          <Button type="primary" loading={tableProps.loading} onClick={form.submit} style={{marginLeft: '5px'}}>Filtrar</Button>
        </Form.Item>
      </Row>
    </Form>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }
      { showFilters && searcher }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} expandable={{ expandedRowRender, rowExpandable }} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <IncidenciaForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default IncidenciesList;
