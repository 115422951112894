import { GET, DELETE, PATCH, POST } from 'api/constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from 'api/interfaces/Query'
import { ICalendarEventResult, ICalendarEventsResult, ICalendarEvent } from 'api/interfaces/calendar/Event'
import { Routes } from 'api/routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "api/interfaces/ResultResponse";
import moment from "moment";

export default class CalendarEvent extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  protected dateTimeFields: string[] = ["starts_at", "ends_at"];

  public async list(params: IQuery = {}): Promise<ICalendarEventsResult> {
    return await this.request(GET, Routes.calendarTasksPath(), {}, params) as ICalendarEventsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ICalendarEventResult> {
    return await this.request(GET, Routes.calendarTaskPath(id), {}, params) as ICalendarEventResult
  }

  public async create(params: JsonApiDocument): Promise<ICalendarEventResult> {
    return await this.request(POST, Routes.calendarTasksPath(), {}, params) as ICalendarEventResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ICalendarEventResult> {
    return await this.request(PATCH, Routes.calendarTaskPath(id), {}, params) as ICalendarEventResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.calendarTaskPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ICalendarEvent {
    return {
      id: null,
      type: "calendar_event",
      attributes: {
        name: "",
        category_id: null,
        starts_at: moment(),
        ends_at: moment(),
        start_time_zone: "Europe/Madrid",
        end_time_zone: "Europe/Madrid",
        user_id: null,
        all_day: false,
        event_color: "",
        category_name: "",
        recurrence: null,
        uid: "",
        recurrences: []
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
