import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IServeiResult, IServeisResult, IServei } from '../interfaces/Servei'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Serveis extends Http implements IQueryList, IQueryEditable {
  public async list(params: IQuery = {}): Promise<IServeisResult> {
    return await this.request(GET, Routes.serveisPath(), {}, params) as IServeisResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IServeiResult> {
    return await this.request(GET, Routes.serveiPath(id), {}, params) as IServeiResult
  }

  public async create(params: JsonApiDocument): Promise<IServeiResult> {
    return await this.request(POST, Routes.serveisPath(), {}, params) as IServeiResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IServeiResult> {
    return await this.request(PATCH, Routes.serveiPath(id), {}, params) as IServeiResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.serveiPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(attrs = {}) : IServei {
    return {
      id: null,
      type: "serveis",
      attributes: {
        nom: "",
        observacions: "",
        is_administracio_finques: false,
        ...attrs
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
