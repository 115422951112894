import React from "react";

import { Button, Form, message, Modal, Spin } from "antd";
import { FormInstance, FormProps } from "antd/lib/form";
import { isFunction } from "lodash";
import { FieldData } from "rc-field-form/lib/interface";
import { IQueryEditable } from "api/interfaces/Query";
import { JsonApiDocument, JsonApiSingleResponse } from "api/interfaces/JsonApi";
import { ExpandedApiError } from "api/errors";
import { ResultResponse } from "api/interfaces/ResultResponse";

export interface ModalFormProps<T extends JsonApiDocument> {
  title?: string,
  renderTitle?: (item: T) => React.ReactNode,
  api: IQueryEditable
  handleCreated: (item: T | T[]) => void,
  handleUpdated: (item: T) => void,
  form: FormInstance
  initialValues: T,
  /**
   * React children or child render callback
  */
  children?:
    | ((props: any) => React.ReactNode)
    | React.ReactNode;
}

export interface ModalFormState<T extends JsonApiDocument> {
  multiple: boolean,
  visible: boolean,
  loading: boolean,
  record?: T
}

export interface ModalFormChildProps {
  form: FormInstance,
  readOnly: boolean,
  record: JsonApiDocument,
}

class ModalForm<T extends JsonApiDocument, P extends ModalFormProps<T> = ModalFormProps<T>> extends React.Component<P, ModalFormState<T>> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Readonly<P>) {
    super(props);

    this.state = {
      multiple: false,
      visible: false,
      loading: false,
      record: props.initialValues
    };
  }

  handleCancel = () : void => {
    this.setState({ loading: false, visible: false });

    this.formRef.current?.resetFields();
  };

  onFinishFailed: FormProps["onFinishFailed"] = ({ values, errorFields, outOfDate }) => {
    // console.log(values, errorFields);
  }

  onFinish = async (formValues: {[key: string]: any}) : Promise<void> =>  {
    const { api } = this.props;
    const { record } = this.state;

    this.setState({ loading: true });

    const response = record.id
      ? await api.update(record.id, { ...record, ...formValues })
      : await api.create({ ...record, ...formValues })

    if (response.isSuccess())
      this.handleSubmitSuccess(response)
    else
      this.handleSubmitFailure(formValues, response);
  };

  handleSubmitSuccess = (response:  ResultResponse<JsonApiSingleResponse>) : void => {
    const { record } = this.state;
    const { handleCreated, handleUpdated } = this.props;

    const { data: item } = response.success();

    if (record?.id)
      handleUpdated(item as T);
    else
      handleCreated(item as T);

    this.handleCancel();
    message.success("Registre guardat correctament");
  };

  handleSubmitFailure = (values: any, response: ResultResponse<JsonApiSingleResponse>) : void => {
    this.setState({ loading: false });

    if (response.fail().name === "ExpandedApiError") {
      const { summary, errors } = response.fail() as ExpandedApiError;

      const fields : FieldData[] = [];

      Object.keys(errors).forEach((field: string) => {
        fields.push({
          name: ["attributes", field],
          value: values.attributes ? values.attributes[field] : "",
          errors: errors[field]
        });
      });

      if (fields.length > 0) {
        this.formRef.current?.setFields(fields);
      } else if (summary) {
        // try to show a general error
        message.error(summary, 10);
      }
    } else {
      message.error("Hi hagut un error desconegut", 10);
    }
  };

  show = (values: T | null = null) : void => {
    if (values) {
      this.formRef.current?.setFieldsValue(values)
    } else {
      this.formRef.current?.resetFields();
    }

    this.setState({ loading: false, visible: true, record: (values ? {...values} : {...this.props.initialValues}) });
  };

  submitForm = () => {
    this.formRef.current?.submit();
  }

  render() : JSX.Element {
    const { title, children, renderTitle } = this.props;
    const { loading, record, visible } = this.state;
    const readOnly = !record?.meta?.permissions.can_edit;

    const footer = (
      // this.state.loading ? null :
      <div style={{textAlign: 'right' }}>
        <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>
          Cancel·lar
        </Button>
        { record?.meta?.permissions.can_edit &&
          <Button onClick={this.submitForm} type="primary" loading={loading}>
            {record?.id ? "Guardar" : "Crear"}
          </Button>
        }
      </div>
    )

    return (
      <Modal
      title={renderTitle ? renderTitle(record) : title}
        closable
        destroyOnClose
        onCancel={this.handleCancel}
        // onOk={this.handleOk}
        // confirmLoading={loading}
        open={visible}
        footer={footer}
      >
        <Spin spinning={loading}>
          <Form form={this.props.form} ref={this.formRef} initialValues={this.props.initialValues}  onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} layout="vertical">
            { children && isFunction(children)
              ? (children as (childProps: ModalFormChildProps) => React.ReactNode)({
                  form: this.props.form,
                  readOnly,
                  record,
                })
              : null
            }
          </Form>
        </Spin>
      </Modal>
    );
  };
}

export default ModalForm;

type ModalFormWithForwardRefProps<T extends JsonApiDocument> = {
  // ref is not a valid prop name and treated
  // differently by React, so we use forwardedRef
  forwardedRef: React.Ref<ModalForm<T>>;
} & ModalFormProps<T>;

export const ModalFormWithForwardRef = <T extends JsonApiDocument>({
  forwardedRef,
  ...rest
}: ModalFormWithForwardRefProps<T>) => (
  <ModalForm {...rest} ref={forwardedRef} />
);
