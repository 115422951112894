import React, { useMemo } from "react"
import {DatePicker, Form, Input, Select, Typography} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "utils/formats";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "../../preferences/dictionaries/model";
import {filterOptionByLabel} from "utils/helpers"
import { IExpedientIncidencia } from "api/interfaces/expedient/Incidencia";
import { Routes } from "api/routes";
// import {IPropietari} from "api/interfaces/Propietari";
import {IServei} from "api/interfaces/Servei";
import {IContact} from "api/interfaces/Contact";
import { FlatType } from "pages/flats/model";

export interface IntervencioFormProps {
  form: FormInstance;
  expedientId: string;
  readOnly?: boolean;
}

const { Text } = Typography;

const rules = {
  data_realitzacio: [ { required: true, message: "Aquest camp és requerit" } ],
  tipus_intervencio_id: [ { required: true, message: "Aquest camp és requerit" } ],
};

const intervencionsRequest = { ...axiosConfig, url: "tipus_intervencions", params: { items: "all" } };
const serveisRequest = { ...axiosConfig, url: "serveis", params: { items: "all" } };
// const propietarisRequest = { ...axiosConfig, url: "propietaris", params: { items: "all" } };

const IntervencioForm: React.FC<IntervencioFormProps> = ({ expedientId, readOnly = false }) => {
  const flatsRequest = useMemo(() => (
    {...axiosConfig, url: `/flats?filters[local_expedients_expedient_id_eq]=${expedientId}&items=all` }
  ), [expedientId]);

  const { data: flatsData, isFetching: isFetchingFlats } = useAxiosRequest<{ data: FlatType[] }>(flatsRequest);
  const { data: flats = [] } = flatsData || {};

  const { data: intervencionsData, isFetching: isFetchingIntervencions } = useAxiosRequest<{ data: DicitonaryType[] }>(intervencionsRequest);
  const { data: intervencions = [] } = intervencionsData || {};

  const incidencesRequest = useMemo(() => ({ ...axiosConfig, baseURL: "/", url: Routes.expedientIncidenciesPath(String(expedientId)), params: { items: "all", filters: { "order": "status" }}}), [expedientId]);
  const { data: incidenciesData, isFetching: isFetchingIncidencies } = useAxiosRequest<{ data: IExpedientIncidencia[] }>(incidencesRequest);
  const { data: incidencies = [] } = incidenciesData || {};

  const { data: serveisData, isFetching: isFetchingServeis } = useAxiosRequest<{ data: IServei[] }>(serveisRequest);
  const { data: serveis = [] } = serveisData || {};

  // const { data: propietarisData, isFetching: isFetchingPropietaris } = useAxiosRequest<{ data: IPropietari[] }>(propietarisRequest);
  // const { data: propietaris = [] } = propietarisData || {};

  const contactesRequest = useMemo(() => ({
    ...axiosConfig,
    baseURL: "/", url: Routes.contactsPath(),
    params: { filters: { for: `Expedient:${expedientId}` }, items: "all" }}
  ), [expedientId]);

  const { data: contactesData, isFetching: isFetchingContactes } = useAxiosRequest<{ data: IContact[] }>(contactesRequest);
  const { data: contactes = [] } = contactesData || {};

  return (
    <>
      <Form.Item name={["attributes", "data_realitzacio"]} label="Data" rules={rules.data_realitzacio}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "tipus_intervencio_id"]} label="Tipus d'intervenció" rules={rules.tipus_intervencio_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingIntervencions} filterOption={filterOptionByLabel} showSearch>
          {intervencions.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "incidence_ids"]} label="Incidències relacionades">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona les incidències"  loading={isFetchingIncidencies} filterOption={filterOptionByLabel} showSearch className="withSublabel">
          {incidencies.map((item) => (
            <Select.Option key={item.id} value={parseInt(item.id)}>
              <Text disabled={!!item.attributes.data_tancament} style={{cursor: "pointer"}}>
                {item.attributes.tipus_incidencia_name} <br/>
                <Text disabled={!!item.attributes.data_tancament} type="secondary" style={{cursor: "pointer"}}>{item.attributes.alias || "-"}</Text>
              </Text>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "local_ids"]} label="Pisos / Locals">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona un element" loading={isFetchingFlats} filterOption={filterOptionByLabel} showSearch>
          {flats.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.adr1}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "servei_ids"]} label="Serveis">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els serveis" loading={isFetchingServeis} filterOption={filterOptionByLabel} showSearch>
          {serveis.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      {/*<Form.Item name={["attributes", "propietari_ids"]} label="Propietaris">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els propietaris" loading={isFetchingPropietaris} filterOption={filterOptionByLabel} showSearch>
          {propietaris.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>*/}
      <Form.Item name={["attributes", "contact_ids"]} label="Contactes">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els contactes" loading={isFetchingContactes} filterOption={filterOptionByLabel} showSearch>
          {contactes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default IntervencioForm;
