import React from "react";

import { Form, Table, Alert, Button, Input, Select, message } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useQuery, useRouter } from "hooks/useRouter";
import { renderRecordActions, renderObservations, filterOptionByLabel } from "utils/helpers";
import { useTable } from "hooks/useTableApi";
import { FormInstance } from "antd/lib/form";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import { ModalFormChildProps, ModalFormWithForwardRef } from "components/ModalFormApi";
import api from "api";
import {IPropietari} from "api/interfaces/Propietari";
import PropietariForm from "./create";
import { useAxiosRequest } from "use-axios-request";
import { axiosConfig } from "utils/request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    nom_cont: undefined,
    tipus_propietat_id_eq: undefined,
  })
}

const propietaryTypesRequest = { ...axiosConfig, url: "tipus_propietat", params: { items: "all" } };

const PropietarisList: React.FC = () => {
  // useWhyDidYouUpdate('PropietarisList', props);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();

  const { filters: query } = useQuery();
  const router = useRouter();

  const { tableProps, error, refresh, reload, search } = useTable<IPropietari>(api.propietaris, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const { data: propietaryTypesData, isFetching: isFetchingPropietaryTypes } = useAxiosRequest<{ data: DicitonaryType[] }>(propietaryTypesRequest);
  const { data: propietaryTypes = [] } = propietaryTypesData || {};

  const goToPropietari = (id: string) => router.push(`/propietaris/${id}`);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IPropietari> => {
    return {
      title: "Nou propietari",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.propietaris.newInstance()
    }
  }, [refresh]);

  const { create, drawerProps } = useDrawerForm<IPropietari>(api.propietaris, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await api.propietaris.destroy(id);

    if (response.isSuccess()) {
      refresh();
    } else {
      const error = response.fail().summary || "No s'ha pogut eliminar el registre"
      message.error(error, 10);
    }
  }

  const columns: ColumnType<IPropietari>[] = [
    {
      title: 'Nom',
      dataIndex: ["attributes", 'nom'],
      key: "name"
    },
    {
      title: 'Tipus propietat',
      key: "tipus_propietat",
      dataIndex: ["attributes", "tipus_propietat_name"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToPropietari, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="nom_cont">
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item name="tipus_propietat_id_eq" style={{marginRight: 0}}>
          <Select placeholder="Tipus de servei" loading={isFetchingPropietaryTypes} filterOption={filterOptionByLabel} showSearch>
            {propietaryTypes.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["propietaris:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form }: ModalFormChildProps) => (
          <PropietariForm form={form} />
        )}
      </ModalFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default PropietarisList;
