import { useReducer, useMemo, Reducer, useEffect, useCallback } from "react";
import { useAxiosRequest } from 'use-axios-request';
import { AxiosError } from "axios";
import { useUpdateEffect } from "@umijs/hooks";

import { axiosConfig } from "utils/request";
import { FormInstance } from "antd/lib/form";
import { TableProps } from "antd/lib/table";

export interface useInnerTableReturnType<RecordType> {
  search?: {
    reset: () => void,
    submit: (value: string, event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>) => void
  },
  tableProps: Partial<TableProps<RecordType>>,
  refresh: () => void;
  reload: () => void;
  error: Error | null;
};

export interface UseInnerTableOptionsType {
  form?: FormInstance;
  formResetCallback?: (form: FormInstance) => void;
  current?: number;
  defaultPageSize?: number;
  baseQuery?: { [name: string]: any };
}

class UseInnerTableInitialState<RecordType> {
  // Current page
  current = 1;

  // Page size
  pageSize = 15;

  // Total items
  total = 0;

  // Array of records
  data: RecordType[] = [];

  // Form data
  baseQuery: { [name: string]: any } = {};

  // Form data
  searchQuery: { [name: string]: any } = {};

  // Counter
  requestCounter = 0;
}

const reducer = <RecordType>(state: UseInnerTableInitialState<RecordType>, action: { type: string; payload?: {} }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

export const useInnerTable = <RecordType>(baseUrl: string, options: UseInnerTableOptionsType = {}) : useInnerTableReturnType<RecordType> => {
  const initialState = useMemo(() => new UseInnerTableInitialState<RecordType>(), []);

  // No hay problema que se cree cada vez, porque el useState y useReducer
  // solo se inicializan la primera vez, por tanto no provoca redender
  const { current = 1, defaultPageSize = 15, baseQuery = {}, form, formResetCallback } = options;

  const [state, dispatch] = useReducer<Reducer<UseInnerTableInitialState<RecordType>, any>>(reducer, {
    ...initialState,
    current: current,
    pageSize: defaultPageSize,
    baseQuery: baseQuery
  });

  const requestCallbacks = useMemo(() => ({
    onSuccess: (data: CollectionResponse<RecordType>) => {
      const { page, count, vars: { items }} = data.meta.pagy;

      dispatch({
        type: 'updateState',
        payload: {
          data: data.data,
          current: page,
          total: count,
          pageSize: items,
        }
      })
    },
    onError: (_error: AxiosError) => {
      dispatch({
        type: 'updateState',
        payload: {
          data: [],
          current: 1,
          total: 0,
          pageSize: defaultPageSize,
        }
      })
    }
  }), [dispatch, defaultPageSize]);

  const { isFetching, update, error } = useAxiosRequest<CollectionResponse<RecordType>>(null, requestCallbacks)

  // Tienen que usar useCallback porque al devolverlas al principal
  // si no son la misma provocaria un re-render?
  const reload = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: {
        current: 1,
        searchQuery: {},
        requestCounter: state.requestCounter + 1,
      },
    });
  }, [state.requestCounter]);

  const refresh = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: { requestCounter: state.requestCounter + 1 },
    });
  }, [state.requestCounter]);

  const searchReset = useCallback(() => {
    if (!form) {
      return;
    }

    if (formResetCallback) {
      formResetCallback(form)
    } else {
      form.resetFields();
    }

    dispatch({
      type: 'updateState',
      payload: {
        current: 1,
        searchQuery: form.getFieldsValue()
      }
    });

    refresh();
  }, [dispatch, form, refresh, formResetCallback]);

  const searchSubmit = useCallback(
    (value: string, event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>) : void => {
      if (!form) {
        return;
      }
      if (event && (event as React.SyntheticEvent<HTMLElement>).preventDefault) {
        (event as React.SyntheticEvent<HTMLElement>).preventDefault();
      }

      setTimeout(() => {
        const activeFormData = form.getFieldsValue();

        dispatch({
          type: 'updateState',
          payload: {
            searchQuery: { ...state.searchQuery, ...activeFormData },
          },
        });

        refresh();
      });
    },
    [form, refresh, state.searchQuery],
  );

  useEffect(() => {
    refresh();
    // This is OK, we only call this on first mount to fire the axios request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    reload()
    // This is OK, we only call this if base URL changes
    // if we add reload, we have an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl])

  useUpdateEffect(() => {
    update({
      ...axiosConfig,

      url: baseUrl,
      params: {
        page: state.current,
        items: state.pageSize,
        filters: { ...state.searchQuery, ...state.baseQuery }
      }
    });
  }, [update, state.current, state.pageSize, state.requestCounter])

  const handlePagination = useCallback<TableProps<RecordType>["onChange"]>(
    (pagination) => {
      dispatch({
        type: 'updateState',
        payload: {
          current: pagination.current,
          pageSize: pagination.pageSize,
          count: state.requestCounter + 1,
        }
      })
  }, [state.requestCounter]);

  const result : useInnerTableReturnType<RecordType> = {
    tableProps: {
      bordered: false,
      size: "small",
      dataSource: state.data,
      loading: isFetching,
      onChange: handlePagination,
      pagination: {
        size: "small",
        current: state.current,
        pageSize: state.pageSize,
        total: state.total,
        hideOnSinglePage: true
      }
    },
    refresh,
    reload,
    error
  }

  if (form) {
    result.search = {
      submit: searchSubmit,
      reset: searchReset
    }
  }

  return result;
}
