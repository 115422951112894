import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { IPropietariResult, IPropietarisResult, IPropietari } from '../interfaces/Propietari'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Propietaris extends Http implements IQueryList, IQueryEditable {
  public async list(params: IQuery = {}): Promise<IPropietarisResult> {
    return await this.request(GET, Routes.propietarisPath(), {}, params) as IPropietarisResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IPropietariResult> {
    return await this.request(GET, Routes.propietariPath(id), {}, params) as IPropietariResult
  }

  public async create(params: JsonApiDocument): Promise<IPropietariResult> {
    return await this.request(POST, Routes.propietarisPath(), {}, params) as IPropietariResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IPropietariResult> {
    return await this.request(PATCH, Routes.propietariPath(id), {}, params) as IPropietariResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.propietariPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IPropietari {
    return {
      id: null,
      type: "propietaris",
      attributes: {
        nom: "",
        tipus_propietat_id: null,
        observacions: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
