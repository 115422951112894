const basePath = `api`

export const Routes = {
  applicationMessagesPath: () => `${basePath}/application_messages`,
  applicationMessagePath: (id: string) => `${basePath}/application_messages/${id}`,
  avConvivenciesPath: (community_id: string) => `${basePath}/communities/${community_id}/av_convivencies`,
  avConvivenciaPath: (community_id: string, id: string) => `${basePath}/communities/${community_id}/av_convivencies/${id}`,
  avEstructuresPath: (community_id: string) => `${basePath}/communities/${community_id}/av_estructures`,
  avEstructuraPath: (community_id: string, id: string) => `${basePath}/communities/${community_id}/av_estructures/${id}`,
  avOrganitzacionsPath: (community_id: string) => `${basePath}/communities/${community_id}/av_organitzacions`,
  avOrganitzacioPath: (community_id: string, id: string) => `${basePath}/communities/${community_id}/av_organitzacions/${id}`,
  avParticipacionsPath: (community_id: string) => `${basePath}/communities/${community_id}/av_participacions`,
  avScorePath: (community_id: string) => `${basePath}/communities/${community_id}/av_score`,
  avParticipacioPath: (community_id: string, id: string) => `${basePath}/communities/${community_id}/av_participacions/${id}`,
  administracionsCcvvPath: () => `${basePath}/administracio_ccvvs`,
  administracioCcvvPath: (id: string) => `${basePath}/administracio_ccvvs/${id}`,
  contactsPath: () => `${basePath}/contacts`,
  contactPath: (id: string) => `${basePath}/contacts/${id}`,
  contactingsPath: () => `${basePath}/contactings`,
  contactingPath: (id: string) => `${basePath}/contactings/${id}`,
  propietarisPath: () => `${basePath}/propietaris`,
  propietariPath: (id: string) => `${basePath}/propietaris/${id}`,
  serveisPath: () => `${basePath}/serveis`,
  serveiPath: (id: string) => `${basePath}/serveis/${id}`,
  usersPath: () => `${basePath}/users`,
  userPath: (id: string) => `${basePath}/users/${id}`,

  calendarTasksPath: () => `${basePath}/calendar/tasks`,
  calendarTaskPath: (id: string) => `${basePath}/calendar/tasks/${id}`,

  communitiesPath: () => `${basePath}/communities`,
  communityPath: (id: string) => `${basePath}/communities/${id}`,
  observacionsCcvvPath: (community_id: string) => `${basePath}/communities/${community_id}/observacio_ccvvs`,
  observacioCcvvPath: (community_id: string, id: string) => `${basePath}/communities/${community_id}/observacio_ccvvs/${id}`,
  gestionsCcvvPath: (community_id: string) => `${basePath}/communities/${community_id}/gestio_ccvvs`,
  gestioCcvvPath: (community_id: string, id: string) => `${basePath}/communities/${community_id}/gestio_ccvvs/${id}`,

  expedientsPath: () => `${basePath}/expedients`,
  expedientPath: (id: string) => `${basePath}/expedients/${id}`,
  expedientConsultesPath: (expedient_id: string) => `${basePath}/expedients/${expedient_id}/consultes`,
  expedientConsultaPath: (expedient_id: string, id: string) => `${basePath}/expedients/${expedient_id}/consultes/${id}`,
  expedientCoordinacionsPath: (expedient_id: string) => `${basePath}/expedients/${expedient_id}/coordinacions`,
  expedientCoordinacioPath: (expedient_id: string, id: string) => `${basePath}/expedients/${expedient_id}/coordinacions/${id}`,
  expedientGestionsPath: (expedient_id: string) => `${basePath}/expedients/${expedient_id}/gestions`,
  expedientGestioPath: (expedient_id: string, id: string) => `${basePath}/expedients/${expedient_id}/gestions/${id}`,
  expedientLocalsPath: (expedient_id: string) => `${basePath}/expedients/${expedient_id}/locals`,
  expedientLocalPath: (expedient_id: string, id: string) => `${basePath}/expedients/${expedient_id}/locals/${id}`,
  expedientIncidenciesPath: (expedient_id: string) => `${basePath}/expedients/${expedient_id}/incidencies`,
  expedientIncidenciaPath: (expedient_id: string, id: string) => `${basePath}/expedients/${expedient_id}/incidencies/${id}`,
  expedientIncidenciaLocalsPath: (expedient_id: string, incidencia_id: string) => `${basePath}/expedients/${expedient_id}/incidencies/${incidencia_id}/locals`,
  expedientIncidenciaLocalPath: (expedient_id: string, incidencia_id: string, id: string) => `${basePath}/expedients/${expedient_id}/incidencies/${incidencia_id}/locals/${id}`,
  expedientIntervencionsPath: (expedient_id: string) => `${basePath}/expedients/${expedient_id}/intervencions`,
  expedientIntervencioPath: (expedient_id: string, id: string) => `${basePath}/expedients/${expedient_id}/intervencions/${id}`,
  expedientParticipacionsPath: (expedient_id: string) => `${basePath}/expedients/${expedient_id}/participacions`,
  expedientParticipacioPath: (expedient_id: string, id: string) => `${basePath}/expedients/${expedient_id}/participacions/${id}`,
};
