import React, {Dispatch, useCallback, useMemo, useState} from "react";
import {Form, Row, Col, Input, Button, DatePicker, Select} from "antd";
import {rowConfig, oneColumn, fourColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IQueryEditable} from "../../api/interfaces/Query";
import {IExpedient} from "../../api/interfaces/Expedient";
import {dateFormats} from "../../utils/formats";
import {axiosConfig} from "../../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType, MotiuTancamentDictionaryType} from "../preferences/dictionaries/model";
import { IState } from "./edit";
import {filterOptionByLabel} from "../../utils/helpers";
import { IUser } from "api/interfaces/User";
import { IServei } from "api/interfaces/Servei";

interface ExpedientFormProps {
  api: IQueryEditable,
  record: IExpedient,
  dispatch: Dispatch<{ type: string; payload?: Partial<IState>; }>
}

const rules = {
  data: [ { required: true, message: "Aquest camp és requerit" } ],
  canal_entrada_id: [ { required: true, message: "Aquest camp és requerit" } ],
  demandant_id: [ { required: true, message: "Aquest camp és requerit" } ],
  required: [ { required: true, message: "Aquest camp és requerit" } ],
};

class InnerForm extends PageForm<IExpedient> {}

const canalsRequest = { ...axiosConfig, url: "canals_entrada", params: { items: "all" } };
const demandantsRequest = { ...axiosConfig, url: "demandants_expedients", params: { items: "all" } };
const tancamentsRequest = { ...axiosConfig, url: "motius_tancament", params: { items: "all" } };
const serveisRequest = { ...axiosConfig, url: "serveis", params: { items: "all" } };
const usersRequest = { ...axiosConfig, url: "users", params: { items: "all", filters: { role_in: ["admin", "dinamitzador_servei"] } } };

const ExpedientForm: React.FC<ExpedientFormProps> = ({ api, record, dispatch }) => {
  const [motiuTancamentID, setMotiuTancamentID] = useState<number | string>(record.attributes.motiu_tancament_id);
  const [form] = Form.useForm();

  const handleCreated = (item) => {
    dispatch({
      type: 'updateState',
      payload: {
        item: item
      }
    });
  };

  const handleMotiuTancamentChange = useCallback((value) => {
    setMotiuTancamentID(value);

    // form.setFieldsValue({attributes: { motiu_tancament_servei_id: "" }});
  }, [setMotiuTancamentID/*, form*/]);

  const { data: canalsData, isFetching: isFetchingCanals } = useAxiosRequest<{ data: DicitonaryType[] }>(canalsRequest);
  const { data: canals = [] } = canalsData || {};

  const { data: demandantsData, isFetching: isFetchingDemandants } = useAxiosRequest<{ data: DicitonaryType[] }>(demandantsRequest);
  const { data: demandants = [] } = demandantsData || {};

  const { data: tancamentsData, isFetching: isFetchingTancaments } = useAxiosRequest<{ data: MotiuTancamentDictionaryType[] }>(tancamentsRequest);
  const { data: tancaments = [] } = tancamentsData || {};

  const { data: serveisData, isFetching: isFetchingServeis } = useAxiosRequest<{ data: IServei[] }>(serveisRequest);
  const { data: serveis = [] } = serveisData || {};

  const { data: usersData, isFetching: isFetchingUsers } = useAxiosRequest<{ data: IUser[] }>(usersRequest);
  const { data: users = [] } = usersData || {};

  const showMotiuTancamentServei = useMemo(() => {
    const currentMotiuTancament = tancaments && tancaments.find((item) => String(item.id) === String(motiuTancamentID));

    return currentMotiuTancament && currentMotiuTancament.attributes.requires_selecting_a_service;
  }, [motiuTancamentID, tancaments]);

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleCreated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Row {...rowConfig}>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "data_inici"]} label="Data d'inici" rules={rules.data}>
                <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "data_fi"]} label="Data fi">
                <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "motiu_tancament_id"]} label="Motiu tancament">
                <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingTancaments} filterOption={filterOptionByLabel} showSearch allowClear
                 onChange={handleMotiuTancamentChange}>
                  {tancaments.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            { showMotiuTancamentServei &&
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "motiu_tancament_servei_id"]} label="Servei tancament" rules={showMotiuTancamentServei ? rules.required : []}>
                <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingServeis} filterOption={filterOptionByLabel} showSearch allowClear>
                  {serveis.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col> }
          </Row>
          <Row {...rowConfig}>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "canal_entrada_id"]} label="Canal d'entrada" rules={rules.canal_entrada_id}>
                <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingCanals} filterOption={filterOptionByLabel} showSearch>
                  {canals.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "referencia_canal_entrada"]} label="Referència canal d'entrada">
                <Input disabled={readOnly} placeholder="Nº de referència" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "demandant_id"]} label="Demandant" rules={rules.demandant_id}>
                <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingDemandants} filterOption={filterOptionByLabel} showSearch>
                  {demandants.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "professional_id"]} label="Professional de referència" rules={rules.required}>
                <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingUsers} filterOption={filterOptionByLabel} showSearch>
                  {users.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.full_name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "observacions"]} label="Observacions">
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{minRows: 3}} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
          </Row>
        </>
      )}
    </InnerForm>
  );
};

export default ExpedientForm;
