import { useMemo, useRef, RefObject } from "react";
import DrawerForm, { DrawerFormProps } from "components/DrawerFormApi";
import { Form, notification } from "antd";
import { IQueryEditable } from "api/interfaces/Query";
import { JsonApiDocument } from "api/interfaces/JsonApi";


export interface useDrawerFormReturnType<RecordType extends JsonApiDocument> {
  create: () => void
  edit: (id: string) => void
  drawerProps: {
    forwardedRef: RefObject<DrawerForm<RecordType>>
  } & DrawerFormProps<RecordType>
};

export interface useDrawerFormOptionsType<RecordType extends JsonApiDocument> {
  title?: string,
  renderTitle?: (item: RecordType) => React.ReactNode,
  newRecord: RecordType,
  handleCreated?: DrawerFormProps<RecordType>["handleCreated"]
  handleUpdated?: DrawerFormProps<RecordType>["handleUpdated"]
  eventBusPrefix?: DrawerFormProps<RecordType>["eventBusPrefix"]
}

const noop = () => {};

export const useDrawerForm = <RecordType extends JsonApiDocument>(api: IQueryEditable, options: useDrawerFormOptionsType<RecordType>) : useDrawerFormReturnType<RecordType> => {
  const [form] = Form.useForm();
  const drawerRef = useRef<DrawerForm<RecordType>>(null);

  const { title, renderTitle, handleCreated = noop, handleUpdated = noop, eventBusPrefix, newRecord } = options;

  const initialValues = useMemo(() => newRecord, [newRecord])

  // const requestCallbacks = useMemo(() => ({
  //   onSuccess: (data: ItemResponse<RecordType>) => {
  //     drawerRef.current?.show(data.data);
  //   },
  //   onError: (error: AxiosError) => {
  //     drawerRef.current?.handleCancel();

  //     const { response } = error;

  //     if (response && response.status) {
  //       const { status } = response;
  //       const errorText = codeMessage[response.status] || response.statusText;

  //       notification.error({
  //         message: `Error ${status}`,
  //         description: errorText,
  //       });
  //     } else if (!response) {
  //       notification.error({
  //         message: `Error`,
  //         description: "Hi hagut un error desconegut"
  //       });
  //     }
  //   }
  // }), []);

  // const { update } = useAxiosRequest<ItemResponse<RecordType>>(null, requestCallbacks)

  const create = () => {
    drawerRef.current?.show()
  }

  const edit = async (id: string) => {
    drawerRef.current?.show();
    drawerRef.current?.setState({ loading: true });

    const response = await api.show(id, {});

    if (response.isSuccess()) {
      drawerRef.current?.show(response.success().data as RecordType)
    } else {
      drawerRef.current?.handleCancel();

      //     const { response } = error;

      //     if (response && response.status) {
      //       const { status } = response;
      //       const errorText = codeMessage[response.status] || response.statusText;

      //       notification.error({
      //         message: `Error ${status}`,
      //         description: errorText,
      //       });
      //     } else if (!response) {
      //       notification.error({
      //         message: `Error`,
      //         description: "Hi hagut un error desconegut"
      //       });
      //     }
      //   }

      notification.error({
        message: response.fail().name,
        description: response.fail().message
      });
    }
  }


  const result: useDrawerFormReturnType<RecordType> = {
    create,
    edit,
    drawerProps: {
      api,
      form,
      title,
      renderTitle,
      handleCreated,
      handleUpdated,
      eventBusPrefix,
      forwardedRef: drawerRef,
      initialValues
    }
  }

  return result;
}
