import React from "react";
import {Button, Table, Alert, Form} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {FormInstance} from "antd/lib/form";
import {renderRecordDate} from "utils/helpers";
import {IGestioCcvv} from "../../../api/interfaces/community/GestioCcvv";

interface GestioListProps {
  communitiyId: string
  hideHistory: () => void
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    tipus_consulta: undefined
  })
};

const GestioList: React.FC<GestioListProps> = ({ communitiyId, hideHistory }) => {
  const [form] = Form.useForm();
  const apiEndpoint = React.useMemo(() => api.gestioCcvvs(communitiyId), [communitiyId]);

  const { tableProps, error, reload } = useInnerTable<IGestioCcvv>(apiEndpoint, {form, formResetCallback });

  const columns: ColumnType<IGestioCcvv>[] = [
    {
      title: "Data",
      key: "start_date",
      render: renderRecordDate("data")
    },
    {
      title: 'Junta',
      key: "type",
      dataIndex: ["attributes", "junta"]
    },
    {
      title: 'NIF',
      key: "nif",
      dataIndex: ["attributes", "nif"]
    },
    {
      title: 'Reunions Extr.',
      key: "reunions_extraordinares",
      dataIndex: ["attributes", "reunions_extraordinares"]
    },
    {
      title: 'Reunions Ord.',
      key: "reunions_ordinaries",
      dataIndex: ["attributes", "reunions_ordinaries"]
    },
    {
      title: 'Llibre actes',
      key: "llibre_actes",
      dataIndex: ["attributes", "llibre_actes"]
    },
    {
      title: 'Llibre comptes',
      key: "llibre_comptes",
      dataIndex: ["attributes", "llibre_comptes"]
    },
    {
      title: 'Compte ancari',
      key: "compte_bancari",
      dataIndex: ["attributes", "compte_bancari"]
    },
    {
      title: 'Gestoria',
      key: "gestoria",
      dataIndex: ["attributes", "gestoria"]
    },
    {
      title: 'Neteja',
      key: "neteja",
      dataIndex: ["attributes", "neteja"]
    },
    {
      title: 'Altres serveis',
      key: "altres_serveis",
      dataIndex: ["attributes", "altres_serveis"]
    },
    // {
    //   title: " ",
    //   key: "actions",
    //   align: "right",
    //   render: renderRecordActions(edit)
    // }
  ];

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="ghost" onClick={hideHistory}>Ocultar historial</Button>
      </div>

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />
    </>
  );
};

export default GestioList;
