import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable, IQueryExportable } from '../../interfaces/Query'
import { IExpedientIntervencioResult, IExpedientIntervencionsResult, IExpedientIntervencio } from '../../interfaces/expedient/Intervencio'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'
import qs from 'qs'

export default class ExpedientIntervencions extends Http implements IQueryList, IQueryEditable, IQueryExportable, JsonApiDocumentGenerator {
  private readonly expedient_id: string;

  protected dateFields: string[] = ["data_realitzacio"];

  constructor(expedient_id: string, host?: string) {
    super(host);
    this.expedient_id = expedient_id;
  }

  public async list(params: IQuery = {}): Promise<IExpedientIntervencionsResult> {
    return await this.request(GET, Routes.expedientIntervencionsPath(this.expedient_id), {}, params) as IExpedientIntervencionsResult
  }

  public exportUrl(format: string, params: IQuery = {}): string {
    return '/' + Routes.expedientIntervencionsPath(this.expedient_id) + `?${qs.stringify({ ...params, export: format}, { encodeValuesOnly: true, arrayFormat: 'brackets' })}`;
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientIntervencioResult> {
    return await this.request(GET, Routes.expedientIntervencioPath(this.expedient_id, id), {}, params) as IExpedientIntervencioResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientIntervencioResult> {
    return await this.request(POST, Routes.expedientIntervencionsPath(this.expedient_id), {}, params) as IExpedientIntervencioResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientIntervencioResult> {
    return await this.request(PATCH, Routes.expedientIntervencioPath(this.expedient_id, id), {}, params) as IExpedientIntervencioResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientIntervencioPath(this.expedient_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IExpedientIntervencio {
    return {
      id: null,
      type: "intervencio_expedient",
      attributes: {
        data_realitzacio: moment(),
        tipus_intervencio_id: null,
        tipus_intervencio_name: "",
        observacions: ""
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
