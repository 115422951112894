import React from "react";
import { DrawerFormChildProps } from "components/DrawerFormApi";
import { dateFormats } from "utils/formats";
import { Form, DatePicker, Input, Select } from "antd";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import {axiosConfig} from "utils/request";
import {filterOptionByLabel} from "utils/helpers"

const relacionsRequest = { ...axiosConfig, url: "av_d_relacions", params: { items: "all" } };
const conflictesRequest = { ...axiosConfig, url: "av_d_gestio_conflictes", params: { items: "all" } };
const optionsRequest = { ...axiosConfig, url: "av_d_si_no_nsnc_tramit", params: { items: "all" } };

const AvConvivenciaForm: React.FC<DrawerFormChildProps> = ({ form, readOnly }) => {
  const { data: relacions = { data: [] }, isFetching: isFetchingRelacions } = useAxiosRequest<{ data: DicitonaryType[] }>(relacionsRequest);
  const { data: conflictes = { data: [] }, isFetching: isFetchingConflictes } = useAxiosRequest<{ data: DicitonaryType[] }>(conflictesRequest);
  const { data: options = { data: [] }, isFetching: isFetchingOptions } = useAxiosRequest<{ data: DicitonaryType[] }>(optionsRequest);

  return (
    <>
      <Form.Item name={["attributes", "data"]} label="Data">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "relacions"]} label="Relació entre les persones">
        <Select placeholder="Selecciona un valor" loading={isFetchingRelacions} filterOption={filterOptionByLabel} showSearch>
          {relacions && relacions.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "gestio_conflictes"]} label="Forma de gestionar els conflictes">
        <Select placeholder="Selecciona un valor" loading={isFetchingConflictes} filterOption={filterOptionByLabel} showSearch>
          {conflictes && conflictes.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "violencia"]} label="Situacions de violència explícita">
        <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
          {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "delinquencia"]} label="Situacions de delinqüència">
        <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
          {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "vandalisme"]} label="Situacions de vandalisme">
        <Select placeholder="Selecciona un valor" loading={isFetchingOptions} filterOption={filterOptionByLabel} showSearch>
          {options && options.data.map((item: DicitonaryType) => <Select.Option key={item.attributes.nom} value={item.attributes.nom} disabled={readOnly}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
}


export default AvConvivenciaForm;
