import React from "react";
import {Button, Table, Alert, Form, Select, message} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {FormInstance} from "antd/lib/form";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {filterOptionByLabel, renderRecordActions, renderRecordDateRange, renderObservations} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IExpedientGestio} from "api/interfaces/expedient/Gestio";
import GestioForm from "../forms/gestio";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "pages/preferences/dictionaries/model";

interface GestionsListProps {
  expedientId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    tipus_gestio: undefined
  })
};

const gestionsRequest = { ...axiosConfig, url: "tipus_gestions", params: { items: "all" } };

const GestionsList: React.FC<GestionsListProps> = ({ expedientId }) => {
  const [form] = Form.useForm();
  const apiEndpoint = React.useMemo(() => api.expedientGestions(expedientId), [expedientId]);

  const { tableProps, error, refresh, reload, search } = useInnerTable<IExpedientGestio>(apiEndpoint, { form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const { data: gestionsData, isFetching: isFetchingGestions } = useAxiosRequest<{ data: DicitonaryType[] }>(gestionsRequest);
  const { data: gestions = [] } = gestionsData || {};

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientGestio> => {
    return {
      title: "Nou tipus d'expedient",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IExpedientGestio>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<IExpedientGestio>[] = [
    {
      title: 'Tipus',
      key: "type",
      dataIndex: ["attributes", "tipus_gestio_name"]
    },
    {
      title: "Data",
      key: "date_range",
      render: renderRecordDateRange("data_inici", "data_tancament")
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={{}} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="tipus_gestio" style={{marginRight: 0}}>
          <Select placeholder="Tipus de gestió" loading={isFetchingGestions} filterOption={filterOptionByLabel} showSearch>
            {gestions.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" onClick={form.submit}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <GestioForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default GestionsList;
