import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable } from '../interfaces/Query'
import { ICommunityResult, ICommunitiesResult, ICommunity } from '../interfaces/Community'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Communities extends Http implements IQueryList, IQueryEditable {
  public async list(params: IQuery = {}): Promise<ICommunitiesResult> {
    return await this.request(GET, Routes.communitiesPath(), {}, params) as ICommunitiesResult
  }

  public async show(id: string, params: IQuery = {}): Promise<ICommunityResult> {
    return await this.request(GET, Routes.communityPath(id), {}, params) as ICommunityResult
  }

  public async create(params: JsonApiDocument): Promise<ICommunityResult> {
    return await this.request(POST, Routes.communitiesPath(), {}, params) as ICommunityResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<ICommunityResult> {
    return await this.request(PATCH, Routes.communityPath(id), {}, params) as ICommunityResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.communityPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : ICommunity {
    return {
      id: null,
      type: "communities",
      attributes: {

      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
