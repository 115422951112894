import React from "react";
import { Form, Row, Col, Input, Button, Radio } from "antd";
import {rowConfig, oneColumn, fourColumns} from "utils/constants";
import PageForm, { PageFormChildProps } from "components/PageFormApi";
import {IServei} from "api/interfaces/Servei";
import {IQueryEditable} from "../../api/interfaces/Query";

interface ServeiFormProps {
  api: IQueryEditable,
  record: IServei
}

class InnerForm extends PageForm<IServei> {}

const ServeiForm: React.FC<ServeiFormProps> = ({ api, record }) => {
  const [form] = Form.useForm();

  const handleCreated = () => void 0;

  return (
    <InnerForm
      api={api}
      form={form}
      initialValues={record}
      handleCreated={handleCreated}
      handleUpdated={handleCreated}
    >
      {({ loading, readOnly }: PageFormChildProps) => (
        <>
          <Form.Item name={["attributes", "is_administracio_finques"]} style={{marginBottom: 0, height: 0}}>
            <Input type="hidden" />
          </Form.Item>
          <Row {...rowConfig}>
            <Col {...fourColumns}>
              <Form.Item name={["attributes", "nom"]}  label="Nom">
                <Input type="text" disabled={readOnly} placeholder="Nom del servei" />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...oneColumn}>
              <Form.Item name={["attributes", "observacions"]} label="Observacions">
                <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{minRows: 3}} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowConfig} justify="center">
            <Form.Item style={{marginBottom: 0}}>
              <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
            </Form.Item>
            </Row>
          </>
      )}
    </InnerForm>
  );
};

export default ServeiForm;
