import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IExpedientLocalResult, IExpedientLocalsResult, IExpedientLocal } from '../../interfaces/expedient/Local'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class ExpedientLocals extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly expedient_id: string;

  protected dateFields: string[] = ["data"];

  constructor(expedient_id: string, host?: string) {
    super(host);
    this.expedient_id = expedient_id;
  }

  public async list(params: IQuery = {}): Promise<IExpedientLocalsResult> {
    return await this.request(GET, Routes.expedientLocalsPath(this.expedient_id), {}, params) as IExpedientLocalsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IExpedientLocalResult> {
    return await this.request(GET, Routes.expedientLocalPath(this.expedient_id, id), {}, params) as IExpedientLocalResult
  }

  public async create(params: JsonApiDocument): Promise<IExpedientLocalResult> {
    return await this.request(POST, Routes.expedientLocalsPath(this.expedient_id), {}, params) as IExpedientLocalResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IExpedientLocalResult> {
    return await this.request(PATCH, Routes.expedientLocalPath(this.expedient_id, id), {}, params) as IExpedientLocalResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.expedientLocalPath(this.expedient_id, id), {}, params) as ResultResponse<EmptyResponse>;
  }

  public newInstance() : IExpedientLocal {
    return {
      id: null,
      type: "expedient_local",
      attributes: {
        data: moment(),
        regim_id: null,
        regim_name: "",
        participacio_id: null,
        participacio_organ_name: "",
        tipus_propietat_name: "",
        propietari_id: null,
        propietari_name: "",
        dades_contacte: "",
        local_id: null,
        local_name: "",
        observacions: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }
}
