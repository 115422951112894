import ContactesSection from "pages/shared/contacts/list";
import React, {useCallback} from "react";
import ContactsLocalsList from "pages/expedients/sections/contacts_locals";
import { IContacting } from "api/interfaces/Contacting";


interface ContactsLocalsListProps {
  expedientId: string
}

const ContactsSection: React.FC<ContactsLocalsListProps> = ({ expedientId }) => {
  const expandedRowRender = useCallback((record: IContacting): React.ReactNode => {
    return <ContactsLocalsList expedientId={expedientId} contactId={record.attributes.contact.data.id}/>;
  },[expedientId]) ;

  return(
    <ContactesSection type="Expedient" id={expedientId} expandable={{ expandedRowRender }}/>
  )
}

export default ContactsSection;
