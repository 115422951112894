import React, {useMemo} from "react"
import {Select} from "antd"
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import {Routes} from "api/routes";
import {IExpedientLocal} from "api/interfaces/expedient/Local";
import {axiosConfig} from "utils/request";
import { ModalRelationChildProps } from "components/ModalRelationApi";
import { IContact } from "api/interfaces/Contact";

export interface ContatcLocalFormProps {
  expedientId: string;
  readOnly?: boolean;
  rowSelection: ModalRelationChildProps<IContact>["rowSelection"];
}

const ContatcLocalForm: React.FC<ContatcLocalFormProps> = ({ expedientId, rowSelection, readOnly = false }) => {
  const localsRequest = useMemo(() => {
    return { ...axiosConfig, baseURL: "/", url: Routes.expedientLocalsPath(String(expedientId)), params: { items: "all", filters: { last_record: true } }}
  }, [expedientId]);

  const { data: flatsData, isFetching: isFetchingFlats } = useAxiosRequest<{ data: IExpedientLocal[] }>(localsRequest);
  const { data: flats = [] } = flatsData || {};

  return (
    <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els locals/pisos" loading={isFetchingFlats} className="w100"
            filterOption={filterOptionByLabel} value={rowSelection.selectedRowKeys} showSearch onChange={rowSelection.onChange}>
      {flats.map((item) => <Select.Option key={item.id} value={item.attributes.local_id}>{item.attributes.local_name}</Select.Option>)}
    </Select>
  )
};

export default ContatcLocalForm;
