import React, { useCallback, useState, useMemo } from "react";
import { Form, Select } from "antd";
import { useAxiosRequest } from "use-axios-request";
import { useDebounceFn } from '@umijs/hooks';

import { GetCarrersRequest, GetCarrersTypeResponse, GetCarrersPerCodiRequest } from "utils/webService";
import { trim } from "lodash";
import { FormInstance, FormItemProps } from "antd/lib/form";
import { SelectProps } from "antd/lib/select";
import { axiosConfig } from "utils/request";

export interface CarrerSelectorProps {
  form: FormInstance;
  field_name: string;
  webservice: boolean;
  itemProps?: Omit<FormItemProps, "children">;
  inputProps?: SelectProps<any>;
}

const CarrerSelector: React.FC<CarrerSelectorProps> = ({ form, field_name, itemProps = {}, inputProps = {}, webservice = true }) => {
  const [search, setSearch] = useState<string>('');

  const initialRequest = useMemo(() => {
    const codiCarrer = trim(form.getFieldValue(field_name));

    if (codiCarrer) {
      if (webservice) {
        return {...GetCarrersPerCodiRequest, params: { sCodi: codiCarrer }};
      } else {
        return { ...axiosConfig, url: "streets", params: { filters: { codi_carrer_eq: codiCarrer } } }
      }
    } else {
      return null;
    }
  }, [form, field_name, webservice]);

  const { data = [], isFetching, update } = useAxiosRequest<GetCarrersTypeResponse[]>(initialRequest);

  const debounce = useDebounceFn(
    () => {
      if (search && trim(search) !== "") {
        if (webservice) {
          update({ ...GetCarrersRequest, params: { Nom: trim(search) } })
        } else {
          update({ ...axiosConfig, url: "streets", params: { filters: { name_lu_cont: trim(search) } }})
        }
      }
    },
    [search],
    300,
  );

  const cancelSearch = useCallback(() => {
    debounce.cancel();
    // cancelAsync(); // Axios request doesn't provide a cancel
  }, [debounce]);

  const values = webservice ? data : (data as any).data;

  return (
    <Form.Item {...itemProps} name={field_name}>
      <Select
        {...inputProps}
        showSearch
        filterOption={false}
        onSearch={setSearch}
        onBlur={cancelSearch}
        placeholder="Selecciona el carrer"
        loading={isFetching}
      >
        {webservice && values && values.map((item) => <Select.Option key={item.codi_carrer} value={String(item.codi_carrer)}>{`${item.des_sigla} ${item.nom_carrer_fmt}`}</Select.Option>)}
        {!webservice && values && values.map((item) => <Select.Option key={item.attributes.codi_carrer} value={String(item.attributes.codi_carrer)}>{`${item.attributes.des_sigla} ${item.attributes.nom_carrer}`}</Select.Option>)}
      </Select>
    </Form.Item>
  );
};

export default CarrerSelector;
