import React, { useMemo } from "react";
import { Table, Input, Form, Button, Typography, Alert } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnType } from "antd/lib/table/interface";
import { useTable } from "hooks/useTable";
import { useQuery } from "hooks/useRouter";
import { renderBooleanColumn, renderRecordActions } from "utils/helpers";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerForm";
import { ModalFormWithForwardRef, ModalFormChildProps } from "components/ModalForm";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import DictionaryForm from "./form";
import { startsWith } from "lodash";

import { DicitonaryType } from "pages/preferences/dictionaries/model";
import TipusRolContacteForm from "./tipus_rol_contacte_form";
import TipusIncidenciaForm from "./tipus_incidencia_form";
import MotiuTancamentForm from "./motiu_tancament_form";

const { Title } = Typography;

export interface DictionaryProps {
  path: string,
  title: string
}

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    nom_cont: undefined
  })
}

const Dictionary: React.FC<DictionaryProps> = ({ path, title }) => {
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();
  const baseUrl = React.useMemo(() => path, [path]);

  const { filters: query } = useQuery();

  const { tableProps, refresh, error, reload, search } = useTable<DicitonaryType>(baseUrl, { form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<DicitonaryType> => {
    return {
      title,
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: {
        id: null,
        type: "dictionary",
        attributes: {
          nom: "",
        },
        meta: {
          permissions: {
            can_edit: true,
            can_show: true
          }
        }
      }
    }
  }, [refresh, title])

  const { create, edit, drawerProps } = useDrawerForm<DicitonaryType>(baseUrl, formOptions);

  const columns: ColumnType<DicitonaryType>[] = useMemo(() => {
    const tableColumns: ColumnType<DicitonaryType>[] = [
      {
        title: 'Nom',
        dataIndex: ["attributes", "nom"],
        key: "attribues.name"
      },
      {
        title: " ",
        key: "actions",
        align: "right",
        render: renderRecordActions(edit)
      }
    ];

    switch(path) {
      case "av_colors":
        tableColumns.splice(
          0,
          1,
          { title: "Ordre", dataIndex: ["attributes", "ordre"], key: "ordre" },
          { title: "Color", dataIndex: ["attributes", "color"], key: "color" },
          { title: "Valor mínim", dataIndex: ["attributes", "valor_minim"], key: "valor_minim" },
          { title: "Valor màxim", dataIndex: ["attributes", "valor_maxim"], key: "valor_maxim" },
          { title: "Risc", dataIndex: ["attributes", "risc"], key: "risc" },
        );
        break;
      case "motius_tancament":
        tableColumns.splice(1, 0, { title: "Requereix seleccionar servei", render: renderBooleanColumn("requires_selecting_a_service"), key: "requires_selecting_a_service" });
        break;
      case "tipus_rol_contacte":
        tableColumns.splice(1, 0, { title: "Grups", dataIndex: ["attributes", "group_names"], key: "group_names" });
        tableColumns.splice(2, 0, { title: "Color", dataIndex: ["attributes", "color"], key: "color" });
        tableColumns.splice(3, 0, { title: "Posició", dataIndex: ["attributes", "position"], key: "position" });
        break;
      case "tipus_incidencies":
        tableColumns.splice(1, 0, { title: "Àmbit", dataIndex: ["attributes", "ambit_tipus_incidencia"], key: "ambit_tipus_incidencia" });
        break;
      default:
        break;
    }

    return tableColumns;
  }, [edit, path]);

  const hideSearcher = useMemo(() => {
    const hideFor = ["av_colors"];

    return hideFor.indexOf(path) !== -1;
  }, [path])

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      {!hideSearcher &&
        <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
          <Form.Item name="nom_cont" style={{marginRight: 0}}>
            <Input.Search placeholder="Nom" loading={tableProps.loading as boolean} onSearch={form.submit}/>
          </Form.Item>
          <Form.Item>
            <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          </Form.Item>
        </Form>
      }
      <div>
        { !startsWith(path, "av_") &&
          <PermissibleRender userPermissions={userPermissions} requiredPermissions={["dictionaries:create"]}>
            <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
          </PermissibleRender>
        }
        { startsWith(path, "av_") &&
          <Alert
            showIcon
            type="warning"
            message="Si us plau, contacta amb l'administrador per modificar aquest diccionari"
          /> }
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <>
      <Title level={3}>{title}</Title>

      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => {
          switch (path) {
            case "motius_tancament":
              return <MotiuTancamentForm form={form} readOnly={readOnly} />;
            case "tipus_rol_contacte":
              return <TipusRolContacteForm form={form} readOnly={readOnly} />;
            case "tipus_incidencies":
              return <TipusIncidenciaForm form={form} readOnly={readOnly} />;
            default:
              return <DictionaryForm form={form} readOnly={readOnly} />
          }
        }}
      </ModalFormWithForwardRef>
    </>
  );
};

export default Dictionary;
