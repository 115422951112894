import React from "react";
import Form, { FormInstance } from "antd/lib/form";
import { Store } from "antd/lib/form/interface";
import { Tabs, Row, Col, Input, Button } from "antd";
import { rowConfig, fourColumns, twoColumns } from "utils/constants";
import ComboCarrerPortal from "components/WebService/ComboCarrerPortal";
import DistricteSelector from "components/WebService/DistricteSelector";
import BarriSelector from "components/WebService/BarriSelector";
import { useQuery } from "hooks/useRouter";
import FlatsFilers from "pages/shared/searchers/flats";
import { Tab } from "rc-tabs/lib/interface";

export interface FlatsSearcherProps {
  form: FormInstance;
  loading: boolean;
  searchSubmit: (formValues: Store) => void,
  resetSearh: () => void
}

export const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    community_barri_districte_id_eq: undefined,
    community_barri_id_eq: undefined,
    community_codi_postal_cont: undefined,
    community_codi_carrer_eq: undefined,
    community_id_portal_eq: undefined,
    referencia_cadastral_cont: undefined,

    has_coord_serveis: undefined,
    has_cons_serveis: undefined,
    pis_has_regim: undefined,
    pis_has_participacio: undefined,
    pis_has_tipus_propietat: undefined,
    pis_has_propietari: undefined,
  })
}

const FlatsSearcher: React.FC<FlatsSearcherProps> = ({ form, loading, searchSubmit, resetSearh}) => {
  const { filters: query } = useQuery();

  const tabs: Tab[] = [
    {
      label: "General",
      key: "1",
      children: (
        <>
          <Row {...rowConfig}>
            <ComboCarrerPortal form={form} field_names={{ carrer: "community_codi_carrer_eq", portal: "community_id_portal_eq" }} webservice={false} />
            <Col {...fourColumns}>
              <DistricteSelector field_name="community_barri_districte_id_eq" />
            </Col>
            <Col {...fourColumns}>
              <BarriSelector field_name="community_barri_id_eq" />
            </Col>
          </Row>
          <Row {...rowConfig}>
            <Col {...twoColumns}>
              <Form.Item name="referencia_cadastral_cont">
                <Input type="text" placeholder="Referència cadastral" />
              </Form.Item>
            </Col>
            <Col {...fourColumns}>
              <Form.Item name="community_codi_postal_cont">
                <Input type="text" placeholder="Codi postal" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    },
    {
      label: "Registres",
      key: "2",
      children: <FlatsFilers />
    }
  ];

  return (
    <Form form={form} initialValues={query} onFinish={searchSubmit}>
      <Tabs defaultActiveKey="1" items={tabs} animated={false} />
      <Row {...rowConfig} justify="center">
        <Form.Item style={{marginBottom: 0}}>
          <Button onClick={resetSearh} style={{marginRight: '8px'}}>Reiniciar</Button>
          <Button type="primary" htmlType="submit" loading={loading}>Buscar</Button>
        </Form.Item>
      </Row>
    </Form>
  )
}

export default FlatsSearcher;
