import { AxiosRequestConfig } from "axios";
import { parse as parseXml } from 'fast-xml-parser';
import { compact, castArray } from "lodash";

const axiosConfig : AxiosRequestConfig = {
  headers: {
    'Accept': "application/xml"
  },
  responseType: "text"
};

export const GetDistrictesRequest : AxiosRequestConfig = {
  ...axiosConfig,

  url: "https://emap.terrassa.cat/webservices/ADRService.asmx/GetDistrictes",

  transformResponse: (data: any, headers?: any) : GetDistrictesTypeResponse[] =>{
    const xml = parseXml(data, {
      ignoreNameSpace: true,
      ignoreAttributes: false,
      attributeNamePrefix: '',
    });

    return compact(castArray(xml.queryResult.record));
  }
};

export interface GetDistrictesTypeResponse {
  id: string,
  districte: number;
}

export const GetBarrisRequest : AxiosRequestConfig = {
  ...axiosConfig,

  url: "https://emap.terrassa.cat/webservices/ADRService.asmx/GetBarris",

  params: {
    Nom: "",
    Ordre: 1
  },

  transformResponse: (data: any, headers?: any) : GetBarrisTypeResponse[] =>{
    const xml = parseXml(data, {
      ignoreNameSpace: true,
      ignoreAttributes: false,
      attributeNamePrefix: '',
    });

    return compact(castArray(xml.queryResult.record));
  }
};

export interface GetBarrisTypeResponse {
  id: string,
  codi_barri: number;
  nom_barri: string;
}

export const GetCarrersRequest : AxiosRequestConfig = {
  ...axiosConfig,

  url: "https://emap.terrassa.cat/webservices/ADRService.asmx/GetCarrersxNom",

  params: {
    Nom: ""
  },

  transformResponse: (data: any, headers?: any) : GetCarrersTypeResponse[] =>{
    const xml = parseXml(data, {
      ignoreNameSpace: true,
      ignoreAttributes: false,
      attributeNamePrefix: '',
    });

    return compact(castArray(xml.queryResult.record));
  }
};

export const GetCarrersPerCodiRequest : AxiosRequestConfig = {
  ...axiosConfig,

  url: "https://emap.terrassa.cat/webservices/ADRService.asmx/GetCarrersxCodi",

  params: {
    sCodi: ""
  },

  transformResponse: (data: any, headers?: any) : GetCarrersTypeResponse[] =>{
    const xml = parseXml(data, {
      ignoreNameSpace: true,
      ignoreAttributes: false,
      attributeNamePrefix: '',
    });

    return compact(castArray(xml.queryResult.record));
  }
};

export interface GetCarrersTypeResponse {
  id: string,
  codi_carrer: number;
  sigla: string;
  des_sigla: string;
  nom_carrer: string;
  nom_carrer_fmt: string;
}

export const GetPortalsxCodiCarrer : AxiosRequestConfig = {
  ...axiosConfig,

  url: "https://emap.terrassa.cat/webservices/ADRService.asmx/GetPortalsxCodiCarrer",

  params: {
    CodiCarrer: ""
  },

  transformResponse: (data: any, headers?: any) : GetPortalsxCodiCarrerTypeResponse[] =>{
    const xml = parseXml(data, {
      ignoreNameSpace: true,
      ignoreAttributes: false,
      attributeNamePrefix: '',
    });

    return compact(castArray(xml.queryResult.record));
  }
};

export interface GetPortalsxCodiCarrerTypeResponse {
  id: string;
  codi_pais: number;
  nom_pais: string;
  codi_provincia: number;
  nom_provincia: string;
  codi_municipi: number;
  nom_municipi: string;
  codi_carrer: number;
  sigla: string;
  des_sigla: string;
  nom_carrer_fmt: string;
  nro: number;
  bis: string;
  tipus_nro: string;
  id_portal: number;
  adr1: string;
  utm: number;
  codi_barri: string;
  nom_barri: string;
  codi_postal: number;
  porta_baixa: string;
  x_coord: number;
  y_coord: number;
  x_etrs89: number;
  y_etrs89: number;
  longitud: number;
  latitud: number;
}
