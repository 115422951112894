import React, { useReducer, useEffect } from "react"
import axios, {AxiosResponse} from "axios";
import {Form, Select, message, TreeSelect} from "antd"
import { FormInstance } from "antd/lib/form";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {filterOptionByLabel} from "utils/helpers"
import {AttachmentCollection, AttachmentType} from "utils/models";
import {AttachmentTypesResponse} from "utils/responses";
import { find } from "lodash";
import {HomeOutlined} from "@ant-design/icons/lib";
import {faFolder} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const { Option } = Select;

export interface AttachmentFormProps {
  form: FormInstance;
  endpoint: string,
  currentVaultId: string|number,
}

export interface IState {
  isFetching: boolean,
  error: string,
  defaultExpandedKeys: number[],
  options: any[]
}

const initialState: IState = {
  isFetching: false,
  error: null,
  defaultExpandedKeys: [],
  options: []
};

const reducer = (state: typeof initialState, action: { type: string; payload?: Partial<IState> }) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const categoriesRequest = { ...axiosConfig, url: "attachment_types", params: { items: "all" } };

const AttachmentForm: React.FC<AttachmentFormProps> = ({ form, endpoint, currentVaultId }) => {
  const { data: categories = { data: [] }, isFetching: isFetchingCategories } = useAxiosRequest<AttachmentTypesResponse>(categoriesRequest);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${endpoint}/vaults/${currentVaultId}`, { ...axiosConfig});
        const treeIds = result.data.data.attributes.ancestors_ids;

        const options = [
          {
            pId: 0,
            id: treeIds[0],
            value: treeIds[0],
            title: <><HomeOutlined style={{marginRight: "5px"}} /> Sense carpeta</>,
            isLeaf: true
          }
        ];

        const requests: AxiosResponse[] = await Promise.all(
          treeIds.map(vaultId => {
            return axios.get(`${endpoint}/vaults/${vaultId}/vaults`, { ...axiosConfig})
          })
        );

        requests.forEach(response => {
          options.push(...response.data.data.map((item: AttachmentCollection) => {
            const parentId = item.attributes.ancestors_ids.slice(-2)[0];

            return {
              pId: parentId === treeIds[0] ? 0 : parentId,
              id: parseInt(item.id),
              value: parseInt(item.id),
              title: <><FontAwesomeIcon icon={faFolder} style={{marginRight: "7px"}} /> {item.attributes.name}</>,
              isLeaf: !item.attributes.has_collections
            };
          }))
        })

        dispatch({
          type: 'updateState',
          payload: {
            defaultExpandedKeys: treeIds,
            options: options,
            isFetching: false,
            error: null
          }
        })
      } catch (e) {
        console.log(e)
        dispatch({
          type: 'updateState',
          payload: {
            options: [],
            isFetching: false,
            error: "Error al carregar la informació"
          }
        })
      }
    }

    if (currentVaultId) {
      dispatch({
        type: 'updateState',
        payload: {
          isFetching: true,
          error: null
        }
      });

      fetchData();
    }
  }, [dispatch, endpoint, currentVaultId])

  const loadData = async (node) => {
    if (find(state.options, { pId: node.value }))
      return;

    node.loading = true;

    try {
      const response = await axios.get(`${endpoint}/vaults/${node.value}/vaults`, { ...axiosConfig, params: { items: "all" }});

      const newNodes = response.data.data.map((item: AttachmentCollection) => ({
        pId: parseInt(node.value),
        id: parseInt(item.id),
        value: parseInt(item.id),
        title: <><FontAwesomeIcon icon={faFolder} style={{marginRight: "7px"}} /> {item.attributes.name}</>,
        isLeaf: !item.attributes.has_collections
       }));

      dispatch({
        type: 'updateState',
        payload: {
          options: [...state.options, ...newNodes]
        }
      })
    } catch (e) {
      message.error("No s'han pogut carregar els directoris")
    }

    node.loading = false;
  };

  return (
    <>
      <Form.Item name={["attributes", "attachment_type_id"]} label="Tipus de fitxer" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
        <Select loading={isFetchingCategories} placeholder="Selecciona el tipus de fitxer" style={{ width: "100%" }} filterOption={filterOptionByLabel} showSearch>
          {categories && categories.data.map((cat: AttachmentType) => <Option key={cat.id} value={parseInt(cat.id)}>{cat.attributes.nom}</Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "attached_to_id"]} label="Directori" rules={[{ required: true, message: "Aquest camp és requerit" }]}>
        <TreeSelect
          treeDataSimpleMode
          treeDefaultExpandedKeys={state.defaultExpandedKeys}
          treeData={state.options}
          loadData={loadData}
          allowClear={false}
        />
      </Form.Item>
    </>
  )
};

export default AttachmentForm;
