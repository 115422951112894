import React from "react"
import {DatePicker, Form, Input, Select, Radio} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {axiosConfig} from "../../../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "../../preferences/dictionaries/model";
import {IServei} from "../../../api/interfaces/Servei";
import {filterOptionByLabel} from "utils/helpers"

export interface IncidenciaFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  data_inici: [ { required: true, message: "Aquest camp és requerit" } ],
  tipus_incidencia_id: [ { required: true, message: "Aquest camp és requerit" } ],
  motiu_tancament_id: [ { required: true, message: "Aquest camp és requerit" } ],
};

const incidenciesRequest = { ...axiosConfig, url: "tipus_incidencies", params: { items: "all" } };
const motiusRequest = { ...axiosConfig, url: "motius_tancament", params: { items: "all" } };
const serveisRequest = { ...axiosConfig, url: "serveis", params: { items: "all" } };

const IncidenciaForm: React.FC<IncidenciaFormProps> = ({ readOnly = false }) => {
  const { data: incidenciesData, isFetching: isFetchingIncidencies } = useAxiosRequest<{ data: DicitonaryType[] }>(incidenciesRequest);
  const { data: incidencies = [] } = incidenciesData || {};

  const { data: motiusData, isFetching: isFetchingMotius } = useAxiosRequest<{ data: DicitonaryType[] }>(motiusRequest);
  const { data: motius = [] } = motiusData || {};

  const { data: serveisData, isFetching: isFetchingServeis } = useAxiosRequest<{ data: IServei[] }>(serveisRequest);
  const { data: serveis = [] } = serveisData || {};

  return (
    <>
      <Form.Item name={["attributes", "expedient_id"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "data_inici"]} label="Data d'inici" rules={rules.data_inici}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "tipus_incidencia_id"]} label="Tipus d'incidència" rules={rules.tipus_incidencia_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingIncidencies} filterOption={filterOptionByLabel} showSearch>
          {incidencies.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "alias"]} label="Alias">
        <Input disabled={readOnly} placeholder="Alias" />
      </Form.Item>
      <Form.Item name={["attributes", "affects_community"]} label="Afecta a tota la comunitat">
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["attributes", "data_tancament"]} label="Data tancament">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "motiu_tancament_id"]} label="Motiu de tancament">
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingMotius} filterOption={filterOptionByLabel} showSearch allowClear>
          {motius.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "servei_ids"]} label="Derivació a serveis">
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els serveis" loading={isFetchingServeis} filterOption={filterOptionByLabel} showSearch>
          {serveis.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default IncidenciaForm;
