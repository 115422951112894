import { GET, DELETE, PATCH, POST } from '../constants'
import Http from '../Http'
import { IQuery, IQueryList, IQueryEditable, IQueryAssociatable } from '../interfaces/Query'
import { IContactingResult, IContactingsResult, IContacting } from '../interfaces/Contacting'
import { Routes } from '../routes'
import {EmptyResponse, JsonApiDocument, JsonApiRelationDocument} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../interfaces/ResultResponse";

export default class Contactings extends Http implements IQueryList, IQueryEditable, IQueryAssociatable {
  protected dateFields: string[] = ["valid_fins"];

  public async list(params: IQuery = {}): Promise<IContactingsResult> {
    return await this.request(GET, Routes.contactingsPath(), {}, params) as IContactingsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IContactingResult> {
    return await this.request(GET, Routes.contactingPath(id), {}, params) as IContactingResult
  }

  public async create(params: JsonApiDocument): Promise<IContactingResult> {
    return await this.request(POST, Routes.contactingsPath(), {}, params) as IContactingResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IContactingResult> {
    return await this.request(PATCH, Routes.contactingPath(id), {}, params) as IContactingResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.contactingPath(id), {}, params) as ResultResponse<EmptyResponse>
  }

  public async associate(params: JsonApiRelationDocument): Promise<IContactingsResult> {
    return await this.request(POST, Routes.contactingsPath() + '/associate', {}, params) as IContactingsResult
  }

  public async deassociate(params: JsonApiRelationDocument): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(POST, Routes.contactingsPath() + '/deassociate', {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance(attributes) : IContacting {
    return {
      id: null,
      type: "contactings",
      attributes: {
        role_ids: [],
        role_names: [],
        role_colors: [],
        position: 0,
        valid_fins: null,
        contact: {
          data: {
            id: null,
            attributes: {
              nom: "",
              cognoms: "",
              adreca: "",
              telefon: "",
              email: "",
              principal: false,
              observacions: "",
              full_name: ""
            },
            meta: {
              permissions: {
                can_destroy: true,
                can_edit: true,
                can_show: true
              }
            }
          }
        },
        ...attributes
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
