import React from "react"
import {DatePicker, Form, Input, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "../../../utils/formats";
import {axiosConfig} from "../../../utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "../../preferences/dictionaries/model";
import {filterOptionByLabel} from "utils/helpers"

export interface GestioFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  data_inici: [ { required: true, message: "Aquest camp és requerit" } ],
  tipus_gestio_id: [ { required: true, message: "Aquest camp és requerit" } ],
  motiu_tancament_id: [ { required: true, message: "Aquest camp és requerit" } ],
};

const gestionsRequest = { ...axiosConfig, url: "tipus_gestions", params: { items: "all" } };

const GestioForm: React.FC<GestioFormProps> = ({ readOnly = false }) => {
  const { data: gestionsData, isFetching: isFetchingGestions } = useAxiosRequest<{ data: DicitonaryType[] }>(gestionsRequest);
  const { data: gestions = [] } = gestionsData || {};

  return (
    <>
      <Form.Item name={["attributes", "expedient_id"]} style={{display: "none"}}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "data_inici"]} label="Data d'inici" rules={rules.data_inici}>
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "tipus_gestio_id"]} label="Tipus de gestió" rules={rules.tipus_gestio_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingGestions} filterOption={filterOptionByLabel} showSearch>
          {gestions.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "data_tancament"]} label="Data tancament">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default GestioForm;
