import React from "react";
import {Alert, Button, message, Table} from "antd";
import {ColumnType} from "antd/lib/table/interface";
import {PlusOutlined} from "@ant-design/icons";
import {PermissibleRender} from "@brainhubeu/react-permissible";

import api from "api";
import {IExpedientIncidenciaLocal} from "api/interfaces/expedient/IncidenciaLocal";
import {useInnerTable} from "hooks/useInnerTableApi";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderObservations} from "utils/helpers";
import {useStoreState} from "utils/store";
import {ModalFormChildProps, ModalFormWithForwardRef} from "components/ModalFormApi";
import IncidenciaLocalForm from "../forms/incidencia_local";


interface IncidenciesLocalsListProps {
  expedientId: string
  incidenciaId: string
}

const IncidenciesLocalsList: React.FC<IncidenciesLocalsListProps> = ({ expedientId, incidenciaId }) => {
  const apiEndpoint = React.useMemo(() => api.expedientIncidenciaLocals(expedientId, incidenciaId), [expedientId, incidenciaId]);

  const { tableProps, error, refresh, reload } = useInnerTable<IExpedientIncidenciaLocal>(apiEndpoint);

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientIncidenciaLocal> => {
    return {
      title: "Afegir local",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IExpedientIncidenciaLocal>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<IExpedientIncidenciaLocal>[] = [
    {
      title: 'Pis',
      key: "flat",
      dataIndex: ["attributes", "local_name"]
    },
    {
      title: 'Rol',
      key: "rol_incidencia",
      dataIndex: ["attributes", "rol_incidencia_name"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginTop: "5px" }}>
      <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
        <Button icon={<PlusOutlined />} onClick={create} size="small">Afegir</Button>
      </PermissibleRender>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: ModalFormChildProps) => (
          <IncidenciaLocalForm form={form} readOnly={readOnly} expedientId={expedientId} />
        )}
      </ModalFormWithForwardRef>
    </>
  );
};

export default IncidenciesLocalsList;
