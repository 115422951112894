import React from "react";
import {Button, Table, Alert, message} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import { PlusOutlined } from "@ant-design/icons";
import {renderRecordActions, renderRecordDateRange, renderRecordService} from "utils/helpers";
import {IAdministracioCcvv} from "api/interfaces/AdministracioCcvv";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import AdministracioForm from "../forms/administracio";

interface AdministracioListProps {
  expedientId: string
}

const AdministracioList: React.FC<AdministracioListProps> = ({ expedientId }) => {
  const baseQuery  = React.useMemo(() => ({ expedient: expedientId }) , [expedientId])

  const { tableProps, error, refresh, reload } = useInnerTable<IAdministracioCcvv>(api.administracioCcvvs, { baseQuery });

  const handleDestroy = async (id: string) => {
    const response = await api.administracioCcvvs.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IAdministracioCcvv> => {
    return {
      title: "Administració",
      eventBusPrefix: "CommunityAdministration",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.administracioCcvvs.newInstance({ expedient_id: expedientId })
    }
  }, [refresh, expedientId]);

  const { create, edit, drawerProps } = useDrawerForm<IAdministracioCcvv>(api.administracioCcvvs, formOptions);

  const columns: ColumnType<IAdministracioCcvv>[] = [
    {
      title: "Comunitat",
      key: "community",
      dataIndex: ["attributes", "ccvv_name"]
    },
    {
      title: "Data",
      key: "start_date",
      render: renderRecordDateRange("data_inici", "data_fi")
    },
    {
      title: "Adm. de finques",
      key: "admin_finques",
      render: renderRecordService
    },
    {
      title: 'Observacions',
      key: "observacions",
      dataIndex: ["attributes", "observacions"]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
       <div>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly, eventBusPrefix }: DrawerFormChildProps) => (
          <AdministracioForm form={form} expedientId={expedientId} readOnly={readOnly} eventBusPrefix={eventBusPrefix} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default AdministracioList;
