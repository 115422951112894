import React from "react";
import {Button, Table, Alert, message} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import { PlusOutlined } from "@ant-design/icons";
import {renderRecordActions, renderRecordDate} from "utils/helpers";
import {IObservacioCcvv} from "api/interfaces/community/ObservacioCcvv";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import ObservacioForm from "pages/communities/forms/observacio";

interface ObservacioListProps {
  communitiyId: string
}

const ObservacioList: React.FC<ObservacioListProps> = ({ communitiyId }) => {
  const apiEndpoint = React.useMemo(() => api.observacioCcvvs(communitiyId), [communitiyId]);

  const { tableProps, error, reload, refresh } = useInnerTable<IObservacioCcvv>(apiEndpoint);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IObservacioCcvv> => {
    return {
      title: "Nova administració",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IObservacioCcvv>(apiEndpoint, formOptions);

  const columns: ColumnType<IObservacioCcvv>[] = [
    {
      title: "Data",
      key: "start_date",
      render: renderRecordDate("data")
    },
    {
      title: 'Observacions',
      key: "observacions",
      dataIndex: ["attributes", "observacions"]
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <div>
        <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <ObservacioForm form={form} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default ObservacioList;
