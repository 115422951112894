import React from "react";
import {Button, Table, Alert, Form, message, Select, DatePicker} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { PlusOutlined } from "@ant-design/icons";
import {FormInstance} from "antd/lib/form";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {renderRecordActions, renderObservations, renderRecordDateRange, renderRecordService, filterOptionByLabel} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IExpedientCoordinacio} from "api/interfaces/expedient/Coordinacio";
import CoordinacioForm from "../forms/coordinacio";
import { compact } from "lodash";
import { dateFormats } from "utils/formats";
import { axiosConfig } from "utils/request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import { useAxiosRequest } from "use-axios-request";

interface CoordinacionsListProps {
  expedientId: string
}

const { RangePicker } = DatePicker;

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    servei: undefined,
    status: "all",
    start_between: [undefined, undefined],
    end_between: [undefined, undefined],
  })
};

const serveisRequest = { ...axiosConfig, url: "serveis", params: { items: "all" } };

const CoordinacionsList: React.FC<CoordinacionsListProps> = ({ expedientId }) => {
  const [form] = Form.useForm();
  const apiEndpoint = React.useMemo(() => api.expedientCoordinacions(expedientId), [expedientId]);

  const { tableProps, error, refresh, reload, search } = useInnerTable<IExpedientCoordinacio>(apiEndpoint, { form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const { data: serveisData, isFetching: isFetchingServeis } = useAxiosRequest<{ data: DicitonaryType[] }>(serveisRequest);
  const { data: serveis = [] } = serveisData || {};

  const onFinish = (formValues: {[key: string]: any}) : void =>  {
    const startRange = compact<moment.Moment>(formValues["start_between"]);
    const endRange = compact<moment.Moment>(formValues["end_between"]);

    const start_between = startRange.length > 0
      ? [startRange[0].format('YYYY-MM-DD'), startRange[1].format('YYYY-MM-DD')].join(",")
      : undefined;

      const end_between = endRange.length > 0
      ? [endRange[0].format('YYYY-MM-DD'), endRange[1].format('YYYY-MM-DD')].join(",")
      : undefined;

      searchSubmit({ ...formValues, start_between, end_between });
  }

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientCoordinacio> => {
    return {
      title: "Coordinació",
      eventBusPrefix: "coordinacio",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IExpedientCoordinacio>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

  const columns: ColumnType<IExpedientCoordinacio>[] = [
    {
      title: "Data",
      key: "start_date",
      render: renderRecordDateRange("data_inici", "data_fi")
    },
    {
      title: 'Servei',
      key: "type",
      render: renderRecordService
    },
    {
      title: 'Incidències',
      key: "incidences",
      dataIndex: ["attributes", "incidences_identifiers"]
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={{status: "all"}} layout="inline" onFinish={onFinish}>
        <Form.Item name="status">
          <Select className="w100" placeholder="Estat">
            <Select.Option key="all" value="all">Totes</Select.Option>
            <Select.Option key="open" value="open">Obertes</Select.Option>
            <Select.Option key="closed" value="closed">Tancades</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="servei">
          <Select className="w100" placeholder="Tipus de servei" loading={isFetchingServeis} filterOption={filterOptionByLabel} showSearch>
            {serveis.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item name="start_between">
          <RangePicker className="w100" placeholder={["Data d'inici inicial","Data d'inici final"]} format={dateFormats.display} separator="-" />
        </Form.Item>
        <Form.Item name="end_between">
          <RangePicker className="w100" placeholder={["Data fi inicial","Data fi final"]} format={dateFormats.display} separator="-" />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
          <Button type="link" htmlType="submit" loading={tableProps.loading as boolean}>Filtrar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly, eventBusPrefix}: DrawerFormChildProps) => (
          <CoordinacioForm form={form} expedientId={expedientId} readOnly={readOnly} eventBusPrefix={eventBusPrefix} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default CoordinacionsList;
