import React, { useMemo } from "react";
import {Button, Table, Alert, Form, Select, message, DatePicker, Row, Col, Skeleton, Progress} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { useInnerTable } from "hooks/useInnerTableApi";
import api from "api";

import {useStoreState} from "utils/store";
import { DownloadOutlined, PieChartOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {FormInstance} from "antd/lib/form";
import {useDrawerForm, useDrawerFormOptionsType} from "hooks/useDrawerFormApi";
import {filterOptionByLabel, renderRecordActions, renderRecordDate, renderObservations} from "utils/helpers";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "components/DrawerFormApi";
import {IExpedientIntervencio} from "api/interfaces/expedient/Intervencio";
import IntervencioForm from "../forms/intervencio";
import {axiosConfig} from "utils/request";
import {useAxiosRequest} from "use-axios-request";
import {DicitonaryType} from "pages/preferences/dictionaries/model";
import {dateFormats} from "utils/formats";
import { Routes } from "api/routes";
import { IExpedientIncidencia } from "api/interfaces/expedient/Incidencia";
import moment from "moment";
import { forEach, map } from "lodash";
import { fourColumns, rowConfig, twoColumns } from "utils/constants";

interface IntervencionsListProps {
  expedientId: string
}

const formResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    tipus: undefined,
    tipus_incidencia: undefined,
    incidencia: undefined,
    from: undefined,
    to: undefined
  })
};

const tipusIncidenciesRequest = { ...axiosConfig, url: "tipus_incidencies", params: { items: "all" } };
const intervencionsRequest = { ...axiosConfig, url: "tipus_intervencions", params: { items: "all" } };

const IntervencionsList: React.FC<IntervencionsListProps> = ({ expedientId }) => {
  const [showFilters, setShowFilters] = React.useState(false);
  const [showStats, setShowStats] = React.useState(false);

  const [form] = Form.useForm();
  const apiEndpoint = React.useMemo(() => api.expedientsIntervencions(expedientId), [expedientId]);

  const { tableProps, error, refresh, reload, search, meta, loading: isLoadingData } = useInnerTable<IExpedientIntervencio>(apiEndpoint, { form, formResetCallback });
  const { submit: searchSubmit, reset: resetSearh, searchQuery } = search!;

  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const exportURL = React.useMemo(() => {
    const filters = searchQuery;

    if (filters) {
      forEach(filters, (value, key) => {
        if (moment.isMoment(value)) {
          filters[key] = value.format(dateFormats.server)
        }
      })
    }

    return apiEndpoint.exportUrl("xlsx", { filters: searchQuery })
  }, [apiEndpoint, searchQuery]);

  const { data: tipusIncidenciesData, isFetching: isFetchingTipusIncidencies } = useAxiosRequest<{ data: DicitonaryType[] }>(tipusIncidenciesRequest);
  const { data: tipusIncidencies = [] } = tipusIncidenciesData || {};

  const { data: tipusIntervencionsData, isFetching: isFetchingTipusIntervencions } = useAxiosRequest<{ data: DicitonaryType[] }>(intervencionsRequest);
  const { data: tipusIntervencions = [] } = tipusIntervencionsData || {};

  const incidencesRequest = useMemo(() => ({ ...axiosConfig, baseURL: "/", url: Routes.expedientIncidenciesPath(String(expedientId)), params: { items: "all" }}), [expedientId]);
  const { data: incidenciesData, isFetching: isFetchingIncidencies } = useAxiosRequest<{ data: IExpedientIncidencia[] }>(incidencesRequest);
  const { data: incidencies = [] } = incidenciesData || {};

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IExpedientIntervencio> => {
    return {
      title: "Intervenció",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: apiEndpoint.newInstance()
    }
  }, [apiEndpoint, refresh]);

  const { create, edit, drawerProps } = useDrawerForm<IExpedientIntervencio>(apiEndpoint, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await apiEndpoint.destroy(id);

    if (response.isSuccess())
      refresh();
    else
      message.error("No s'ha pogut eliminar el registre", 10);
  }

/*  const onFinish = (formValues: {[key: string]: any}) : void =>  {
    const from = formValues["from"] ? formValues["from"].format(dateFormats.server) : undefined;
    const to = formValues["to"] ? formValues["to"].format(dateFormats.server) : undefined;

    searchSubmit({ ...formValues, from, to });
  }*/

  const columns: ColumnType<IExpedientIntervencio>[] = [
    {
      title: "Data",
      key: "start_date",
      render: renderRecordDate("data_realitzacio")
    },
    {
      title: 'Tipus',
      key: "type",
      dataIndex: ["attributes", "tipus_intervencio_name"]
    },
    {
      title: 'Incidències',
      key: "incidences",
      dataIndex: ["attributes", "incidences_identifiers"]
    },
    {
      title: 'Locals i Pisos',
      key: "services",
      dataIndex: ["attributes", "locals_noms"]
    },
    {
      title: 'Serveis',
      key: "services",
      dataIndex: ["attributes", "serveis_noms"]
    },
    {
      title: 'Contactes',
      key: "services",
      dataIndex: ["attributes", "contacts_noms"]
    },
    /*{
      title: 'Propietaris',
      key: "services",
      dataIndex: ["attributes", "propietaris_noms"]
    },*/
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(edit, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between'}}>
      <div>
        <Button icon={<SearchOutlined />} onClick={() => setShowFilters(!showFilters)} className="mr-5">Filtres</Button>
        <Button icon={<PieChartOutlined />} onClick={() => setShowStats(!showStats)}>
          Estadístiques
          { isLoadingData ? '' : ` (${(meta.stats?.total || 0)})`}
        </Button>
      </div>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["expedients:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
        <Button icon={<DownloadOutlined />} href={exportURL} target="_blank" style={{marginLeft: '5px'}}>Descarregar</Button>
      </div>
    </div>
  );

  const searcher = (
    <Form form={form} initialValues={{}} layout="vertical" onFinish={searchSubmit} style={{marginBottom: '16px'}}>
      <Row {...rowConfig}>
        <Col {...fourColumns}>
          <Form.Item name="from" label="Des de">
            <DatePicker className="w100" format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...fourColumns}>
          <Form.Item name="to" label="Fins">
            <DatePicker className="w100" format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name="tipus" label="Tipus d'intervenció">
            <Select className="w100" mode="multiple" placeholder="Selecciona un element" loading={isFetchingTipusIntervencions} filterOption={filterOptionByLabel} showSearch dropdownMatchSelectWidth={true}>
              {tipusIntervencions.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
       <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name="tipus_incidencia" label="Tipus d'incidència">
            <Select className="w100" mode="multiple" placeholder="Selecciona un element" loading={isFetchingTipusIncidencies} filterOption={filterOptionByLabel} showSearch dropdownMatchSelectWidth={true}>
              {tipusIncidencies.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name="incidencia" label="Incidència associada">
            <Select className="w100" mode="multiple" placeholder="Selecciona una data" loading={isFetchingIncidencies} filterOption={filterOptionByLabel} showSearch>
              {incidencies.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.identifier}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowConfig} justify="center">
        <Form.Item style={{marginBottom: 0}}>
          <Button type="ghost" onClick={resetSearh}>Reiniciar</Button>
          <Button type="primary" loading={tableProps.loading} onClick={form.submit} style={{marginLeft: '5px'}}>Filtrar</Button>
        </Form.Item>
      </Row>
    </Form>
  );

  const stats = useMemo(() => {
    if (isLoadingData) return <Skeleton active />;

    const { stats = { total: 1, categories: {} } } = meta;

    const childs = map(stats.categories, (value, key) => {
      const percent = Math.round((value/stats.total) * 100);

      return (
        <>
          {key} ({value})
          <Progress percent={percent} strokeColor="#145687" status="normal" />
        </>
      )
    })

    const half = Math.ceil(childs.length / 2);
    const firstHalf = childs.slice(0, half)
    const secondHalf = childs.slice(half)

    return (
      <Row {...rowConfig} style={{marginBottom: 16}}>
        <Col {...twoColumns}>
          {firstHalf}
        </Col>
        <Col {...twoColumns}>
          {secondHalf}
        </Col>
      </Row>
    )
  }, [meta, isLoadingData]);

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  );

  return (
    <>
      { toolbar }
      { showFilters && searcher }
      { showStats && stats }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form, readOnly }: DrawerFormChildProps) => (
          <IntervencioForm form={form} expedientId={expedientId} readOnly={readOnly} />
        )}
      </DrawerFormWithForwardRef>
    </>
  );
};

export default IntervencionsList;
