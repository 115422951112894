import React from "react";
import { Form, Select } from "antd";
import { useAxiosRequest, CachePolicy } from "use-axios-request";

import { BarrisTypeResponse } from "utils/responses";
import {axiosConfig} from "utils/request";
import {filterOptionByLabel} from "../../utils/helpers";

interface BarriSelectorProps {
  field_name: string;
}

const barrisRequest = { ...axiosConfig, url: "barris", params: { items: "all" } };

const BarriSelector: React.FC<BarriSelectorProps> = ({ field_name }) => {
  const requestOptions = {
    // Milliseconds that determine how often should the data with the same config is polled.
    // No polling occures if 0 is passed. Defaults to 0.
    pollInterval: 300000, // 5 minutos
    // Boolean. If true, response data will be cached. Internal axios buildURL is used to
    // generate a cache key.
    cache: CachePolicy.CacheAndNetwork,
    // A Callback that is called after a successful response
    // onSuccess: () => setShowModal(false),
    // A Callback that is called after an error response
    // onError: () => setShowModal(false),
  };

  const { data: barris = { data: [] }, isFetching } = useAxiosRequest<BarrisTypeResponse>(barrisRequest, requestOptions);

  return (
    <Form.Item name={field_name}>
      <Select placeholder="Selecciona el barri" loading={isFetching} filterOption={filterOptionByLabel} showSearch allowClear>
        {barris && barris.data.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
      </Select>
    </Form.Item>
  );
};

export default BarriSelector;
