import { GET, DELETE, PATCH, POST } from '../../constants'
import Http from '../../Http'
import { IQuery, IQueryList, IQueryEditable } from '../../interfaces/Query'
import { IAvOrganitzacioResult, IAvOrganitzacionsResult, IAvOrganitzacio } from '../../interfaces/community/AvOrganitzacio'
import { Routes } from '../../routes'
import {EmptyResponse, JsonApiDocument, JsonApiDocumentGenerator} from 'api/interfaces/JsonApi'
import {ResultResponse} from "../../interfaces/ResultResponse";
import moment from 'moment'

export default class AvOrganitzacions extends Http implements IQueryList, IQueryEditable, JsonApiDocumentGenerator {
  private readonly community_id: string

  protected dateFields: string[] = ["data"];

  constructor(community_id: string, host?: string) {
    super(host);
    this.community_id = community_id;
  }

  public async list(params: IQuery = {}): Promise<IAvOrganitzacionsResult> {
    return await this.request(GET, Routes.avOrganitzacionsPath(this.community_id), {}, params) as IAvOrganitzacionsResult
  }

  public async show(id: string, params: IQuery = {}): Promise<IAvOrganitzacioResult> {
    return await this.request(GET, Routes.avOrganitzacioPath(this.community_id, id), {}, params) as IAvOrganitzacioResult
  }

  public async create(params: JsonApiDocument): Promise<IAvOrganitzacioResult> {
    return await this.request(POST, Routes.avOrganitzacionsPath(this.community_id), {}, params) as IAvOrganitzacioResult
  }

  public async update(id: string, params: JsonApiDocument): Promise<IAvOrganitzacioResult> {
    return await this.request(PATCH, Routes.avOrganitzacioPath(this.community_id, id), {}, params) as IAvOrganitzacioResult
  }

  public async destroy(id: string, params: IQuery = {}): Promise<ResultResponse<EmptyResponse>> {
    return await this.request(DELETE, Routes.avOrganitzacioPath(this.community_id, id), {}, params) as ResultResponse<EmptyResponse>
  }

  public newInstance() : IAvOrganitzacio {
    return {
      id: null,
      type: "users",
      attributes: {
        comunitat_constituida: "",
        organs_representacio_interns: "",
        efectivitat_organs_interns: "",
        organs_representacio_externs: "",
        efectivitat_organs_externs: "",
        reunions_ordinaries_anuals: "",
        asseguranca: "",
        ite: "",
        pagament_quotes: "",
        pagament_derrames: "",
        rotacio_carrecs: "",
        demanda_subvencio: "",
        fons_reserva: "",
        existencia_estatus: "",
        normes_regim_intern: "",
        data: moment(),
        observacions: "",
      },
      meta: {
        permissions: {
          can_destroy: false,
          can_edit: true,
          can_show: true
        }
      }
    };
  }

}
