import React from "react"
import { Form, Input, DatePicker } from "antd"
import { FormInstance } from "antd/lib/form";
import { dateFormats } from "utils/formats";

export interface ContactFormProps {
  form: FormInstance;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}


const ContactForm: React.FC<ContactFormProps> = ({ readOnly = false }) => {
  return (
    <>
      <Form.Item name={["attributes", "nom"]} label="Nom" rules={rules.required}>
        <Input disabled={readOnly} placeholder="Nom" />
      </Form.Item>
      <Form.Item name={["attributes", "cognoms"]} label="Cognoms">
        <Input disabled={readOnly} placeholder="Cognoms" />
      </Form.Item>
      <Form.Item name={["attributes", "adreca"]} label="Adreça">
        <Input disabled={readOnly} placeholder="Adreça" />
      </Form.Item>
      <Form.Item name={["attributes", "telefon"]} label="Telèfon">
        <Input disabled={readOnly} placeholder="Telèfon" />
      </Form.Item>
      <Form.Item name={["attributes", "email"]} label="Correu electrònic">
        <Input disabled={readOnly} placeholder="Correu electrònic" />
      </Form.Item>
      {/*
      <Form.Item name={["attributes", "principal"]} label="Principal">
        <Radio.Group disabled={readOnly}>
          <Radio value={false}>No</Radio>
          <Radio value>Sí</Radio>
        </Radio.Group>
      </Form.Item>
      */}
      <Form.Item name={["attributes", "valid_fins"]} label="Vàlid fins">
        <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
      </Form.Item>
      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
}

export default ContactForm;
