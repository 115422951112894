import React from "react";

import { Form, Table, Alert, Button, Input, message } from 'antd';
import { ColumnType } from "antd/lib/table/interface";
import { PageHeaderWrapper } from "@ant-design/pro-layout";

import { useQuery, useRouter } from "hooks/useRouter";
import { renderRecordActions, renderObservations, renderBooleanColumn } from "utils/helpers";
import { useTable } from "hooks/useTableApi";
import { FormInstance } from "antd/lib/form";
import { useDrawerForm, useDrawerFormOptionsType } from "hooks/useDrawerFormApi";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { PlusOutlined } from "@ant-design/icons";
import { useStoreState } from "utils/store";
import CreateServeiForm from "./create";
import { ModalFormChildProps, ModalFormWithForwardRef } from "components/ModalFormApi";
import api from "../../api";
import {IServei} from "../../api/interfaces/Servei";

const searchFormResetCallback = (form: FormInstance) => {
  form.setFieldsValue({
    nom_cont: undefined
  })
}

const defaultFilters = {is_administracio_finques_eq: 'false'};

const ServeisList: React.FC = () => {
  // useWhyDidYouUpdate('ServeisList', props);
  const userPermissions = useStoreState(state => state.app.currentPermissions);

  const [form] = Form.useForm();

  const { filters: query = {...defaultFilters} } = useQuery();
  const router = useRouter();

  const { tableProps, error, refresh, reload, search } = useTable<IServei>(api.serveis, { defaultFilters, form, formResetCallback: searchFormResetCallback });
  const { submit: searchSubmit, reset: resetSearh } = search!;

  const goToServei = (id: string) => router.push(`/serveis/${id}`);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IServei> => {
    return {
      title: "Nou servei",
      handleCreated: refresh,
      handleUpdated: refresh,
      newRecord: api.serveis.newInstance({is_administracio_finques: false})
    }
  }, [refresh])

  const { create, drawerProps } = useDrawerForm<IServei>(api.serveis, formOptions);

  const handleDestroy = async (id: string) => {
    const response = await api.serveis.destroy(id);

    if (response.isSuccess()) {
      refresh();
    } else {
      const error = response.fail().summary || "No s'ha pogut eliminar el registre"
      message.error(error, 10);
    }
  }

  const columns: ColumnType<IServei>[] = [
    {
      title: 'Nom',
      dataIndex: ["attributes", 'nom'],
      key: "street"
    },
    {
      title: 'Observacions',
      key: "observations",
      ellipsis: { showTitle: false },
      render: renderObservations
    },
    {
      title: " ",
      key: "actions",
      align: "right",
      render: renderRecordActions(goToServei, handleDestroy)
    }
  ];

  const toolbar = (
    <div style={{ marginBottom: 16, display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <Form form={form} initialValues={query} layout="inline" onFinish={searchSubmit}>
        <Form.Item name="nom_cont" style={{marginRight: 0}}>
          <Input.Search placeholder="Nom" loading={tableProps.loading as boolean} onSearch={form.submit} />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={resetSearh}>Reiniciar</Button>
        </Form.Item>
      </Form>
      <div>
        <PermissibleRender userPermissions={userPermissions} requiredPermissions={["serveis:create"]}>
          <Button icon={<PlusOutlined />} onClick={create}>Afegir</Button>
        </PermissibleRender>
      </div>
    </div>
  );

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
      <Button type="link" onClick={reload}>Reiniciar</Button>
    </div>
  )

  return (
    <PageHeaderWrapper>
      { toolbar }

      { error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Table columns={columns} rowKey="id" {...tableProps} />

      <ModalFormWithForwardRef {...drawerProps}>
        {({ form }: ModalFormChildProps) => (
          <CreateServeiForm form={form} />
        )}
      </ModalFormWithForwardRef>
    </PageHeaderWrapper>
  );
};

export default ServeisList;
