import React from "react";
import { Row, Col, Form, Select } from "antd";
import { rowConfig, fourColumns } from "utils/constants";
import { filterOptionByLabel } from "utils/helpers";
import { axiosConfig } from "utils/request";
import { useAxiosRequest } from "use-axios-request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import { Routes } from "api/routes";
import { IPropietari } from "api/interfaces/Propietari";


const regimsRequest = { ...axiosConfig, url: "regims", params: { items: "all" } };
const tipusPropietatRequest = { ...axiosConfig, url: "tipus_propietat", params: { items: "all" } };
const participacioOrgansRequest = { ...axiosConfig, url: "participacio_organs", params: { items: "all" } };
const propietarisRequest = { ...axiosConfig, baseURL: "/", url: Routes.propietarisPath(), params: { items: "all" } };

const FlatsSearcher: React.FC = () => {
  const { data: regimsData, isFetching: isFetchingRegims } = useAxiosRequest<{ data: DicitonaryType[] }>(regimsRequest);
  const { data: regims = [] } = regimsData || {};

  const { data: participacioOrgansData, isFetching: isFetchingParticipacioOrgans } = useAxiosRequest<{ data: DicitonaryType[] }>(participacioOrgansRequest);
  const { data: participacioOrgans = [] } = participacioOrgansData || {};

  const { data: tipusPropietatData, isFetching: isFetchingTipusPropietat } = useAxiosRequest<{ data: DicitonaryType[] }>(tipusPropietatRequest);
  const { data: tipusPropietat = [] } = tipusPropietatData || {};

  const { data: propietarisData, isFetching: isFetchingPropietaris } = useAxiosRequest<{ data: IPropietari[] }>(propietarisRequest);
  const { data: propietaris = [] } = propietarisData || {};

  return (
    <>
      <Row {...rowConfig}>
        <Col {...fourColumns}>
          <Form.Item name="pis_has_propietari">
            <Select placeholder="Propietari" loading={isFetchingPropietaris} filterOption={filterOptionByLabel} showSearch allowClear>
              {propietaris.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col {...fourColumns}>
          <Form.Item name="pis_has_tipus_propietat">
            <Select placeholder="Amb tipus propietat" loading={isFetchingTipusPropietat} filterOption={filterOptionByLabel} showSearch allowClear>
              {tipusPropietat.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col {...fourColumns}>
          <Form.Item name="pis_has_regim">
            <Select placeholder="Amb règim" loading={isFetchingRegims} filterOption={filterOptionByLabel} showSearch allowClear>
              {regims.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col {...fourColumns}>
          <Form.Item name="pis_has_participacio">
            <Select placeholder="Amb participació" loading={isFetchingParticipacioOrgans} filterOption={filterOptionByLabel} showSearch allowClear>
              {participacioOrgans.map((item) => <Select.Option key={item.id} value={String(item.id)}>{item.attributes.nom}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default FlatsSearcher;
