import React, {Reducer, useMemo, useReducer} from "react"
import {Form, Select, Alert, Button, Input} from "antd"
import { FormInstance } from "antd/lib/form";
import { filterOptionByLabel } from "utils/helpers";
import { useAxiosRequest } from "use-axios-request";
import { axiosConfig } from "utils/request";
import { DicitonaryType } from "pages/preferences/dictionaries/model";
import { debounce } from 'lodash';
import {IContact} from "../../../api/interfaces/Contact";
import api from "../../../api";
import {useDrawerForm, useDrawerFormOptionsType} from "../../../hooks/useDrawerFormApi";
import {DrawerFormChildProps, DrawerFormWithForwardRef} from "../../../components/DrawerFormApi";
import ContactForm from "../../contacts/form";
import {PlusOutlined} from "@ant-design/icons/lib";

export interface CreateContactingFormProps {
  form: FormInstance;
  rolesGroup: string;
  readOnly?: boolean;
}

const rules = {
  required: [ { required: true, message: "Aquest camp és requerit" } ],
}

class SelectInitialState {
  // Array of records
  data: IContact[] = [];

  // Loading
  loading = false;

  // LoadingError
  error = null;
}
const reducer = (state: SelectInitialState, action: { type: string; payload?: {} }) => {
  switch (action.type) {
    case 'updateState':
      console.log(action);
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const displayContact = (item: IContact) => {
  let text = item.attributes.full_name;

  if (item.attributes.telefon)
    text = text.concat(" (", item.attributes.telefon, ")")

  return text;
}

const CreateContactingForm: React.FC<CreateContactingFormProps> = ({ form, readOnly = false, rolesGroup }) => {
  const initialState = useMemo(() => new SelectInitialState(), []);

  const [state, dispatch] = useReducer<Reducer<SelectInitialState, any>>(reducer, { ...initialState  });

  const rolesRequest = useMemo(() => ({ ...axiosConfig, url: "tipus_rol_contacte", params: { items: "all", filters: { group: rolesGroup } }}), [rolesGroup]);
  const { data: rolsData, isFetching: isFetchingRols } = useAxiosRequest<{ data: DicitonaryType[] }>(rolesRequest);
  const { data: rols = [] } = rolsData || {};

  const onSearch = debounce(async (value) => {
    if (value.length < 2) {
      dispatch({
        type: 'updateState',
        payload: {
          data: [],
          loading: false,
          error: null
        }
      })
      return;
    }

    const response = await api.contacts.list({
      items: "all",
      filters: { search: value }
    });

    if (response.isSuccess()) {
      dispatch({
        type: 'updateState',
        payload: {
          data: response.success().data,
          loading: false,
          error: null
        }
      })
    } else {
      dispatch({
        type: 'updateState',
        payload: {
          data: [],
          loading: false,
          error: response.fail().message
        }
      })
    }
  }, 600);

  const formOptions = React.useMemo(() : useDrawerFormOptionsType<IContact> => {
    const addContact = (item: IContact) => {
      dispatch({
        type: 'updateState',
        payload: {
          data: [item],
        }
      });

      form.setFieldsValue({attributes: {contact_id: parseInt(item.id)}});
    };

    return {
      title: "Crear nou contacte",
      handleCreated: addContact,
      handleUpdated: addContact,
      newRecord: api.contacts.newInstance()
    }
  }, [dispatch, form])

  const { create, drawerProps } = useDrawerForm<IContact>(api.contacts, formOptions);

  const errorMessage = (
    <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
      <span>Hi ha hagut un error al carregar l'informació</span>
    </div>
  )

  return (
    <>
      { state.error && <Alert type="error" message={errorMessage} className="mb-15" /> }

      <Form.Item name={["attributes", "contactable_id"]} hidden>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "contactable_type"]} hidden>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "context_id"]} hidden>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["attributes", "context_type"]} hidden>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name={["attributes", "contact_id"]} label="Contacte" rules={rules.required}>
        <Select disabled={readOnly} placeholder="Busca per nom, cognoms o telèfon" loading={state.loading}
                filterOption={false} showSearch onSearch={onSearch}
                notFoundContent={<Button icon={<PlusOutlined />} onClick={create} type="link">Crear contacte</Button>}
        >
          {state.data.map((item: IContact) => (
            <Select.Option key={item.id} value={parseInt(item.id)}>{displayContact(item)}</Select.Option>)
          )}
        </Select>
      </Form.Item>
      <Form.Item name={["attributes", "role_ids"]} label="Rols del contacte" rules={rules.required}>
        <Select disabled={readOnly} mode="multiple" placeholder="Selecciona els rols" loading={isFetchingRols} filterOption={filterOptionByLabel} showSearch>
          {rols.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.nom}</Select.Option>)}
        </Select>
      </Form.Item>

      <DrawerFormWithForwardRef {...drawerProps}>
        {({ form }: DrawerFormChildProps) => (
          <ContactForm form={form} />
        )}
      </DrawerFormWithForwardRef>
    </>
  )
}

export default CreateContactingForm;
