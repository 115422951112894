import React, { useMemo } from "react"
import {Col, DatePicker, Form, Input, Row, Select} from "antd"
import { FormInstance } from "antd/lib/form";
import {dateFormats} from "utils/formats";

import ComboServeiContactes from "components/Forms/ComboServeiContactes";
import { useAxiosRequest } from "use-axios-request";
import { IExpedient } from "api/interfaces/Expedient";
import { axiosConfig } from "utils/request";
import { Routes } from "api/routes";
import { filterOptionByLabel } from "utils/helpers";
import moment from "moment";
import { rowConfig, twoColumns } from "utils/constants";

export interface AdministracioFormProps {
  communityId: string;
  form: FormInstance;
  readOnly?: boolean;
  eventBusPrefix: string;
}

const rules = {
  data_inici: [ { required: true, message: "Aquest camp és requerit" } ],
  tipus_consulta_id: [ { required: true, message: "Aquest camp és requerit" } ],
  ccvv_id: [ { required: true, message: "Aquest camp és requerit" } ],
  servei_id: [ { required: true, message: "Aquest camp és requerit" } ],
};

const renderRecordDate = (expedient: IExpedient) => {
  const data_inici = expedient.attributes.data_inici;
  const data_fi = expedient.attributes.data_fi;

  const start = data_inici ? moment(data_inici) : null;
  const end = data_fi ? moment(data_fi) : null;

  return <span>{ start ? start.format(dateFormats.display) : null} - { end ? end.format(dateFormats.display) : null}</span>;
}

const AdministracioForm: React.FC<AdministracioFormProps> = ({ communityId, readOnly = false, form, eventBusPrefix }) => {
  const expedientsRequest = useMemo(() => ({ ...axiosConfig, baseURL: "/", url: Routes.expedientsPath(), params: { items: "all", filters: { "ccvv_id": communityId }}}), [communityId]);
  const { data: expedientsData, isFetching: isFetchingExpedients } = useAxiosRequest<{ data: IExpedient[] }>(expedientsRequest);
  const { data: expedients = [] } = expedientsData || {};

  return (
    <>
      <Form.Item name={["attributes", "ccvv_id"]} hidden>
        <Input type="hidden" name='ccvv_id' value={communityId} />
      </Form.Item>

      <Row {...rowConfig}>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "data_inici"]} label="Data d'inici" rules={rules.data_inici}>
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
        <Col {...twoColumns}>
          <Form.Item name={["attributes", "data_fi"]} label="Data fi">
            <DatePicker className="w100" disabled={readOnly} format={dateFormats.display} placeholder="Selecciona una data" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={["attributes", "expedient_id"]} label="Expedient" rules={rules.ccvv_id}>
        <Select disabled={readOnly} placeholder="Selecciona un element" loading={isFetchingExpedients} filterOption={filterOptionByLabel} showSearch>
          {expedients.map((item) => <Select.Option key={item.id} value={parseInt(item.id)}>{item.attributes.num_expedient} ({renderRecordDate(item)})</Select.Option>)}
        </Select>
      </Form.Item>

      <ComboServeiContactes
        form={form}
        eventBusPrefix={eventBusPrefix}
        rules={rules}
        serviceFilters={{is_administracio_finques_eq: true}}
        labels={{ servei: "Administració de finques", contactes: "Contactes (administració de finques)" }}
      />

      <Form.Item name={["attributes", "observacions"]} label="Observacions">
        <Input.TextArea disabled={readOnly} placeholder="Observacions" autoSize={{ minRows: 4 }} />
      </Form.Item>
    </>
  )
};

export default AdministracioForm;
